import { createReducer } from '@reduxjs/toolkit';
import { BaseBottomSheetProps } from '@sarisuki/bottomSheet/types';
import { hideBottomSheet, showBottomSheet } from '.';

interface BottomSheetState {
  isVisible: boolean;
  bottomSheetType?: string | null;
  bottomSheetProps?: BaseBottomSheetProps | null;
}

const initialState: BottomSheetState = {
  isVisible: false,
  bottomSheetType: null,
  bottomSheetProps: null,
};

const bottomSheetReducer = createReducer(initialState, {
  [showBottomSheet.type]: (state, action) => {
    if (showBottomSheet.match(action)) {
      const {
        payload: { bottomSheetProps, bottomSheetType },
      } = action;
      state.isVisible = true;
      state.bottomSheetType = bottomSheetType;
      state.bottomSheetProps = bottomSheetProps;
    }
  },
  [hideBottomSheet.type]: (state) => {
    state.isVisible = false;
    state.bottomSheetProps = null;
    state.bottomSheetType = null;
  },
});

export default bottomSheetReducer;
