/**
 * Generalizes the received mobile number into the
 * value that the API uses.
 * @param mobileNumber
 * @returns
 */
 const generalizeMobileNumber = (mobileNumber: string) =>
 mobileNumber.replace(/^(09|9|\+639)/g, '639');

const localizeMobileNumber = (mobileNumber: string) =>
 mobileNumber?.replace(/^(09|639)/g, '09');

const nonLocalizeMobileNumber = (mobileNumber: string) =>
 mobileNumber?.replace(/^(09|639)/g, '9');

const internationalMobileNumber = (mobileNumber: string) =>
 mobileNumber.replace(/^(09|9|639)/g, '+639');

export default {
 generalizeMobileNumber,
 localizeMobileNumber,
 nonLocalizeMobileNumber,
 internationalMobileNumber,
};
