import { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


//Note: This works well with the OutsideAlerter component.
export const useOutsideAlerter = (ref:any, callback:(event:any) => void) => {
    useEffect(() => {

        const wrappedCallback = (event:any):void => {
          // @ts-ignore: Object is possibly 'null'.
          if (ref.current && !ref.current?.contains(event.target)) {
            callback(event);
          }
        }

        // Bind the event listener
        document.addEventListener("mousedown", wrappedCallback);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", wrappedCallback);
        };
    }, [ref]);
}


