import { useAppSelector } from '@app/hooks';
import { Modal, ModalOverlay, ModalContent, VStack, Text } from '@chakra-ui/react';
import { BaseModalProps } from '@sarisuki/modal/types';
import React, { useEffect } from 'react';
import LoadingIcon from '@assets/icons/homemap-loading-icon.svg';
import { selectHome } from '@sarisuki/home/homeSlice';

interface IProps extends BaseModalProps {}

const HomeMapLoadingModal: React.FC<IProps> = ({ open, onDismiss }: IProps) => {
    const { isLoading } = useAppSelector(selectHome);

    useEffect(() => {
        if (!isLoading) {
            onDismiss();
        }
    }, [isLoading]);

    return (
      <Modal isCentered isOpen={open} onClose={onDismiss}>
        <ModalOverlay />
        <ModalContent rounded="md" overflow="hidden" w="360px" mb={280}>
          <VStack position="relative" w="full">
            <VStack w="full" py="50px" px="20px" spacing="4">
              <LoadingIcon />
              <Text fontSize="16px" color="gray.500" fontWeight={600}>
                Checking for available locations...
              </Text>
            </VStack>
          </VStack>
        </ModalContent>
      </Modal>
    );
};
export default HomeMapLoadingModal;
