import Axios from 'axios';

/**
 * This will be the api url that will be used for testing.
 */
const DEV_API_URL = process.env.NEXT_PUBLIC_DEV_API_URL;
const PROD_API_URL = process.env.NEXT_PUBLIC_PROD_API_URL;

export const API_URL =
  process.env.ENVIRONMENT === 'production' ? PROD_API_URL : DEV_API_URL;

const axios = Axios.create({
  baseURL: `${API_URL}/api/v1`,
});

export default axios;

export const MIXPANEL_KEY = process.env.NEXT_PUBLIC_DEV_MIXPANEL_PROJECT_KEY;
export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
export const SLACK_TOKEN = process.env.NEXT_PUBLIC_SLACK_TOKEN;
export const SLACK_CHANNEL = process.env.NEXT_PUBLIC_SLACK_CHANNEL;
export const CONVERSIONS_TOKEN = process.env.NEXT_PUBLIC_CONVERSIONS_TOKEN;
export const CONVERSIONS_PIXEL_ID = process.env.NEXT_PUBLIC_CONVERSIONS_PIXEL_ID;
export const CMS_URL = process.env.NEXT_PUBLIC_CMS_URL;
export const NEW_CMS_URL = process.env.NEXT_PUBLIC_NEW_CMS_URL;
export const CATEGORY_FLAG = process.env.NEXT_PUBLIC_CATEGORY_FLAG;
export const REDESIGN_FLAG = process.env.NEXT_PUBLIC_REDESIGN_FLAG;
export const CASHLESS_PAYMENT_FLAG = process.env.NEXT_PUBLIC_CASHLESS_PAYMENT_FLAG;
export const URL = process.env.NEXT_PUBLIC_URL;
export const APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
export const NETCORE_CATEGORY_ID = process.env.NEXT_PUBLIC_NETCORE_CATEGORY_ID;
export const SHOW_PROMO_ADDRESS_FORM = process.env.NEXT_PUBLIC_SHOW_PROMO_ADDRESS_FORM;
export const PROMO_NAME = process.env.NEXT_PUBLIC_PROMO_NAME;
