import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Modal, ModalOverlay, ModalContent, VStack, Text, Image, HStack, Container, Tooltip, Button } from '@chakra-ui/react';
import { selectCart } from '@sarisuki/cart/cartSlice';
import { BaseModalProps } from '@sarisuki/modal/types';
import { getOrderDetails } from '@sarisuki/orders/ordersSlice';
import { useEffect, useState } from 'react';
import { selectOrderHistory } from '@sarisuki/orderHistory/orderHistorySlice/selectors';
import { getPreviousStoreInfo } from '@sarisuki/orderHistory/orderHistorySlice/actions';

interface IProps extends BaseModalProps {}

const OrderHistoryOrderAgainModal: React.FC<IProps>
    = ({ open, onDismiss, ...modalProps }: IProps) => {
  const dispatch = useAppDispatch();
  const [itemCount, setItemCount] = useState<number | undefined>(0);
  const { cart_error } = useAppSelector(selectCart);
  const { previousOrderId } = useAppSelector(selectOrderHistory);
  const [orderItemStoreName, setOrderItemStoreName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setItemCount(cart_error?.length);

    if (previousOrderId) {
      const orderDetails = async () => dispatch(getOrderDetails(previousOrderId));
      orderDetails();
    }

    if (modalProps) Object.values(modalProps).forEach((data: any) => setOrderItemStoreName(data.prev_storename ?? ''));
  }, []);

  const onProceedClick = async () => {
    setIsLoading(true);
    if (orderItemStoreName) await dispatch(getPreviousStoreInfo(orderItemStoreName));
  };

  const renderOrderItems = () => cart_error?.map((orderItems) => (
    <HStack pt={5} key={orderItems.product.sku}>
      <Image
        w="36px"
        h="36px"
        src={orderItems.product.images[0]?.url}
        fallbackSrc="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/e596a334-ec86-4a84-96df-17900077efc2/d7gwtxy-a0648d53-d900-425d-85e4-96fdeb5e7968.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwic3ViIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsImF1ZCI6WyJ1cm46c2VydmljZTpmaWxlLmRvd25sb2FkIl0sIm9iaiI6W1t7InBhdGgiOiIvZi9lNTk2YTMzNC1lYzg2LTRhODQtOTZkZi0xNzkwMDA3N2VmYzIvZDdnd3R4eS1hMDY0OGQ1My1kOTAwLTQyNWQtODVlNC05NmZkZWI1ZTc5NjguZ2lmIn1dXX0.EUXeqrmX0WznMmIeDsU2e2oViUjumxXkYxFrK3A1OOY"
        mr="2"
        alt={orderItems.product.name}
      />
      <VStack spacing={0}>
        <Tooltip label={orderItems.product.name} bg="white" color="gray.700">
          <Text color="black" isTruncated fontWeight={400} fontSize="14px" w="176px" m={0}>
            {orderItems.product.name}
          </Text>
        </Tooltip>

        <Text color="gray.60" fontWeight={400} fontSize="12px" w="full">
          {orderItems.product.unit_of_measure}
        </Text>
      </VStack>
    </HStack>
  ));

  return (
    <Modal isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent w={['360px', '474px']} h={['370px', '410px']} borderRadius="4px" px={['60px', '80px']} pt="46px">
        <VStack>
          <Text fontWeight={700} fontSize={['18px', '20px']} color="black">
            {itemCount} of your items for this order {itemCount === 1 ? 'is' : 'are'} out of stock
          </Text>
          <Text fontWeight={400} fontSize={['12px', '14px']} color="gray.60" textAlign="center">
            We won’t be adding {itemCount === 1 ? 'it' : 'them'} to your cart.
          </Text>
          <Container px={5} pt="10px" pb="20px">{renderOrderItems()}</Container>
          <Button
            bg="sarisuki-green.500"
            color="white"
            borderRadius="4px"
            fontWeight={700}
            fontSize={['14px', '16px']}
            w="80%"
            _focus={{ border: 'none', bg: 'sarisuki-green.500' }}
            _hover={{ border: 'none', bg: 'sarisuki-green.500' }}
            isLoading={isLoading}
            onClick={onProceedClick}
          >
            Got it, proceed
          </Button>
          <Button
            bg="white"
            color="gray.500"
            borderRadius="4px"
            fontWeight={700}
            fontSize={['14px', '16px']}
            w="80%"
            onClick={onDismiss}
            _focus={{ border: 'none', bg: 'white' }}
            _hover={{ border: 'none', bg: 'white' }}
          >
            Cancel
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
export default OrderHistoryOrderAgainModal;
