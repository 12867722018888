import { MIXPANEL_KEY } from '@const/api';
import mixpanel, { Dict } from 'mixpanel-browser';

declare global {
  interface Window {
    Hansel:any;
  }
}

mixpanel.init(MIXPANEL_KEY!);

const actions = {
  track: (name: string, props?: Dict | undefined) => {
    let mergedProperties = {};
    const hanselData = window.Hansel ? window.Hansel.logEvent(name, 'mxp', props) : {};

    mergedProperties = Object.assign(props ?? {}, hanselData);
    mixpanel.track(name, mergedProperties);
  },
};

export const MixpanelNetcore = actions;
