import { CITY_SELECTION_TUTORIAL_STEP } from '@const/tutorial-steps';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { FeaturedProduct } from '@sarisuki/home/types/featuredProduct';
import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { StoreLocation } from '@sarisuki/home/types/storeLocation';
import { DropdownOption } from '@sarisuki/home/types/dropdownOption';
import log from '@utils/log';
import _ from 'lodash';

import {
  filterPins,
  setTutorial,
  setCityProvince,
  getStoreLocations,
  addStoreLocations,
  setInputtedAddress,
  getFeaturedProducts,
  setIsSearchingAddress,
  revertToAllStoreLocations,
  setSelectedStoreLocation,
  closeStoreInfoWindow,
  setIsFromHomeLandingPage,
  getFeaturedCL,
} from '.';
import { FeaturedCL } from '../types/featuredCL';

export interface HomeState {
  storeLocations: StoreLocation[];
  filteredStoreLocations: StoreLocation[];
  isLoading: boolean;
  error: any;
  tutorial_step: number;
  inputtedAddress: string;
  cityProvince: string;
  defaultCityProvince: DropdownOption | null;
  featuredProducts: PaginatedResponse<FeaturedProduct>;
  featuredCL: FeaturedCL | null;
  isSearchingAddress: boolean;
  isFromHomeLandingPage: boolean;
  selectedStoreIndex: number | null;
  selectedStoreLocation: StoreLocation | undefined;
}

const initialState: HomeState = {
  storeLocations: [],
  filteredStoreLocations: [],
  isLoading: false,
  error: null,
  tutorial_step: CITY_SELECTION_TUTORIAL_STEP,
  inputtedAddress: '',
  cityProvince: '',
  defaultCityProvince: null,
  featuredProducts: {
    count: 0,
    limit: 0,
    next: '',
    offset: 0,
    previous: '',
    results: [],
  },
  featuredCL: null,
  isSearchingAddress: false,
  isFromHomeLandingPage: false,
  selectedStoreIndex: null,
  selectedStoreLocation: undefined,
};

const normalizeString = (str: string) =>
str
  .replace(/\(([^)]+)\)/g, '')
  .trim()
  .split(' ')
  .map(_.capitalize)
  .join(' ');

const homeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addStoreLocations.fulfilled, (state, action: any) => {
      state.storeLocations = [...state.storeLocations, ...action.payload];
      state.filteredStoreLocations = [...state.filteredStoreLocations, ...action.payload];
    })
    .addCase(setTutorial, (state, action) => {
      state.tutorial_step = action.payload;
    })
    .addCase(setIsSearchingAddress, (state, action) => {
      state.isSearchingAddress = action.payload;
    })
    .addCase(setIsFromHomeLandingPage, (state, action) => {
      state.isFromHomeLandingPage = action.payload;
    })
    .addCase(filterPins, (state, action) => {
      const selectedCityProvince = action.payload;
      const filteredOutput = state.storeLocations.filter((storeLocation) => {
        const { city, province } = storeLocation;
        if (city && province) {
          return (
            `${normalizeString(city)}, ${normalizeString(province)}` ===
            selectedCityProvince
          );
        }

        return false;
      });
      state.filteredStoreLocations = filteredOutput;
    })
    .addCase(setInputtedAddress, (state, action) => {
      state.inputtedAddress = action.payload;
    })
    .addCase(setCityProvince, (state, action) => {
      state.cityProvince = action.payload;
      if (action.payload !== '') {
        state.defaultCityProvince = {
          ...state.defaultCityProvince,
          label: action.payload,
          value: action.payload,
        };
      } else {
        state.defaultCityProvince = null;
      }
    })
    .addCase(setSelectedStoreLocation, (state, action) => {
      state.selectedStoreLocation = action.payload.selectedStoreLocation;
      state.selectedStoreIndex = action.payload.selectedStoreIndex;
    })
    .addCase(closeStoreInfoWindow, (state) => {
      state.selectedStoreLocation = undefined;
    })
    .addCase(revertToAllStoreLocations, (state) => {
      state.filteredStoreLocations = state.storeLocations;
    })
    .addCase(getFeaturedProducts.fulfilled, (state, action) => {
      state.featuredProducts = action.payload;
    })
    .addCase(getFeaturedCL.fulfilled, (state, action) => {
      state.featuredCL = action.payload;
    })
    .addMatcher(isPending(getStoreLocations, getFeaturedProducts), (state) => {
      state.isLoading = true;
    })
    .addMatcher(isFulfilled(getStoreLocations, getFeaturedProducts), (state) => {
      state.isLoading = false;
    })
    .addMatcher(
      isRejected(getStoreLocations, getFeaturedProducts),
      (state, action) => {
        state.error = action.error.message;
        log({
          errorId: 'Store Location Error',
          isMobile: false,
          error: action.error,
          info: '/getStoreLocations Error',
        });
      },
    );
});

export default homeReducer;
