import { useRef } from 'react';
import { useOutsideAlerter } from '@app/hooks';

interface IOutsideAlerterProps {
  children: any,
  onClickOutside: (evt: any) => void
}

const OutsideAlerter:any = (props:IOutsideAlerterProps) => {
  const ref = useRef(null);
  const { children, onClickOutside } = props;
  useOutsideAlerter(ref, onClickOutside);

  return (
    <div ref={ref}>{children}</div>
  );
};

export default OutsideAlerter;
