import { createReducer, isPending, isRejected } from '@reduxjs/toolkit';
import { SarisukiApiError } from '@customTypes/api';
import { OrderData } from '@sarisuki/orders/types/orderData';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { OrderSukiData } from '@sarisuki/orders/types/orderSukiData';
import log from '@utils/log';
import { OrderAgainData } from '@sarisuki/orders/types/orderAgainData';
import { getOrderDetails, getOrders, orderAgain, resetOrderAgainData, showNoOrderError } from '.';

export interface OrdersState {
  isLoading: boolean;
  error: SarisukiApiError | null;
  data: OrderData | null;
  sukiOrderData: PaginatedResponse<OrderSukiData> | null;
  noOrder: boolean;
  order_id: string | null
  orderAgainData: OrderAgainData | null
}

const initialState: OrdersState = {
  data: null,
  sukiOrderData: {
    count: 0,
    limit: 0,
    next: '',
    offset: 0,
    previous: '',
    results: [],
  },
  error: null,
  isLoading: false,
  noOrder: false,
  order_id: null,
  orderAgainData: null,
};

const ordersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getOrderDetails.fulfilled, (state, action) => {
      const { payload } = action;
      state.isLoading = false;
      state.data = payload;
    })
    .addCase(getOrders.fulfilled, (state, action) => {
      state.sukiOrderData = action.payload;
      state.isLoading = false;
    })
    .addCase(showNoOrderError, (state, action) => {
      state.noOrder = action.payload;
    })
    .addCase(resetOrderAgainData, (state) => {
      state.orderAgainData = null;
    });
    builder.addCase(orderAgain.fulfilled, (state, action) => {
      state.orderAgainData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(orderAgain.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(orderAgain.rejected, (state, action) => {
      state.isLoading = false;
      log({ errorId: 'Cart Error', isMobile: false, error: action.error, info: '/orderAgain Error' });
    })
    .addMatcher(isPending(getOrderDetails, getOrders), (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addMatcher(isRejected(getOrderDetails, getOrders), (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = {
        code: error.code!,
        message: error.message!,
        name: error.name!,
      };
      log({ errorId: 'Order Error', isMobile: false, error: action.error, info: '/getOrder Error' });
    });
});

export default ordersReducer;
