import { createReducer } from '@reduxjs/toolkit';
import { BaseModalProps } from '@sarisuki/modal/types';
import { hideModal, showModal } from './actions';

export interface ModalState {
  isVisible: boolean;
  modalType?: string | null;
  modalProps?: BaseModalProps | null;
}

const initialState: ModalState = {
  isVisible: false,
  modalProps: null,
  modalType: null,
};

const modalReducer = createReducer(initialState, {
  [showModal.type]: (state, action) => {
    if (!showModal.match(action)) {
      return;
    }
    // Start Handling the modal
    state.isVisible = true;
    state.modalType = action.payload.modalType;
    state.modalProps = action.payload.modalProps;
  },
  [hideModal.type]: (state) => {
    state.isVisible = false;
    state.modalProps = null;
    state.modalType = null;
  },
});
export default modalReducer;
