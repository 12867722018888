import axios from '@const/api';
import { LocalStorageKeys } from '@const/keys';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { SignupFormData } from '@sarisuki/signup/types';

export const submitSignupForm = createAsyncThunk<
  SignupFormData,
  SignupFormData
>('signup/submitSignupForm', async (formData) => formData);

export interface SignupSukiOptions {
  first_name: string,
  last_name: string,
  birthdate?: Date,
  email?: string,
  otp: string,
  mobile_number: string,
  referral_code?: string,
}

interface SignupSukiResponse {
  key: string;
}

export const signupSuki = createAsyncThunk<
  SignupSukiResponse | undefined,
  SignupSukiOptions
>(
  'signup/signupSuki',
  async (payload) => {
    let response;
    if (!payload.birthdate) {
      delete payload.birthdate;
    }
    try {
      response = await axios.post<
        SignupSukiOptions,
        AxiosResponse<SignupSukiResponse>
      >('/users/suki/signup/', {
        ...payload,
      });

      localStorage.setItem(LocalStorageKeys.USER_TOKEN, response.data.key);
    } catch (err: any) {
      if (err.response) {
        const { errors } = err.response.data;
        throw errors[0];
      }
    }

    return response?.data;
  },
  {
    serializeError: (error: any) => error,
  },
);

export const setUserFromCheckout = createAction<boolean>(
  'signup/setUserFromCheckout',
);

export const setUserFromOTP = createAction<boolean>(
  'signup/setUserFromOTP',
);

export const setUserInfoForSignup = createAction<SignupFormData | null>(
  'signup/setUserInfoForSignup',
);
