import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { VStack, Text, HStack } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';
import { Box, Button, IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import { useMobileMediaQuery } from '@hooks/responsive_hook';
import { useAppSelector } from '@app/hooks';
import { getReferralCode, getVoucherConfig, selectReferral } from '@sarisuki/referral/referralSlice';
import ModalImage from '@assets/images/referralBanner1.png';
import { useEffect, useState } from 'react';
import { LocalStorageKeys } from '@const/keys';
import ChevronsRight from '@assets/icons/chevrons-right.svg';
import { useDispatch } from 'react-redux';
import numberFormatter from '@utils/numberFormatter';

interface IProps extends BaseModalProps {}

const ShareReferralCodeModal: React.FC<IProps> = ({ open, onDismiss }: IProps) => {
  const { referralCode, voucherConfig } = useAppSelector(selectReferral);
  const isMobile = useMobileMediaQuery();
  const [showCopiedIcon, setShowCopiedIcon] = useState(false);
  const [showCopiedLinkIcon, setShowCopiedLinkIcon] = useState(false);
  const dispatch = useDispatch();

  const onCopyButtonClick = () => {
    if (referralCode !== null) {
      navigator.clipboard.writeText(referralCode);
      setShowCopiedIcon(true);
    }
  };

  useEffect(() => {
    dispatch(getVoucherConfig('FOR_REFERRER'));
    if (!referralCode) dispatch(getReferralCode());
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (showCopiedIcon) setShowCopiedIcon(false);
      if (showCopiedLinkIcon) setShowCopiedLinkIcon(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [showCopiedIcon, showCopiedLinkIcon]);

  const onDontShowAgainClick = () => {
    localStorage.setItem(LocalStorageKeys.DISPLAY_REFERRAL_MODAL, 'false');
    onDismiss();
  };

  const onShareMyLinkClick = () => {
    if (referralCode !== null) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(
          `${window.location.host}/signup?referral_code=${referralCode}`,
        );
      } else {
        console.error('Note to developers: you need HTTPS for this to work.');
      }

      setShowCopiedIcon(false);
      setShowCopiedLinkIcon(true);
    }
  };

  return (
    <Modal isCentered isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <VStack bg="white" pt={0} px={6} h="full">
        <ModalContent closeOnOverlayClick={false} maxW={['288px', null, '530px']}>
          <ModalCloseButton color="white" />

          { /* orange top side */ }
          <VStack spacing={0} w="100%" boxShadow="md">
            <VStack spacing={0} w="100%" boxShadow="md" pb={2} roundedTop={5}>
              <VStack
                w="full"
                h="full"
                backgroundColor="orange.500"
                px="16px"
                pb="10px"
                gap="8px"
                roundedTop={5}
              >
                <HStack pb={3}>
                  {!isMobile &&
                    <Box
                      w="180px"
                      h="135px"
                      backgroundColor="orange.500"
                      backgroundImage={ModalImage.src}
                      roundedTop={5}
                    />
                  }

                  <VStack pr={[0, '50px']} pt="30px" alignItems="left" spacing={0}>
                    <Text fontWeight={600} fontSize="16px" color="white" minW="100px" pl="10px">
                      Share your referral code, get
                    </Text>

                    <Text fontWeight={600} fontSize="16px" color="white" minW="100px" pl="10px">
                      { numberFormatter.formatNumberToPHCurrency(voucherConfig?.discount_value).replace(/\.00$/, '') } off on your next order!
                    </Text>

                    {!isMobile &&
                      <Text
                        fontWeight="normal"
                        fontSize="14px"
                        color="sarisuki-green.50"
                        minW="100px"
                        pt="20px"
                        pl="10px"
                      >
                        Get { numberFormatter.formatNumberToPHCurrency(voucherConfig?.discount_value).replace(/\.00$/, '') } off when your friend makes
                      </Text>
                    }

                    {!isMobile &&
                      <Text
                        fontWeight="normal"
                        fontSize="14px"
                        color="sarisuki-green.50"
                        minW="100px"
                        pl="10px"
                      >
                        their first successful order.
                      </Text>
                    }
                  </VStack>
                </HStack>

                {!isMobile &&
                  <HStack
                    w="full"
                    h="52px"
                    backgroundColor="orange.600"
                    alignItems="center"
                    textAlign="center"
                    borderRadius="100px"
                  >
                    <Box
                      display="flex"
                      backgroundColor="white"
                      w="full"
                      h="full"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="100px"
                      pl="30px"
                    >
                      <Text
                        textAlign="left"
                        fontSize="11px"
                        fontWeight="600"
                        lineHeight="16px"
                        color="orange.600"
                        w="75%"
                      >
                        1. Share your referral code
                      </Text>
                    </Box>

                    <ChevronsRight width="90px" />
                    <Box
                      display="flex"
                      backgroundColor="orange.600"
                      w="full"
                      h="full"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text
                        fontSize="11px"
                        lineHeight="16px"
                        color="white"
                        w="full"
                        textAlign="left"
                      >
                        2. Friend uses your code to sign up and order
                      </Text>
                    </Box>

                    <ChevronsRight width="90px" />
                    <Box
                      display="flex"
                      backgroundColor="orange.600"
                      w="full"
                      h="full"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="100px"
                    >
                      <Text
                        fontSize="11px"
                        lineHeight="16px"
                        color="white"
                        w="full"
                        textAlign="left"
                      >
                        3. Get your { numberFormatter.formatNumberToPHCurrency(voucherConfig?.discount_value).replace(/\.00$/, '') } off voucher
                      </Text>
                    </Box>
                  </HStack>
                }

                {isMobile &&
                  <HStack
                    w="full"
                    h="52px"
                    backgroundColor="orange.600"
                    alignItems="center"
                    textAlign="center"
                    borderRadius="100px"
                  >
                    <Box
                      display="flex"
                      fontSize="9px"
                      lineHeight="12px"
                      backgroundColor="white"
                      w="full"
                      h="full"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="100px"
                      pl="5px"
                    >
                      <Text fontSize="9px" fontWeight="500" lineHeight="12px" color="orange.600" w="75px">
                        1. Share your &nbsp; &nbsp; &nbsp;
                        &nbsp;referral code
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      fontSize="9px"
                      lineHeight="12px"
                      backgroundColor="orange.600"
                      w="full"
                      h="full"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text fontSize="9px" fontWeight="300" lineHeight="12px" color="white" w="75px" textAlign="left">
                        2. Friend uses your code to sign up and order
                      </Text>
                    </Box>

                    <Box
                      display="flex"
                      fontSize="9px"
                      fontWeight="300"
                      lineHeight="12px"
                      backgroundColor="orange.600"
                      w="full"
                      h="full"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="100px"
                    >
                      <Text fontSize="9px" lineHeight="12px" color="white" w="full" textAlign="left">
                        3. Get your { numberFormatter.formatNumberToPHCurrency(voucherConfig?.discount_value).replace(/\.00$/, '') } off voucher
                      </Text>
                    </Box>
                  </HStack>
                }
              </VStack>
            </VStack>
          </VStack>

          { /* white bottom side */ }
          <VStack bg="white" placeSelf="center" w={['288px', null, '530px']} roundedBottom={5}>
            <Text fontSize="12px" fontWeight={400} color="gray.700" pt={6}>SHARE YOUR REFERRAL CODE</Text>

            { /* Referral Code Button */ }
            {!showCopiedIcon &&
              <InputGroup w="202px" bg="gray.100" borderRadius="4px">
                <Input
                  value={referralCode !== null ? referralCode : undefined}
                  isReadOnly
                  color="gray.800"
                  fontWeight={600}
                  fontSize="16px"
                  textAlign="center"
                />
                <InputRightElement width="50px">
                  <IconButton
                    aria-label="copy button"
                    icon={<FiCopy />}
                    boxSize={9}
                    color="orange.500"
                    _focus={{ bg: 'gray.100' }}
                    _hover={{ bg: 'gray.100' }}
                    bg="gray.100"
                    onClick={onCopyButtonClick}
                  />
                </InputRightElement>
              </InputGroup>
            }

            {showCopiedIcon &&
              <Input
                w="202px"
                bg="gray.100"
                borderRadius="8px"
                value="Copied!"
                isReadOnly
                color="gray.800"
                fontWeight={600}
                fontSize="16px"
                textAlign="center"
                p={4}
              />
            }

            {/* Share Link Button */}
            <Button
              fontWeight="bold"
              fontSize="16px"
              w="202px"
              borderRadius="4px"
              colorScheme="sarisuki-green"
              onClick={onShareMyLinkClick}
              px="24px"
              py="9px"
            >
              {!showCopiedLinkIcon && 'Share my link'}
              {showCopiedLinkIcon && 'Copied!'}
            </Button>

            <Text as="button" color="gray.400" fontWeight={600} fontSize="10px" pt={6} pb={10} onClick={onDontShowAgainClick}>
              Don&apos;t show this again
            </Text>
          </VStack>
        </ModalContent>
      </VStack>
    </Modal>
  );
};
export default ShareReferralCodeModal;
