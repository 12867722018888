import { createReducer } from '@reduxjs/toolkit';
import { Location } from '@sarisuki/location/types/location';
import { checkLocationPermission, getCurrentLocation, setLocation } from '.';

interface LocationState {
  isPermissionGranted: boolean;
  location: Location | null;
}

const initialState: LocationState = {
  isPermissionGranted: false,
  location: null,
};

const locationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCurrentLocation.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      state.location = action.payload;
    })
    .addCase(checkLocationPermission.fulfilled, (state, action) => {
      state.isPermissionGranted = action.payload;
    })
    .addCase(setLocation, (state, action) => {
      state.location = action.payload;
    });
});

export default locationReducer;
