import { createReducer } from '@reduxjs/toolkit';
import { BrandOption } from '@components/filterSort/type/BrandOption';
import { CategoryOption } from '@components/filterSort/type/CategoryOption';
import { SortOption } from '@components/filterSort/type/SortOption';

import {
  setSwitchStatus,
  setBrandOptions,
  setCategoryOptions,
  setMinPriceSliderValue,
  setMaxPriceSliderValue,
  resetPriceSliderValue,
  resetSelectedBrandOptions,
  resetSelectedCategoryOptions,
  updateSelectedBrandOptions,
  updateSelectedCategoryOptions,
  setSortOption,
  resetSortOption,
  resetAllFilterOptions,
  setIsApplyFilterButtonDisabled,
  updatePreviousFilterStates,
  revertToPreviousFilterStates,
  showFilterSortExitBottomsheet,
  hideFilterSortExitBottomsheet,
  showFilterSortPanel,
  hideFilterSortPanel,
} from '@components/filterSort/filterSlice';

export interface FilterSortState {
  prevBrandOptions: BrandOption[];
  prevCategoryOptions: CategoryOption[];
  prevSelectedBrandOptions: string[];
  prevSelectedCategoryOptions: string[];
  prevMinPriceSliderValue: number;
  prevMaxPriceSliderValue: number;
  prevSortOption: SortOption | null;

  prevIsCategoryParamActive: boolean;
  prevIsBrandParamActive: boolean;
  prevIsPriceParamActive: boolean;
  prevIsMinPriceParamActive: boolean;
  prevIsMaxPriceParamActive: boolean;
  prevIsSortParamActive: boolean;
  prevIsAnyFilterParamActive: boolean;
  prevIsApplyFilterButtonDisabled: boolean;

  brandOptions: BrandOption[];
  categoryOptions: CategoryOption[];
  selectedBrandOptions: string[];
  selectedCategoryOptions: string[];
  minPriceSliderValue: number;
  maxPriceSliderValue: number;
  sortOption: SortOption | null;

  isSwitchChecked: boolean;
  isCategoryParamActive: boolean;
  isBrandParamActive: boolean;
  isPriceParamActive: boolean;
  isMinPriceParamActive: boolean;
  isMaxPriceParamActive: boolean;
  isSortParamActive: boolean;
  isAnyFilterParamActive: boolean;
  isApplyFilterButtonDisabled: boolean;

  isFilterPanelOpen: boolean;
  isModalOpen: boolean;
}

const initialState: FilterSortState = {
  prevBrandOptions: [],
  prevCategoryOptions: [],
  prevSelectedBrandOptions: [],
  prevSelectedCategoryOptions: [],
  prevMinPriceSliderValue: 0,
  prevMaxPriceSliderValue: 10000,
  prevSortOption: null,

  prevIsCategoryParamActive: false,
  prevIsBrandParamActive: false,
  prevIsPriceParamActive: false,
  prevIsMinPriceParamActive: false,
  prevIsMaxPriceParamActive: false,
  prevIsSortParamActive: false,
  prevIsAnyFilterParamActive: false,
  prevIsApplyFilterButtonDisabled: false,

  brandOptions: [],
  categoryOptions: [],
  selectedBrandOptions: [],
  selectedCategoryOptions: [],
  minPriceSliderValue: 0,
  maxPriceSliderValue: 10000,
  sortOption: null,

  isSwitchChecked: false,
  isCategoryParamActive: false,
  isBrandParamActive: false,
  isPriceParamActive: false,
  isMaxPriceParamActive: false,
  isMinPriceParamActive: false,
  isSortParamActive: false,
  isAnyFilterParamActive: false,
  isApplyFilterButtonDisabled: true,

  isFilterPanelOpen: false,
  isModalOpen: false,
};

const filterSortReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSwitchStatus, (state, action) => {
    state.isSwitchChecked = action.payload;
  })
  .addCase(updateSelectedBrandOptions, (state, action) => {
    const updatedBrandOptions = [...state.brandOptions.map((option) => (
      option.label === action.payload ?
      {
        ...option,
        isSelected: !option.isSelected,
      } : option
    ))];

    const updatedSelectedBrands = updatedBrandOptions
      .filter((option) => option.isSelected)
      .map((option) => option.value);

    if (updatedSelectedBrands.length !== 0) {
        state.isBrandParamActive = true;
        state.isAnyFilterParamActive = true;
    } else {
        state.isBrandParamActive = initialState.isBrandParamActive;
        const isActive = [
          state.isCategoryParamActive,
          state.isPriceParamActive,
          state.isSortParamActive,
        ];
        state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
    }

    state.selectedBrandOptions = [...updatedSelectedBrands];
    state.brandOptions = [...updatedBrandOptions];
  })
  .addCase(updateSelectedCategoryOptions, (state, action) => {
    const updatedCategoryOptions = [...state.categoryOptions.map((option) => (
        (option.value === action.payload || option.label === action.payload) ?
        {
          ...option,
          isSelected: !option.isSelected,
        } : option
    ))];

    const updatedSelectedCategories = updatedCategoryOptions
        .filter((option) => option.isSelected)
        .map((option) => option.value);

    if (updatedSelectedCategories.length !== 0) {
      state.isCategoryParamActive = true;
      state.isAnyFilterParamActive = true;
    } else {
      state.isCategoryParamActive = initialState.isCategoryParamActive;
      const isActive = [
          state.isBrandParamActive,
          state.isPriceParamActive,
          state.isSortParamActive,
      ];
      state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
    }

    state.selectedCategoryOptions = [...updatedSelectedCategories];
    state.categoryOptions = [...updatedCategoryOptions];
  })
  .addCase(setBrandOptions, (state, action) => {
    state.brandOptions = action.payload.map(({ brand }) => (
      { label: brand, value: brand, isSelected: false }
    )).filter((brandOption) => brandOption.value !== null);
    state.selectedBrandOptions = initialState.selectedBrandOptions;
    state.prevBrandOptions = state.brandOptions;

    const isActive = [
      state.isCategoryParamActive,
      state.isPriceParamActive,
      state.isSortParamActive,
    ];
    state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
  })
  .addCase(setCategoryOptions, (state, action) => {
    state.categoryOptions = action.payload.map(({ name, slug }) => (
      { label: name, value: slug, isSelected: false }
    )).filter((brandOption) => brandOption.value !== null);
    state.selectedCategoryOptions = initialState.selectedCategoryOptions;
    state.prevCategoryOptions = state.categoryOptions;

    const isActive = [
      state.isBrandParamActive,
      state.isPriceParamActive,
      state.isSortParamActive,
    ];
    state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
  })
  .addCase(setMinPriceSliderValue, (state, action) => {
    const updatedMinPriceSliderValue = action.payload;
    state.minPriceSliderValue = updatedMinPriceSliderValue;

    if (updatedMinPriceSliderValue !== initialState.minPriceSliderValue) {
      state.isMinPriceParamActive = true;
      state.isPriceParamActive = true;
      state.isAnyFilterParamActive = true;
    } else {
      state.isMinPriceParamActive = initialState.isMinPriceParamActive;
      state.isPriceParamActive = state.isMaxPriceParamActive;

      const isActive = [
        state.isBrandParamActive,
        state.isCategoryParamActive,
        state.isMaxPriceParamActive,
        state.isSortParamActive,
      ];
      state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
    }
  })
  .addCase(setMaxPriceSliderValue, (state, action) => {
    const updatedMaxPriceSliderValue = action.payload;
    state.maxPriceSliderValue = updatedMaxPriceSliderValue;

    if (updatedMaxPriceSliderValue !== initialState.maxPriceSliderValue) {
      state.isMaxPriceParamActive = true;
      state.isPriceParamActive = true;
      state.isAnyFilterParamActive = true;
    } else {
      state.isMaxPriceParamActive = initialState.isMaxPriceParamActive;
      state.isPriceParamActive = state.isMinPriceParamActive;

      const isActive = [
        state.isBrandParamActive,
        state.isCategoryParamActive,
        state.isMinPriceParamActive,
        state.isSortParamActive,
      ];
      state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
    }
  })
  .addCase(setSortOption, (state, action) => {
    const updatedSortOption = action.payload;
    state.sortOption = updatedSortOption;

    if (updatedSortOption !== null) {
      state.isSortParamActive = true;
      state.isAnyFilterParamActive = true;
    } else {
      const isActive = [
        state.isBrandParamActive,
        state.isCategoryParamActive,
        state.isPriceParamActive,
      ];
      state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
    }
  })
  .addCase(resetSelectedBrandOptions, (state) => {
    state.brandOptions = [...state.brandOptions.map((option) => (
      { ...option, isSelected: false }
    ))];
    state.selectedBrandOptions = initialState.selectedBrandOptions;

    const isActive = [
      state.isCategoryParamActive,
      state.isPriceParamActive,
      state.isSortParamActive,
    ];
    state.isBrandParamActive = initialState.isBrandParamActive;
    state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
  })
  .addCase(resetSelectedCategoryOptions, (state) => {
    state.categoryOptions = [...state.categoryOptions.map((option) => (
      { ...option, isSelected: false }
    ))];
    state.selectedCategoryOptions = initialState.selectedCategoryOptions;

    const isActive = [
      state.isBrandParamActive,
      state.isPriceParamActive,
      state.isSortParamActive,
    ];
    state.isCategoryParamActive = initialState.isCategoryParamActive;
    state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
  })
  .addCase(resetPriceSliderValue, (state) => {
    state.minPriceSliderValue = initialState.minPriceSliderValue;
    state.maxPriceSliderValue = initialState.maxPriceSliderValue;
    state.isPriceParamActive = initialState.isPriceParamActive;
    state.isMinPriceParamActive = initialState.isMinPriceParamActive;
    state.isMaxPriceParamActive = initialState.isMaxPriceParamActive;

    const isActive = [
      state.isBrandParamActive,
      state.isCategoryParamActive,
      state.isSortParamActive,
    ];
    state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
  })
  .addCase(resetSortOption, (state) => {
    state.sortOption = initialState.sortOption;
    state.isSortParamActive = initialState.isSortParamActive;

    const isActive = [
      state.isBrandParamActive,
      state.isCategoryParamActive,
      state.isPriceParamActive,
    ];
    state.isAnyFilterParamActive = isActive.reduce((final, curr) => final || curr, false);
  })
  .addCase(resetAllFilterOptions, (state) => {
    state.brandOptions = [...state.brandOptions.map((option) => (
      { ...option, isSelected: false }
    ))];
    state.categoryOptions = [...state.categoryOptions.map((option) => (
        { ...option, isSelected: false }
    ))];

    state.selectedBrandOptions = initialState.selectedBrandOptions;
    state.selectedCategoryOptions = initialState.selectedCategoryOptions;
    state.minPriceSliderValue = initialState.minPriceSliderValue;
    state.maxPriceSliderValue = initialState.maxPriceSliderValue;
    state.sortOption = initialState.sortOption;
    state.isSwitchChecked = initialState.isSwitchChecked;

    state.isBrandParamActive = initialState.isBrandParamActive;
    state.isCategoryParamActive = initialState.isCategoryParamActive;
    state.isPriceParamActive = initialState.isPriceParamActive;
    state.isMinPriceParamActive = initialState.isMinPriceParamActive;
    state.isMaxPriceParamActive = initialState.isMaxPriceParamActive;
    state.isSortParamActive = initialState.isSortParamActive;
    state.isAnyFilterParamActive = initialState.isAnyFilterParamActive;
  })
  .addCase(setIsApplyFilterButtonDisabled, (state, action) => {
    state.isApplyFilterButtonDisabled = action.payload;
  })
  .addCase(updatePreviousFilterStates, (state) => {
    state.prevBrandOptions = state.brandOptions;
    state.prevCategoryOptions = state.categoryOptions;
    state.prevSelectedBrandOptions = state.selectedBrandOptions;
    state.prevSelectedCategoryOptions = state.selectedCategoryOptions;
    state.prevMinPriceSliderValue = state.minPriceSliderValue;
    state.prevMaxPriceSliderValue = state.maxPriceSliderValue;
    state.prevSortOption = state.sortOption;

    state.prevIsCategoryParamActive = state.isCategoryParamActive;
    state.prevIsBrandParamActive = state.isBrandParamActive;
    state.prevIsPriceParamActive = state.isPriceParamActive;
    state.prevIsMaxPriceParamActive = state.isMaxPriceParamActive;
    state.prevIsMinPriceParamActive = state.isMinPriceParamActive;
    state.prevIsSortParamActive = state.isSortParamActive;
    state.prevIsAnyFilterParamActive = state.isAnyFilterParamActive;
    state.prevIsApplyFilterButtonDisabled = state.isApplyFilterButtonDisabled;
  })
  .addCase(revertToPreviousFilterStates, (state) => {
    state.brandOptions = state.prevBrandOptions;
    state.categoryOptions = state.prevCategoryOptions;
    state.selectedBrandOptions = state.prevSelectedBrandOptions;
    state.selectedCategoryOptions = state.prevSelectedCategoryOptions;
    state.minPriceSliderValue = state.prevMinPriceSliderValue;
    state.maxPriceSliderValue = state.prevMaxPriceSliderValue;
    state.sortOption = state.prevSortOption;

    state.isCategoryParamActive = state.prevIsCategoryParamActive;
    state.isBrandParamActive = state.prevIsBrandParamActive;
    state.isPriceParamActive = state.prevIsPriceParamActive;
    state.isMaxPriceParamActive = state.prevIsMaxPriceParamActive;
    state.isMinPriceParamActive = state.prevIsMinPriceParamActive;
    state.isSortParamActive = state.prevIsSortParamActive;
    state.isAnyFilterParamActive = state.prevIsAnyFilterParamActive;
    state.isApplyFilterButtonDisabled = state.prevIsApplyFilterButtonDisabled;
  })
  .addCase(showFilterSortExitBottomsheet, (state) => {
    state.isModalOpen = true;
  })
  .addCase(hideFilterSortExitBottomsheet, (state) => {
    state.isModalOpen = false;
  })
  .addCase(showFilterSortPanel, (state) => {
    state.isFilterPanelOpen = true;
  })
  .addCase(hideFilterSortPanel, (state) => {
    state.isFilterPanelOpen = false;
  });
});

export default filterSortReducer;
