import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { CMS_URL, NEW_CMS_URL } from '@const/api';
import { getCart } from '@sarisuki/cart/cartSlice';
import { getOrderDetails } from '@sarisuki/orders/ordersSlice';
import { getProductCategoriesByStore } from '@sarisuki/productCategories/productCategoriesSlice';
import { getProducts, resetSearchResultCount, setSearchKeyword } from '@sarisuki/products/productsSlice';
import { Store } from '@sarisuki/store/types/store';
import { StoreSuki } from '@sarisuki/store/types/storeSuki';

interface VerifyStoreOptions {
  storeUrl: string;
  user_id: string;
  otp: string;
}

interface GetStoreInfoWithOrderOptions {
  storeUrl: string;
  orderId: string;
}

interface AddStoreSukiOptions
  extends Omit<StoreSuki, 'is_verified' | 'user_id'> {
  storeUrl: string;
}

export const getStores = createAsyncThunk<any>(
  'store/getStores', async () => {
  const response = await axios.get('/stores/');
  return response.data;
});

export const getStoreInfo = createAsyncThunk<Store, string>(
  'store/getStoreInfo',
  async (storeName) => {
    const response = await axios.get(`/stores/${storeName}/`);
    return response.data;
  },
);

export const getStoreInfoWithProducts = createAsyncThunk<Store, string>(
  'store/getStoreInfoWithProducts',
  async (storeUrl, { dispatch }) => {
    const store = await dispatch(getStoreInfo(storeUrl)).unwrap();

    if (!store.url) {
      return store;
    }
    // Only fetch the store products if there is a valid
    // store url
    await dispatch(getProducts(store.url));
    return store;
  },
);

export const getStoreInfoWithCart = createAsyncThunk<Store, string>(
  'store/getStoreInfoWithCart',
  async (storeUrl, { dispatch }) => {
    const store = await dispatch(getStoreInfo(storeUrl)).unwrap();

    if (store.id) {
      await dispatch(getCart(store.id));
    }

    return store;
  },
);

export const getStoreInfoWithOrder = createAsyncThunk<Store, GetStoreInfoWithOrderOptions>(
  'store/getStoreInfoWithOrder',
  async (payload, { dispatch }) => {
    const store = await dispatch(getStoreInfo(payload.storeUrl)).unwrap();
    await dispatch(getOrderDetails(payload.orderId));
    return store;
  },
);

export const getStoreInfoWithProductsAndCart = createAsyncThunk<Store, string>(
  'store/getStoreInfoWithProductsAndCart',
  async (storeUrl, { dispatch }) => {
    const store = await dispatch(getStoreInfo(storeUrl)).unwrap();

    if (store.url) {
      await dispatch(getProducts(storeUrl));
    }

    if (store.id) {
      await dispatch(getCart(store.id));
    }

    return store;
  },
);

export const getStoreInfoWithProductCategoriesProductsAndCart = createAsyncThunk<Store, string>(
    'store/getStoreInfoWithProductCategoriesProductsAndCart',
    async (storeUrl, { dispatch }) => {
      const store = await dispatch(getStoreInfo(storeUrl)).unwrap();

    await Promise.all([
      dispatch(getProductCategoriesByStore(storeUrl)),
      dispatch(getCart(store.id!)),
    ]);

    return store;
  },
);

export const verifyStoreSuki = createAsyncThunk<StoreSuki, VerifyStoreOptions>(
  'store/verifyStoreSuki',
  async (payload) => {
    const { storeUrl, ...restPayload } = payload;
    const response = await axios.post<any>(`/stores/${storeUrl}/sukis/verify/`, restPayload);
    return response.data;
  },
);

export const addStoreSuki = createAsyncThunk<StoreSuki, AddStoreSukiOptions>(
  'store/addStoreSuki',
  async (payload) => {
    const response = await axios.post<any>(`/stores/${payload.storeUrl}/join/`, payload, {
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });

    const { data } = response;
    return data;
  },
);

export const setSearchingState = createAsyncThunk<boolean, any>(
  'store/setSearchingState', async (payload, { dispatch }) => {
    if (!payload as boolean) {
      dispatch(setSearchKeyword(null));
      dispatch(resetSearchResultCount());
    }

    return payload;
  },
);

export const getCutoffs = createAsyncThunk<any>(
  'store/getCutoffs',
  async () => {
    const response = await axios.get('/cutoffs/');
    const payload = { cutoff: response.data };
    return payload;
  },
);

export const setEmoji = createAction(
  'store/setEmoji',
);

export const getBanners = createAsyncThunk<any>('store/getBanners', async () => {
  const response = await axios.get('/cms/banners/');
  return response.data;
});

export const setStoreUrl = createAction<string>(
  'store/setStoreUrl',
);

export const getNearbyStores = createAsyncThunk<any, string>(
  'store/getNearbyStores',
  async (storeUrl) => {
    const response = await axios.get(`/stores/${storeUrl}/nearby-stores/`);
    return response.data;
  },
);

export const getIPAddress = createAsyncThunk<any>(
  'store/getIPAddress',
  async () => {
    const response = await axios.get('https://geolocation-db.com/json/');
    return response.data;
  },
);

export const getLandingPageBanners = createAsyncThunk<any>(
  'store/getLandingPageBanners',
  async () => {
    if (CMS_URL) {
      const response = await axios.get(CMS_URL);
      return response.data;
    }
    return null;
  },
);

export const setShowClearSearchIcon = createAction<boolean>(
  'store/setShowClearSearchIcon',
);

export const setShowFilterSortButton = createAction<boolean>(
  'store/setShowFilterSortButton',
);

export const getRedBanner = createAsyncThunk<any>(
  'store/getRedBanner',
  async () => {
    if (NEW_CMS_URL) {
      const response = await axios.get(`${NEW_CMS_URL}/texts/red-banner-webstore/`);
      const payload = { text: response.data };
      return payload;
    }
    return null;
  },
);
