import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { setCityProvince } from '@sarisuki/home/homeSlice';
import { Location } from '@sarisuki/location/types/location';

export const getCurrentLocation = createAsyncThunk<Location | null>(
  'location/getCurrentLocation',
  async (_, { dispatch }) => {
    const geoPosition = await new Promise<GeolocationPosition | null>((res) => {
      navigator.geolocation.getCurrentPosition(
        (position) => res(position), // Success Callback
        () => res(null), // Error Callback
        { enableHighAccuracy: true,
        },
      );
    });

    if (!geoPosition) {
      return null;
    }
    const { coords } = geoPosition;

    const getCityProvince = async () => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(coords.latitude, coords.longitude);
      const details = await geocoder.geocode({ location: latlng }).then((res: any) => res.results);
      const { address_components } = await details[0];

      const identifyCity = ({ types }: any) => types.includes('locality');
      const identifyProvince = ({ types }: any) => types.includes('administrative_area_level_2');

      const city = await address_components.find(identifyCity);
      const province = await address_components.find(identifyProvince);
      return `${city.long_name}, ${province.long_name}`;
    };

    const cityProvince = await getCityProvince();
    dispatch(setCityProvince(cityProvince));

    return {
      accuracy: coords.accuracy,
      altitude: coords.altitude,
      altitudeAccuracy: coords.altitudeAccuracy,
      latitude: coords.latitude,
      longitude: coords.longitude,
      heading: coords.heading,
      speed: coords.speed,
    };
  },
);

export const setLocation = createAction<any>(
  'location/setLocation',
);

export const checkLocationPermission = createAsyncThunk<boolean>(
  'location/checkLocationPermission',
  async (_, { dispatch }) => {
    const isPermissionGranted = await new Promise<boolean>((res) => {
      navigator.geolocation.getCurrentPosition(
        () => res(true),
        () => res(false),
      );
    });

    /**
     * Get the current user's location
     * if the permission is granted
     */
    if (isPermissionGranted) {
      dispatch(getCurrentLocation());
    }

    return isPermissionGranted;
  },
);
