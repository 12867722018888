import 'src/styles/globals.css';
import '@reach/dialog/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-spring-bottom-sheet/dist/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@fontsource/inter';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import dynamic from 'next/dynamic';
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import { store } from '@app/store';
import ModalManager from '@sarisuki/modal/ModalManager';
import theme from 'src/theme';
import BottomSheetManager from '@sarisuki/bottomSheet/BottomSheetManager';
import Head from 'next/head';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

function App({ Component, pageProps }: AppProps) {
  Sentry.init({
    dsn: 'https://95cbcea59380447681128d6db1eb1fbf@o1011746.ingest.sentry.io/6170162',
    integrations: [new Integrations.BrowserTracing()],
    environment: 'production',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
  });

  return (
    <Provider store={store}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
        />
      </Head>
      <ChakraProvider theme={theme}>
        <Component {...pageProps} />
        <ToastContainer hideProgressBar position="top-right" />
        <ModalManager />
        <BottomSheetManager />
      </ChakraProvider>
    </Provider>
  );
}
export default dynamic(() => Promise.resolve(App), { ssr: false });
