import { VStack, Text, Icon } from '@chakra-ui/react';
import { BaseBottomSheetProps } from '@sarisuki/bottomSheet/types';
import { BottomSheet } from 'react-spring-bottom-sheet';
import VerificationSuccessIcon from '@assets/icons/verification-success.svg';

interface IProps extends BaseBottomSheetProps {}

const OTPSuccessBottomSheet = ({ open, onDismiss }: IProps) => {
  const renderSuccess = () => (
    <VStack h="164px" justifyContent="center" alignItems="center" spacing="24px">
      <Icon as={VerificationSuccessIcon} width="38px" height="45px" />
      <Text fontSize="20px" fontWeight="bold" textAlign="center">
        Verification successful!
      </Text>
    </VStack>
  );

  return (
    <BottomSheet header={false} open={open} onDismiss={onDismiss} className="otp-success-bottom-sheet" scrollLocking>
      {renderSuccess()}
    </BottomSheet>
  );
};

export default OTPSuccessBottomSheet;
