import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';

/**
 * Desktop size is defined with a screen width 1024px onwards.
 * "Small" Desktop size is defined with a screen width between 1024px and 1200px.
 * "Large" Desktop size is defined with a screen width of 1201px and 1300px.
 * "Super Large" Desktop size is defined with a screen width of 1301px onwards
 */

const MINW_DESKTOP = 1024;
const MINW_SMALL_DESKTOP = MINW_DESKTOP;
const MINW_LARGE_DESKTOP = 1201;
const MINW_SUPER_LARGE_DESKTOP = 1301;

const MINW_TABLET = 768;
const MAXW_TABLET_PORTRAIT = MINW_DESKTOP - 1;
const MAXW_TABLET_LANDSCAPE = 1180;

const pixelParse = (width: number) => `${width}px`;

const isMobileWidthSize = (width: number) => (width < MINW_TABLET);
const isTabletWidthSize = (width: number) => (width >= MINW_TABLET && width < MINW_DESKTOP);
const isDesktopWidthSize = (width: number) => (width >= MINW_DESKTOP);
const isSmallDesktopWidthSize = (width: number) =>
  (width >= MINW_SMALL_DESKTOP && width < MINW_LARGE_DESKTOP);
const isLargeDesktopWidthSize = (width: number) =>
  (width >= MINW_LARGE_DESKTOP && width < MINW_SUPER_LARGE_DESKTOP);
const isSuperLargeDesktopWidthSize = (width: number) => (width >= MINW_SUPER_LARGE_DESKTOP);

export const useMobileMediaQuery = () => {
  const maxDeviceWidth = pixelParse(MINW_TABLET - 1);

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    detectSize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  return (
    useMediaQuery({
      maxDeviceWidth,
    }) || isMobileWidthSize(windowDimension.winWidth)
  );
};

export const useTabletMediaQuery = () => {
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  const minDeviceWidth = pixelParse(MINW_TABLET);
  const maxDeviceWidth =
    isPortrait ? pixelParse(MAXW_TABLET_PORTRAIT) : pixelParse(MAXW_TABLET_LANDSCAPE);

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    detectSize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  return (
      useMediaQuery({
        minDeviceWidth,
        maxDeviceWidth,
      }) || isTabletWidthSize(windowDimension.winWidth)
  );
};

export const useDesktopMediaQuery = () => {
  const minWidth = pixelParse(MINW_DESKTOP);

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    detectSize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  return (
    useMediaQuery({
      minWidth,
    }) || isDesktopWidthSize(windowDimension.winWidth)
  );
};

export const useSmallDesktopMediaQuery = () => {
  const minWidth = pixelParse(MINW_SMALL_DESKTOP);
  const maxWidth = pixelParse(MINW_LARGE_DESKTOP - 1);

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    detectSize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  return (
    useMediaQuery({
      minWidth,
      maxWidth,
    }) || isSmallDesktopWidthSize(windowDimension.winWidth)
  );
};

export const useLargeDesktopMediaQuery = () => {
  const minWidth = pixelParse(MINW_LARGE_DESKTOP);
  const maxWidth = pixelParse(MINW_SUPER_LARGE_DESKTOP - 1);

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    detectSize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  return (
    useMediaQuery({
      minWidth,
      maxWidth,
    }) || isLargeDesktopWidthSize(windowDimension.winWidth)
  );
};

export const useSuperLargeDesktopMediaQuery = () => {
  const minWidth = pixelParse(MINW_SUPER_LARGE_DESKTOP);

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    detectSize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  return (
    useMediaQuery({
      minWidth,
    }) || isSuperLargeDesktopWidthSize(windowDimension.winWidth)
  );
};
