import { createReducer, isPending, isRejected } from '@reduxjs/toolkit';
import { Promo } from '@sarisuki/promo/types/promo';
import log from '@utils/log';
import { clearPromos, getAvailablePromos } from '.';

export interface PromoState {
  availablePromos: Promo[];
  isPromoLoading: boolean;
}

const initialState: PromoState = {
  availablePromos: [],
  isPromoLoading: false,
};

const promoReducer = createReducer(initialState, (builder) => {
  builder.addCase(getAvailablePromos.fulfilled, (state, action) => {
    const availablePromos = [action.payload]; // only 1 promo is returned for now
    const sanitizedAvailablePromos = availablePromos.reduce(
      (sanitizedPromos: Promo[], promo) => {
        if (promo.discount_amount) {
          sanitizedPromos.push(promo);
        }
        return sanitizedPromos;
      },
      [],
    );

    state.availablePromos = sanitizedAvailablePromos;
    state.isPromoLoading = false;
  });

  builder.addCase(clearPromos, (state) => {
    state.availablePromos = [];
  });

  builder.addMatcher(isPending(getAvailablePromos), (state) => {
    state.isPromoLoading = true;
    state.availablePromos = [];
  });

  builder.addMatcher(isRejected(getAvailablePromos), (state, action) => {
    state.availablePromos = [];
    state.isPromoLoading = false;
    log({ errorId: 'Promo Error', isMobile: false, error: action.error, info: '/getAvailablePromos Error' });
  });
});

export default promoReducer;
