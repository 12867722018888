import { createReducer, isFulfilled, isPending } from '@reduxjs/toolkit';
import { getProductCategory } from '@sarisuki/productCategories/productCategoriesSlice';
import { setIsAllButtonActive, productSubcategorySelected } from '.';

export interface ProductSubcategoriesState {
  isLoading: boolean;
  isAllButtonActive: boolean;
  selectedSubcategoryIndex: number | null;
}

const initialState: ProductSubcategoriesState = {
  isLoading: false,
  isAllButtonActive: false,
  selectedSubcategoryIndex: null,
};

const productSubcategoriesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(productSubcategorySelected, (state, action) => {
      const productSubcategoryIndex = action.payload;
      state.selectedSubcategoryIndex = productSubcategoryIndex;
    })
    .addCase(setIsAllButtonActive, (state, action) => {
      state.isAllButtonActive = action.payload;
    })
    .addMatcher(isPending(getProductCategory), (state) => {
      state.isLoading = true;
      state.selectedSubcategoryIndex = null;
    })
    .addMatcher(isFulfilled(getProductCategory), (state) => {
      state.isLoading = false;
    });
});

export default productSubcategoriesReducer;
