import { LocalStorageKeys } from '@const/keys';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@const/api';
import { Cart } from '@sarisuki/cart/types/cart';
import { CartErrors } from '@sarisuki/cart/types/cartErrors';
import { BackToSchoolRaffle } from '@sarisuki/cart/types/backToSchoolRaffle';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { GroupBuyCart } from '@sarisuki/cart/types/groupBuyCart';

interface CartActionPayload {
  cart_id: string;
  product_id: number;
  quantity: number;
}

interface CartOwnerUpdatePayload {
  cart_id: string;
  owner: string;
}

interface removeCartOptions {
  cart_id: string;
  product_id: number;
}

interface CreategroupBuyCartOptions {
  store_id: number;
  group_id: string;
  quantity: number;
}

export const addToCart = createAsyncThunk<Cart, CartActionPayload>(
  'cart/addCart',
  async ({ cart_id, ...restValues }) => {
    const response = await axios.post<any>(
      `/carts/${cart_id}/items/`,
      restValues,
    );
    return response.data;
  },
);

export const updateCartItem = createAsyncThunk<Cart, CartActionPayload>(
  'cart/updateCart',
  async ({ cart_id, ...restValues }) => {
    const response = await axios.post<any>(
      `/carts/${cart_id}/items/`,
      restValues,
    );
    return response.data;
  },
);

export const updateCartOwner = createAsyncThunk<Cart, CartOwnerUpdatePayload>(
  'cart/updateCartOwner',
  async ({ cart_id, owner }) => {
    const response = await axios.post<any>(`/carts/${cart_id}/update/`, {
      owner,
    });
    return response.data;
  },
);

export const removeCartItem = createAsyncThunk<Cart, removeCartOptions>(
  'cart/removeCartItem',
  async (value) => {
    const { cart_id, product_id } = value;
    const response = await axios.delete<any>(`/carts/${cart_id}/items/`, {
      data: { product_id },
    });
    return response.data;
  },
);

export const removeCart = createAction('cart/removeCart');

export const clearAllCartItems = createAsyncThunk<Cart, string>(
  'cart/clearAllCartItems',
  async (cart_id) => {
    const response = await axios.post<any>(`/carts/${cart_id}/clear/`);
    return response.data;
  },
);

export const createCart = createAsyncThunk<Cart, number>(
  'cart/createCart',
  async (storeId) => {
    const response = await axios.post<any>('/carts/create/', {
      store_id: storeId,
    });
    return response.data;
  },
);

export const getCart = createAsyncThunk<Cart, number>(
  'cart/getCart',
  async (storeId, { dispatch }) => {
    const storeCart = `${LocalStorageKeys.CART_ID}_${storeId}`;

    // Check if there is an existing cart id
    let cart_id = localStorage.getItem(storeCart);
    if (!cart_id) {
      // Create a new cart
      const cart = await dispatch(createCart(storeId)).unwrap();
      localStorage.setItem(storeCart, cart.id);
      cart_id = cart.id;
    }

    try {
      const response = await axios.get(`/carts/${cart_id}/`);
      const { is_checked_out } = response.data;
      if (is_checked_out) {
        localStorage.removeItem(storeCart);
        dispatch(getCart(storeId));
      }
      return response.data;
    } catch (error: any) {
      const { status } = error.response;
      if (status < 500) {
        localStorage.removeItem(storeCart);
        dispatch(getCart(storeId));
      }
      return error.response;
    }
  },
);

export const checkCartForError = createAsyncThunk<CartErrors[], string>(
  'cart/checkCartForError',
  async (order_id) => {
    const response = await axios({
      method: 'post',
      url: `/orders/${order_id}/check-order-for-reorder/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });
    return response.data;
  },
);

export const setCartError = createAction('cart/setCartError');

export const computeRaffleEntries = createAsyncThunk<
  PaginatedResponse<BackToSchoolRaffle>,
  string
>('cart/computeRaffleEntries', async (cart_id) => {
  const response = await axios.get(`/raffle-entries/cart/${cart_id}`);
  return response.data;
});

export const createGroupBuyCart = createAsyncThunk<
  GroupBuyCart,
  CreategroupBuyCartOptions
>('cart/createGroupBuyCart', async (payload) => {
  const response = await axios({
    method: 'post',
    url: '/groupbuy/cart/create/',
    data: payload,
    headers: {
      Authorization: `Token ${localStorage.getItem('user_token')}`,
    },
  });
  return response.data;
});
