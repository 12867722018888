import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { Province } from '@customTypes/province';
import axios from '@const/api';
import { City } from '@customTypes/city';
import { Region } from '@customTypes/region';
import { Barangay } from '@customTypes/barangay';
import {
  GetBarangaysOptions,
  GetCitiesOptions,
} from '@sarisuki/dropdown/types';

const DEFAULT_LIMIT: number = 9999;

export const getProvinces = createAsyncThunk<
  PaginatedResponse<Province>,
  number | undefined
>('dropdown/getProvinces', async (regionId) => {
  let url = `/address/provinces/?limit=${DEFAULT_LIMIT}`;
  if (regionId) {
    url += `&region_id=${regionId}`;
  }
  const response = await axios.get(url);
  return response.data;
});

export const getCities = createAsyncThunk<
  PaginatedResponse<City>,
  GetCitiesOptions | undefined
>('dropdown/getCities', async (options) => {
  let url = '/address/cities/';
  if (options) {
    const { provinceId, limit = DEFAULT_LIMIT, searchTerm } = options;
    url += `?province_id=${provinceId || ''}&limit=${limit || ''}&search=${
      searchTerm || ''
    }`;
  }
  const response = await axios.get(url);
  return response.data;
});
export const getRegions = createAsyncThunk<PaginatedResponse<Region>>(
  'dropdown/getRegions',
  async () => {
    const response = await axios.get(
      `/address/regions/?limit=${DEFAULT_LIMIT}`,
    );
    return response.data;
  },
);

export const getBarangays = createAsyncThunk<
  PaginatedResponse<Barangay>,
  GetBarangaysOptions | undefined
>('dropdown/getBarangays', async (options) => {
  let url = '/address/barangays/';

  if (options) {
    const { cityId, limit, searchTerm } = options;
    url += `?city_id=${cityId || ''}&search=${searchTerm || ''}&limit=${
      limit || ''
    }`;
  }

  const response = await axios.get(url);

  return response.data;
});
