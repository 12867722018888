import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Text, VStack } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';
import { FC } from 'react';
import { useAppSelector } from '@app/hooks';
import { List, ListItem, ListIcon, Button, Spacer, ModalCloseButton } from '@chakra-ui/react';
import { MdError } from 'react-icons/md';
import { useRouter } from 'next/router';
import { selectCheckout } from '@sarisuki/checkout/checkoutSlice';

interface IProps extends BaseModalProps {}

const CheckoutAntiHoardingModal: FC<IProps> = ({ open, onDismiss }: IProps) => {
const { antiHoardingConfig } = useAppSelector(selectCheckout);
const router = useRouter();
const { storeUrl } = router.query;

return (
  <Modal isCentered isOpen={open} onClose={onDismiss}>
    <ModalOverlay />
    <ModalContent w={['95%', '100%']}>
      <ModalBody>
        <VStack align="center" py={['10px', '20px']} px={['10px', '20px']} spacing={[3, 5]}>
          {antiHoardingConfig &&
            <>
              <ModalCloseButton _focus={{ border: 'none' }} _active={{ border: 'none' }} _hover={{ border: 'none' }} />
              <Text
                as="span"
                textColor="gray.900"
                textAlign={['left', 'center']}
                fontWeight={600}
                w="100%"
                fontSize="14px"
              >
                You have exceeded the maximum number of
                {' '}items you can order for the following products:
              </Text>

              <List spacing={3} textAlign="left" w="100%" pl={['0px', '35px']}>
                {antiHoardingConfig.map((data) => (
                  <ListItem key={data.sku} fontSize="12px">
                    <ListIcon as={MdError} color="red.500" />
                    {data.name} (Order Limit: {data.order_limit}).
                  </ListItem>
                ))}
              </List>
            </>
          }
          <Spacer />
          <Button
            mt="20px"
            bg="green.500"
            color="white"
            _focus={{ border: 'none', bg: 'green.500' }}
            _hover={{ border: 'none', bg: 'green.500' }}
            _active={{ border: 'none', bg: 'green.500' }}
            onClick={() => {
              router.push(`/${storeUrl}/cart`);
              onDismiss();
            }}
          >
            Go Back to Cart
          </Button>
        </VStack>
      </ModalBody>
    </ModalContent>
  </Modal>
);
};
export default CheckoutAntiHoardingModal;
