import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@const/api';
import { getOrderDetails } from '@sarisuki/orders/ordersSlice';
import { OrderData } from '@sarisuki/orders/types/orderData';
import { CheckoutFormData } from '@sarisuki/checkout/types';
import { StoreSuki } from '@sarisuki/store/types/storeSuki';
import { SarisukiApiError } from '@customTypes/api';
import { AntiHoardingConfig } from '@sarisuki/checkout/types/AntiHoardingConfig';
import { PaymentDetails } from '@sarisuki/checkout/types/PaymentDetails';
import { CreditDebitToken } from '@sarisuki/checkout/types/CreditDebitToken';

interface CheckoutCartOptions {
  user_id: string;
  delivery_method: string;
  cartId: string;
  delivery_address?: {
    first_name: string,
    last_name: string,
    address: string,
    barangay: string,
    city: string,
    province: string
  };
  payment?: {
    method: string,
    payment_token?: string,
    redirect_urls?: {
      success_url: string,
      failure_url: string,
      cancel_url: string,
    }
  }
}

interface ApplyDiscountOptions {
  discount_type: string;
  code: string;
  cartId: string;
}

interface SelectDiscountOptions {
  discount_type: string;
  code: string;
}

interface resendOptions {
  user_id: string;
  storeUrl: string;
}
interface generateCreditDebitTokenOptions {
  card_number: string;
  card_exp_month: string;
  card_exp_year: string;
  card_cvc: string;
  email: string;
}
interface retryPaymentOptions {
  order_id: string;
  payment_method: string,
  payment_token: string,
  redirect_urls: {
    success_url: string,
    failure_url: string,
    cancel_url: string,
  }
}

export const setIsCheckoutFormValid = createAction<boolean>(
  'checkout/setIsCheckoutFormValid',
);

export const setUpdateInfoFromCheckout = createAction<boolean | null>(
  'checkout/setUpdateInfoFromCheckout',
);

export const setMobileNumber = createAction<string>(
  'checkout/setMobileNumber',
);

export const submitCheckoutForm = createAsyncThunk<CheckoutFormData, CheckoutFormData>(
  'checkout/submitCheckoutForm',
  async (formData) => formData,
);

export const setAntiHoardingConfig = createAction<AntiHoardingConfig[]>(
  'checkout/setAntiHoardingConfig',
);

export const setEWalletDetails = createAction<PaymentDetails | null>(
  'checkout/setEWalletDetails',
);

export const checkoutCart = createAsyncThunk<OrderData, CheckoutCartOptions>(
  'checkout/checkoutCart',
  async (payload, { dispatch }) => {
    const { cartId, ...restPayload } = payload;
    let response;
    try {
      response = await axios.post<any>(`/carts/${cartId}/checkout2/`, restPayload);
    } catch (err: any) {
      if (err.response) {
        const { errors } = err.response.data;
        const error = errors[0];

        if (error.code === 'invalid') {
          switch (error.field) {
            case 'insufficient_stock':
              throw {
                code: 'insufficient_stock',
                message: 'Some of your items have insufficient stock',
              } as SarisukiApiError;
            default:
          }
        } else if (error.code === 'product_limit_exceeded') {
          dispatch(setAntiHoardingConfig(error.extra.products));
        }

        throw error;
      }
      throw err;
    }

    const orderId = response?.data.order_id;
    dispatch(setEWalletDetails(response?.data));
    const orderDetails = await dispatch(getOrderDetails(orderId)).unwrap();

    return orderDetails;
  },
);

export const applyDiscount = createAsyncThunk<any, ApplyDiscountOptions>(
  'checkout/applyDiscount',
  async (payload) => {
    const { cartId, ...restPayload } = payload;

    const response = await axios.post<any>(
      `/carts/${cartId}/discounts/`,
      restPayload, {
        headers: { Authorization: `Token ${localStorage.getItem('user_token')}` },
      },
    );

    return response.data;
  },
);

export const selectDiscount = createAsyncThunk<SelectDiscountOptions, SelectDiscountOptions>(
  'checkout/selectDiscount',
  async (payload) => payload,
);

export const resendOTP = createAsyncThunk<StoreSuki, resendOptions>(
  'checkout/resendOTP',
  async (payload) => {
    const { storeUrl, ...restPayload } = payload;
    const response = await axios.post<any>(`/stores/${storeUrl}/sukis/resend-otp/`, restPayload);
    return response.data;
  },
);

export const setClaimRaffle = createAction<boolean>(
  'checkout/setClaimRaffle',
);

export const generateCreditDebitToken = createAsyncThunk<
  CreditDebitToken, generateCreditDebitTokenOptions>(
  'checkout/generateCreditDebitToken',
  async (payload) => {
    let response;
    try {
      response = await axios.post<any>('/payments/online/payment-methods/token/', payload, {
      headers: { Authorization: `Token ${localStorage.getItem('user_token')}` } });
    } catch (payment_err: any) {
      if (payment_err.response) {
        const { errors } = payment_err.response.data;
        const error = errors[0];
        throw error;
      }
      throw payment_err;
    }
    return response.data;
  },
);

export const setShowPaymentForm = createAction<boolean>(
  'checkout/setShowPaymentForm',
);

export const retryPayment = createAsyncThunk<PaymentDetails, retryPaymentOptions>(
  'checkout/retryPayment',
  async (payload) => {
    const { order_id, ...restPayload } = payload;
    let response;
    try {
      response = await axios.post<any>(`/orders/${order_id}/payment-retry/`, restPayload, {
      headers: { Authorization: `Token ${localStorage.getItem('user_token')}` } });
    } catch (payment_err: any) {
      if (payment_err.response) {
        const { errors } = payment_err.response.data;
        const error = errors[0];
        throw error;
      }
      throw payment_err;
    }
    return response.data;
  },
);

export const setPaymentMethod = createAction<string>(
  'checkout/setPaymentMethod',
);
