import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Text } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { VStack, HStack, Spacer, Divider, IconButton, Icon, Button, useNumberInput, Input, Box } from '@chakra-ui/react';
import { MdOutlineClose } from 'react-icons/md';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import numberFormatter from '@utils/numberFormatter';
import { useTabletMediaQuery } from '@hooks/responsive_hook';
import { createGroupBuyGroup, selectProducts, setActiveGroupBuyId } from '@sarisuki/products/productsSlice';
import { FiUsers } from 'react-icons/fi';
import router from 'next/router';
import { hideModal } from '@sarisuki/modal/modalSlice';
import { selectStore } from '@sarisuki/store/storeSlice';
import { createGroupBuyCart, selectCart } from '@sarisuki/cart/cartSlice';
import { LocalStorageKeys } from '@const/keys';

interface IProps extends BaseModalProps {}
  const ProductBuyWithAGroupModal: React.FC<IProps> = ({ open, onDismiss }: IProps) => {
  const { product, productGroupBuy, activeGroupBuyId, productGroupBuyGroup }
  = useAppSelector(selectProducts);
  const isTablet = useTabletMediaQuery();
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [totalAmount, setTotalAmount] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const { storeUrl } = router.query;
  const { storeId } = useAppSelector(selectStore);
  const { groupBuyCart } = useAppSelector(selectCart);
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps }
    = useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: product?.available_stock_qty || 0,
      precision: 0,
    },
  );
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  useEffect(() => {
    if (input['aria-valuenow']) {
      setTotalItems(input['aria-valuenow']);
      if (productGroupBuy?.price) {
        setTotalAmount(numberFormatter.formatNumberToPHCurrency(productGroupBuy?.price * input['aria-valuenow']));
      }
    }
  }, [input['aria-valuenow']]);

  useEffect(() => {
    if (activeGroupBuyId) {
      dispatch(hideModal());
      router.replace({
        pathname: '/[storeUrl]/groupbuy/[groupBuyId]',
        query: { storeUrl, groupBuyId: activeGroupBuyId },
      });
    }
  }, [activeGroupBuyId]);

  useEffect(() => {
    if (groupBuyCart && activeGroupBuyId === '') {
      dispatch(hideModal());
      localStorage.setItem(LocalStorageKeys.GROUP_BUY_ID, `${groupBuyCart.group}`);
      router.push(`/${storeUrl}/checkout`);
    }
  }, [groupBuyCart]);

  const getGroupBuyCart = async (id: string) => {
    await dispatch(createGroupBuyCart({
      store_id: storeId ?? 0,
      group_id: id,
      quantity: totalItems,
    }));
  };

  useEffect(() => {
    if (productGroupBuyGroup) {
      dispatch(setActiveGroupBuyId(''));
      getGroupBuyCart(productGroupBuyGroup.id);
    }
  }, [productGroupBuyGroup]);

  const onStartAGroupBuyClick = async () => {
    if (!productGroupBuy) return;
    setisLoading(true);
    await dispatch(createGroupBuyGroup(productGroupBuy.id));
  };

  return (
    <Modal isCentered isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent closeOnOverlayClick={false} maxW={isTablet ? '614px' : '1194px'} rounded="xl">
        <VStack pt="20px" px={0} w="100%" pb="72px" spacing={0}>
          <HStack w="100%" px="40px" pb={5}>
            {!isTablet &&
              <HStack>
                <Box bg="dark-orange" borderRadius="4px 0px 8px" p={1}><Icon as={FiUsers} color="white" boxSize="15px" mx={1} mb={1} strokeWidth={2.5} /></Box>
                <Text fontWeight={700} fontSize="20px" color="gray.900">Buy with a group</Text>
              </HStack>
            }
            <Spacer />
            <IconButton
              onClick={onDismiss}
              aria-label="close button"
              icon={<Icon as={MdOutlineClose} boxSize={isTablet ? 10 : 6} color="gray.500" />}
              bg="white"
              _hover={{ border: 'none' }}
              _active={{ border: 'none' }}
              _focus={{ border: 'none' }}
            />
          </HStack>
          {!isTablet && <Divider />}

          <VStack pt={isTablet ? '5px' : '40px'} w="100%" px={isTablet ? '48px' : '202px'} spacing={0}>
            <Text w="100%" fontWeight={500} fontSize="20px" color="black-pearl">{product?.name}</Text>
            <Text w="100%" fontWeight={600} fontSize="16px" color="gray.500">{product?.unit_of_measure}</Text>
            <HStack spacing={0} placeSelf="flex-start" pt={product?.on_sale ? 0 : 5}>
              <Text w="100%" fontWeight={700} fontSize="18px" color="dark-orange">₱</Text>
              <Text w="100%" fontWeight={700} fontSize="24px" color="dark-orange">{numberFormatter.formatNumberToPHCurrency(productGroupBuy?.price ?? 0).substring(1)}</Text>
              <Text pl="16px" pr={4} w="100%" as="s" fontWeight={500} fontSize="20px" color="gray.400">{numberFormatter.formatNumberToPHCurrency(productGroupBuy?.product.default_srp_price ?? 0)}</Text>
              <Box>
                <Text
                  w="80px"
                  h="80px"
                  rotate="15"
                  transform="auto"
                  textAlign="center"
                  className="big-on-sale-bg-orange"
                  fontWeight={700}
                  fontSize="24px"
                  color="dark-orange"
                  spacing={0}
                  pt={3}
                >
                  {productGroupBuy && Math.round(((
                    productGroupBuy.product.default_srp_price - productGroupBuy.price)
                    / productGroupBuy.product.default_srp_price) * 100,
                  )}%
                  <Text fontWeight={500} fontSize="24px" color="dark-orange" mt="-10px">OFF</Text>
                </Text>
              </Box>
            </HStack>

            <HStack w="100%" pb={3}>
              <Text textAlign="left" w="100%" fontWeight={400} fontSize="20px" color="gray.700">Quantity</Text>

              <HStack maxW="198px" maxH={isTablet ? '58px' : '48px'} spacing={0} border="1px" borderColor="gray.100" p={2} borderRadius="32px">
                <Button
                  {...dec}
                  bg="white"
                  p={0}
                  _hover={{ bg: 'white', border: 'none' }}
                  _active={{ bg: 'white', border: 'none' }}
                  _focus={{ bg: 'white', border: 'none' }}
                >
                  <Icon as={BiMinus} boxSize={7} color="orange.500" />
                </Button>
                <Input
                  {...input}
                  border="none"
                  textAlign="center"
                  color="gray.600"
                  fontWeight={700}
                  fontSize="24px"
                  _focus={{ boxShadow: 'none' }}
                />
                <Button
                  {...inc}
                  bg="white"
                  p={0}
                  _hover={{ bg: 'white', border: 'none' }}
                  _active={{ bg: 'white', border: 'none' }}
                  _focus={{ bg: 'white', border: 'none' }}
                >
                  <Icon as={BiPlus} boxSize={7} color="orange.500" />
                </Button>
              </HStack>
            </HStack>

            <Button
              w="100%"
              h={isTablet ? '66px' : '56px'}
              py="16px"
              bg="dark-orange"
              fontWeight={700}
              fontSize="20px"
              color="white"
              _hover={{ bg: 'dark-orange', border: 'none' }}
              _active={{ bg: 'dark-orange', border: 'none' }}
              _focus={{ bg: 'dark-orange', border: 'none' }}
              onClick={onStartAGroupBuyClick}
              isLoading={isLoading}
            >
              Start a group buy ({totalItems} {totalItems === 1 ? 'item' : 'items'}): {totalAmount}
            </Button>

            <Text fontWeight={400} fontSize="16px" color="gray.400" pt={2} lineHeight="24px" textAlign="center" verticalAlign="center">You need to invite{' '}
              <Text as="span" color="red.500" fontWeight={600} fontSize="16px" lineHeight="24px">
                <Icon as={FiUsers} boxSize="17px" mx={1} mb={1} strokeWidth={2.5} />
                {productGroupBuy && productGroupBuy.max_members - 1}{' '}other members
              </Text>
              {' '}to your group before the group buy expires.
            </Text>

            {totalItems === product?.available_stock_qty &&
              <Text fontWeight={600} fontSize="16px" color="red.500" pt={2}>You have reached the maximum order limit of this item.</Text>
            }
          </VStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
export default ProductBuyWithAGroupModal;
