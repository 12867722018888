import { createReducer } from '@reduxjs/toolkit';
import { SignupFormData } from '@sarisuki/signup/types';
import { setUserFromCheckout, setUserFromOTP, setUserInfoForSignup, signupSuki, submitSignupForm } from '.';

export interface SignupState {
  form: SignupFormData | null;
  isLoading: boolean;
  error: any;
  isUserFromCheckout: boolean;
  isUserFromOTP: boolean;
}

const initialState: SignupState = {
  form: null,
  isLoading: false,
  error: null,
  isUserFromCheckout: false,
  isUserFromOTP: false,
};

const signupReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(submitSignupForm.fulfilled, (state, action) => {
      state.form = action.payload;
    })
    .addCase(setUserFromCheckout, (state, action) => {
      state.isUserFromCheckout = action.payload;
    })
    .addCase(setUserInfoForSignup, (state, action) => {
      state.form = action.payload;
    })
    .addCase(signupSuki.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(signupSuki.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(signupSuki.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    })
    .addCase(setUserFromOTP, (state, action) => {
      state.isUserFromOTP = action.payload;
    });
});

export default signupReducer;
