import { createReducer } from '@reduxjs/toolkit';
import { SignupFormData } from '@sarisuki/signup/types';
import log from '@utils/log';
import { LoginFormData } from '@sarisuki/login/types';
import { getSukiInfo, loginSuki, submitLoginForm, updateSukiInfo, verifyMobileNumber, showEditAddressForm, logout } from '.';

export interface LoginState {
  form: LoginFormData | null;
  sukiInfo: SignupFormData | null;
  isLoading: boolean;
  error: any;
  isEditFormVisible: boolean | null;
}

const initialState: LoginState = {
  form: null,
  sukiInfo: null,
  isLoading: false,
  error: null,
  isEditFormVisible: null,
};

const loginReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(submitLoginForm.fulfilled, (state, action) => {
      state.form = action.payload;
    })
    .addCase(getSukiInfo.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getSukiInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sukiInfo = action.payload;
    })
    .addCase(getSukiInfo.rejected, (state, action) => {
      logout();
      state.isLoading = false;
      state.error = action.error;
      log({ errorId: 'Login Error', isMobile: false, error: action.error, info: '/getSukiInfo Error' });
    })
    .addCase(updateSukiInfo.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(updateSukiInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sukiInfo = action.payload;
    })
    .addCase(updateSukiInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      log({ errorId: 'Login Error', isMobile: false, error: action.error, info: '/updateSukiInfo Error' });
    })
    .addCase(loginSuki.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(loginSuki.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      log({ errorId: 'Login Error', isMobile: false, error: action.error, info: '/loginSuki Error' });
    })
    .addCase(verifyMobileNumber.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(verifyMobileNumber.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(verifyMobileNumber.rejected, (state, action) => {
      state.isLoading = false;
      const { error } = action;
      state.error = {
        name: error.name || '',
        code: error.code || '',
        message: error.message || '',
      };
      log({ errorId: 'Login Error', isMobile: false, error: action.error, info: '/verifyMobileNumber Error' });
    })
    .addCase(showEditAddressForm, (state, action) => {
      state.isEditFormVisible = action.payload;
    });
});

export default loginReducer;
