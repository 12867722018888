import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { CATEGORY_FLAG } from '@const/api';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { ProductCategory } from '@sarisuki/productCategories/types/productCategory';
import flag from '@utils/flag';

interface GetProductCategoryOptions {
  storeUrl: string;
  productCategoryId: number;
}

export const getProductCategoriesByStore = createAsyncThunk<
  PaginatedResponse<ProductCategory>,
  string>(
  'productCategories/getProductCategoriesByStore',
  async (storeUrl) => {
    let url = `/stores/${storeUrl}/categories/`;

    if (flag.on(CATEGORY_FLAG)) {
      url = `/catalog/${storeUrl}/categories/`;
    }

    const response = await axios.get(url);
    return response.data;
  },
);

export const getProductCategory = createAsyncThunk<
  ProductCategory,
  GetProductCategoryOptions>(
  'productCategories/getProductCategory',
  async (payload) => {
    const { productCategoryId, storeUrl } = payload;
    let url = `/stores/${storeUrl}/categories/${productCategoryId}/`;

    if (flag.on(CATEGORY_FLAG)) {
      url = `/catalog/${storeUrl}/categories/${productCategoryId}/`;
    }

    const response = await axios.get(url);
    return response.data;
  },
);

export const setProductCategoryPickerVisibility = createAction<boolean>(
  'productCategories/setProductCategoryPickerVisibility',
);

export const setProductCategory = createAction<ProductCategory>(
  'productCategories/setProductCategory',
);

export const productCategorySelected = createAction<any>(
  'productCategories/productCategorySelected',
);

export const productCategoryUnselected = createAction(
  'productCategories/productCategoryUnselected',
);
