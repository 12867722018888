import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { ProductCategory } from '@sarisuki/productCategories/types/productCategory';
import log from '@utils/log';
import {
  getProductCategoriesByStore,
  getProductCategory,
  productCategorySelected,
  productCategoryUnselected,
  setProductCategory,
  setProductCategoryPickerVisibility,
} from '.';

export interface ProductCategoriesState {
  categories: PaginatedResponse<ProductCategory>;
  category: ProductCategory | null;
  selectedCategoryIndex: number | null;
  isLoading: boolean;
  error: any;
  isProductCategoryPickerVisible: boolean;
}

const initialState: ProductCategoriesState = {
  categories: {
    count: 0,
    limit: 0,
    next: '',
    offset: 0,
    previous: '',
    results: [],
  },
  selectedCategoryIndex: 0,
  category: null,
  isLoading: false,
  error: null,
  isProductCategoryPickerVisible: true,
};

const productCategoriesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(productCategorySelected, (state, action) => {
      state.selectedCategoryIndex = action.payload;
    })
    .addCase(getProductCategoriesByStore.fulfilled, (state, action) => {
      state.categories = action.payload;
    })
    .addCase(getProductCategory.fulfilled, (state, action) => {
      state.category = action.payload;
    })
    .addCase(productCategoryUnselected, (state) => {
      state.selectedCategoryIndex = null;
      state.category = null;
    })
    .addCase(setProductCategoryPickerVisibility, (state, action) => {
      state.isProductCategoryPickerVisible = action.payload;
    })
    .addCase(setProductCategory, (state, action) => {
      state.category = action.payload;
    })
    .addMatcher(isPending(getProductCategoriesByStore), (state) => {
      state.isLoading = true;
    })
    .addMatcher(isFulfilled(getProductCategoriesByStore), (state) => {
      state.isLoading = false;
    })
    .addMatcher(
      isRejected(getProductCategoriesByStore, getProductCategory),
      (state, action) => {
        state.error = action.error.message;
        log({ errorId: 'ProductCategory Error', isMobile: false, error: action.error, info: '/productCategory Error' });
      },
    );
});

export default productCategoriesReducer;
