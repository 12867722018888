const formatNumberToPHCurrencyInteger = (num: number) => {
  const formatter = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 0,
  });

  return formatter.format(num);
};

const formatNumberToPHCurrency = (num: number) => {
  const formatter = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
  });

  return formatter.format(num);
};

const formatToCompactNumber = (num: number) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const item = lookup.slice().reverse().find((item) => num >= item.value);
  return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
};

const formatToCartValue = (amount: number) => {
  if (Number.isNaN(amount)) {
    return formatNumberToPHCurrency(0);
  }

  if (amount >= 10000 && amount <= 999999) {
    if (((amount / 100) % 10) === 0) {
      return `${formatNumberToPHCurrency(Math.floor(amount / 1000))}k`;
    }
    return `${formatNumberToPHCurrency(Math.floor(amount / 100) / 10.0)}k`;
  }

  if (amount >= 1000000) {
    if (((amount / 100) % 10) === 0) {
      return `${formatNumberToPHCurrency(Math.floor(amount / 1000))}M`;
    }
    return `${formatNumberToPHCurrency(Math.floor(amount / 100000) / 10.0)}M`;
  }

  return formatNumberToPHCurrency(amount);
};

export default {
  formatNumberToPHCurrencyInteger,
  formatNumberToPHCurrency,
  formatToCompactNumber,
  formatToCartValue,
};
