import FilterBottomSheet from '@sarisuki/filter/components/FilterBottomSheet';
import { ReactNode, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import ProductCategoryBottomSheet from '@sarisuki/productCategories/components/ProductCategoryBottomSheet';
import StoreInfoBottomSheet from '@sarisuki/store/components/StoreInfoBottomSheet';
import OTPSuccessBottomSheet from '@sarisuki/otp/components/OTPSuccessBottomSheet';
import { bottomSheetSelector, hideBottomSheet } from '@sarisuki/bottomSheet/bottomSheetSlice';
import ProductBuyWithAGroupBottomsheet from '@components/products/templates/mobile/ProductBuyWithAGroupBottomsheet';
import BuyForYourselfBottomsheet from '../products/components/ProductBuyForYourselfBottomsheet';

/**
 * This handles the rendering of
 * multiple bottom sheets which are
 * all provided by the bottomSheetStore
 */
const BottomSheetManager = () => {
  const dispatch = useAppDispatch();
  const { isVisible, bottomSheetType } = bottomSheetSelector(useAppSelector((state) => state));
  const onDismiss = () => dispatch(hideBottomSheet());

  const componentToRender = useCallback<() => ReactNode | null>(() => {
    switch (bottomSheetType) {
      case 'product-categories-bottom-sheet':
        return <ProductCategoryBottomSheet open={isVisible} onDismiss={onDismiss} />;
      case 'filter-bottom-sheet':
        return <FilterBottomSheet open={isVisible} onDismiss={onDismiss} />;
      case 'store-info-bottom-sheet':
        return <StoreInfoBottomSheet open={isVisible} onDismiss={onDismiss} />;
      case 'otp-success-bottom-sheet':
        return <OTPSuccessBottomSheet open={isVisible} onDismiss={onDismiss} />;
      case 'buy-for-yourself-bottom-sheet':
        return <BuyForYourselfBottomsheet open={isVisible} onDismiss={onDismiss} />;
      case 'buy-with-a-group-bottom-sheet':
        return <ProductBuyWithAGroupBottomsheet open={isVisible} onDismiss={onDismiss} />;
      default:
        return null;
    }
  }, [bottomSheetType]);

  return <>{componentToRender()}</>;
};

export default BottomSheetManager;
