import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '@const/api';
import { SarisukiApiError } from '@customTypes/api';
import { OrderData } from '@sarisuki/orders/types/orderData';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { OrderSukiData } from '@sarisuki/orders/types/orderSukiData';
import { OrderAgainData } from '@sarisuki/orders/types/orderAgainData';

export const getOrderDetails = createAsyncThunk<OrderData, string>(
  'orders/getOrderDetails',
  async (orderId) => {
    try {
      const response = await axios.get(`/orders/track/${orderId}/`);
      return response.data;
    } catch (err: any) {
      if (err.response) {
        const errors = err.response.data.errors as SarisukiApiError[];
        const error = errors[0];

        if (error.code === 'invalid') {
          throw {
            code: 'invalid',
            message: 'Invalid order id.',
          } as SarisukiApiError;
        }

        throw error;
      }
      throw err;
    }
  },
);

export const showNoOrderError = createAction<boolean>(
  'orders/showNoOrderError',
);

export const getOrders = createAsyncThunk<PaginatedResponse<OrderSukiData>>(
  'orders/getOrders',
  async () => {
    const response = await axios({
      method: 'get',
      url: '/orders/suki/',
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });

    return response.data;
  },
);

export interface OrderAgainOptions {
  order_id: string,
  cart_id: string,
}
export const orderAgain = createAsyncThunk<OrderAgainData, OrderAgainOptions>(
  'orders/orderAgain',
  async (OrderAgainOptions) => {
    const response = await axios({
      method: 'post',
      url: `/orders/${OrderAgainOptions.order_id}/reorder/`,
      data: {
        cart_id: OrderAgainOptions.cart_id },
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}` },
    });

    return response.data;
  },
);

export const resetOrderAgainData = createAction(
  'orders/resetOrderAgainData',
);
