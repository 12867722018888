import { createReducer } from '@reduxjs/toolkit';
import { Barangay } from '@customTypes/barangay';
import { City } from '@customTypes/city';
import { Province } from '@customTypes/province';
import { Region } from '@customTypes/region';
import { getBarangays, getCities, getProvinces, getRegions } from '.';

interface DropdownState {
  cities: City[];
  provinces: Province[];
  regions: Region[];
  barangays: Barangay[];
  isCityLoading: boolean;
  isProvinceLoading: boolean;
  isRegionsLoading: boolean;
  isBarangaysLoading: boolean;
}

const initialState: DropdownState = {
  cities: [],
  provinces: [],
  regions: [],
  barangays: [],
  isCityLoading: false,
  isProvinceLoading: false,
  isRegionsLoading: false,
  isBarangaysLoading: false,
};

const dropdownReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRegions.pending, (state) => {
      state.isRegionsLoading = true;
    })
    .addCase(getRegions.fulfilled, (state, { payload }) => {
      state.regions = payload.results;
      state.isRegionsLoading = false;
    })
    .addCase(getRegions.rejected, (state) => {
      state.isRegionsLoading = false;
    })
    .addCase(getCities.pending, (state) => {
      state.isCityLoading = true;
    })
    .addCase(getCities.fulfilled, (state, { payload }) => {
      state.cities = payload.results;
      state.isCityLoading = false;
    })
    .addCase(getCities.rejected, (state) => {
      state.isCityLoading = false;
    })
    .addCase(getProvinces.pending, (state) => {
      state.isProvinceLoading = true;
    })
    .addCase(getProvinces.fulfilled, (state, { payload }) => {
      state.provinces = payload.results;
      state.isProvinceLoading = false;
    })
    .addCase(getProvinces.rejected, (state) => {
      state.isProvinceLoading = false;
    })
    .addCase(getBarangays.pending, (state) => {
      state.isBarangaysLoading = true;
    })
    .addCase(getBarangays.fulfilled, (state, { payload }) => {
      state.barangays = payload.results;
      state.isBarangaysLoading = false;
    })
    .addCase(getBarangays.rejected, (state) => {
      state.isBarangaysLoading = false;
    });
});

export default dropdownReducer;
