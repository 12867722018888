import { createReducer, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { Product } from '@sarisuki/products/type/product';
import log from '@utils/log';
import {
  getProduct,
  getProducts,
  getProductsByFilters,
  getReversedProductsByFilters,
  getProductsAndOptionsByKeyword,
  getProductsByCategoryId,
  getProductsByURL,
  getProductsBySubcategoryId,
  getProductsBySlug,
  setProducts,
  getProductsByBrands,
  setIsFiltering,
  getBuyAgainProductsByBrands,
  revertToUnfilteredProducts,
  getProductsByOrderHistory,
  getBestSellerProductsBySlug,
  getProductListByClassification,
  resetProductLists,
  addClassificationInProductLists,
  setSearchKeyword,
  resetSearchResultCount,
  getProductAverageRating,
  getProductReviews,
  setIsFromReviewsPage,
  getProductGroupBuyListing,
  createGroupBuyGroup,
  setActiveGroupBuyId,
  getProductGroupBuyGroupInfo,
} from '@sarisuki/products/productsSlice';
import ProductList from '@components/productDisplay/type/productList';
import { ProductRating } from '@sarisuki/products/type/productRating';
import { ProductReview } from '@sarisuki/products/type/productReview';
import { GroupBuyProduct } from '@sarisuki/products/type/groupBuyProduct';
import { GroupBuyGroup } from '@sarisuki/products/type/groupBuyGroup';

export interface ProductsState {
  products: PaginatedResponse<Product>;
  unfilteredProducts: PaginatedResponse<Product>;
  bestSellerProducts: PaginatedResponse<Product>;
  productLists: {
    listType: string;
    end: number,
    count: number,
    hasNext: boolean,
    results: ProductList[],
  };
  searchResultCount: number | null;
  product: Product | null;
  isLoading: boolean;
  isFiltering: boolean;
  error: any | null;
  keyword: string | null;
  isSearchLoading: boolean;
  productRating: ProductRating | null;
  productReviews: PaginatedResponse<ProductReview> | null;
  isFromReviewsPage: boolean;
  productGroupBuy: GroupBuyProduct | null;
  productGroupBuyGroup: GroupBuyGroup | null;
  activeGroupBuyId: string | null;
}

const initialState: ProductsState = {
  products: {
    count: 0,
    limit: 0,
    next: '',
    offset: 0,
    previous: '',
    results: [],
  },
  unfilteredProducts: {
    count: 0,
    limit: 0,
    next: '',
    offset: 0,
    previous: '',
    results: [],
  },
  bestSellerProducts: {
    count: 0,
    limit: 0,
    next: '',
    offset: 0,
    previous: '',
    results: [],
  },
  productLists: {
    listType: '',
    end: 0,
    count: 0,
    hasNext: false,
    results: [],
  },
  searchResultCount: null,
  product: null,
  isLoading: true,
  isFiltering: false,
  error: null,
  keyword: null,
  isSearchLoading: false,
  productRating: null,
  productReviews: null,
  isFromReviewsPage: false,
  productGroupBuy: null,
  productGroupBuyGroup: null,
  activeGroupBuyId: null,
};

const productsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.unfilteredProducts = action.payload;
    })
    .addCase(getProductsByFilters.pending, (state) => {
      state.isSearchLoading = true;
      state.isFiltering = true;
    })
    .addCase(getProductsByFilters.fulfilled, (state, action) => {
      state.products = action.payload;
      state.unfilteredProducts = action.payload;
      state.searchResultCount = action.payload.count;

      state.isSearchLoading = initialState.isSearchLoading;
      state.isFiltering = initialState.isFiltering;
    })
    .addCase(getProductsByFilters.rejected, (state) => {
      state.isSearchLoading = initialState.isSearchLoading;
      state.isFiltering = initialState.isFiltering;
    })
    .addCase(getReversedProductsByFilters.pending, (state) => {
      state.isSearchLoading = true;
      state.isFiltering = true;
    })
    .addCase(getReversedProductsByFilters.fulfilled, (state, action) => {
      const updatedProducts = {
        ...action.payload,
        results: [...action.payload.results.reverse()],
      };

      state.products = { ...updatedProducts };
      state.unfilteredProducts = { ...updatedProducts };
      state.searchResultCount = action.payload.count;

      state.isSearchLoading = initialState.isSearchLoading;
      state.isFiltering = initialState.isFiltering;
    })
    .addCase(getReversedProductsByFilters.rejected, (state) => {
      state.isSearchLoading = initialState.isSearchLoading;
      state.isFiltering = initialState.isFiltering;
    })
    .addCase(getProductsAndOptionsByKeyword.pending, (state) => {
      state.isSearchLoading = true;
    })
    .addCase(getProductsAndOptionsByKeyword.fulfilled, (state, action) => {
      state.products = action.payload;
      state.searchResultCount = action.payload.count;
      state.unfilteredProducts = action.payload;
      state.isSearchLoading = initialState.isSearchLoading;
    })
    .addCase(getProductsAndOptionsByKeyword.rejected, (state) => {
      state.isSearchLoading = initialState.isSearchLoading;
    })
    .addCase(getProductsByCategoryId.fulfilled, (state, action) => {
      state.products = action.payload;
      state.unfilteredProducts = action.payload;
    })
    .addCase(getProduct.fulfilled, (state, action) => {
      state.product = action.payload;
    })
    .addCase(getProductsByURL.fulfilled, (state, action) => {
      const products = state.products.results;
      const unfilteredProducts = state.unfilteredProducts.results;

      products.push(...action.payload.results);
      unfilteredProducts.push(...action.payload.results);

      state.products = {
        ...action.payload,
        results: products,
      };

      state.unfilteredProducts = {
        ...action.payload,
        results: unfilteredProducts,
      };
    })
    .addCase(getProductsBySubcategoryId.fulfilled, (state, action) => {
      state.products = action.payload;
      state.unfilteredProducts = action.payload;
    })
    .addCase(getProductsBySlug.fulfilled, (state, action) => {
      state.products = action.payload;
      state.unfilteredProducts = action.payload;
    })
    .addCase(setProducts, (state, action) => {
      state.products = action.payload;
    })
    .addCase(getProductsByBrands.fulfilled, (state, action) => {
      state.products = action.payload;
    })
    .addCase(getBuyAgainProductsByBrands.fulfilled, (state, action) => {
      state.products = action.payload;
    })
    .addCase(revertToUnfilteredProducts, (state) => {
      state.products = state.unfilteredProducts;
    })
    .addCase(getProductsByOrderHistory.fulfilled, (state, action) => {
      state.products = action.payload;
      state.unfilteredProducts = action.payload;
    })
    .addCase(getBestSellerProductsBySlug.fulfilled, (state, action) => {
      const MAX_BESTSELLER_PRODUCTS_RESULT_COUNT = 10;
      const bestSellers = action.payload.results.slice(0, MAX_BESTSELLER_PRODUCTS_RESULT_COUNT);

      state.bestSellerProducts = {
        ...action.payload,
        results: [...bestSellers],
      };
    })
    .addCase(resetSearchResultCount, (state) => {
      state.searchResultCount = 0;
    })
    .addCase(setSearchKeyword, (state, action) => {
      state.keyword = action.payload;
    })
    .addCase(setIsFiltering, (state, action) => {
      state.isFiltering = action.payload;
    })
    .addCase(resetProductLists, (state) => {
      state.productLists = initialState.productLists;
    })
    .addCase(addClassificationInProductLists, (state, action) => {
      const { listType, index, classification, hasNext } = action.payload;
      const currentCount = state.productLists.count;

      const isInProductLists = state.productLists.results.reduce(
        (isInCollection: boolean, currProductList: ProductList) =>
        isInCollection || (currProductList.classification.id === classification.id), false);

      if (!isInProductLists) {
        state.productLists = {
          ...state.productLists,
          listType,
          end: index,
          count: currentCount + 1,
          hasNext,
          results: [
            ...state.productLists.results,
            {
              classification,
              isLoading: true,
              count: 0,
              limit: 0,
              next: '',
              offset: 0,
              previous: '',
              results: [],
            },
          ],
        };

        state.isLoading = true;
      }
    })
    .addCase(getProductListByClassification.fulfilled, (state, action) => {
      const { classification, data } = action.payload;

      const newProductLists = state.productLists.results.map(
        (productsList: ProductList) => {
        if (productsList.classification.id === classification.id) {
          return {
            classification,
            isLoading: false,
            ...data,
          };
        }
        return productsList;
      });

      const getLoadingState = newProductLists.reduce(
        (overallIsLoading: boolean, currProductList: ProductList) =>
        (overallIsLoading || currProductList.isLoading), false);

      state.isLoading = getLoadingState;

      state.productLists = {
        ...state.productLists,
        results: [...newProductLists],
      };
    })
    .addCase(getProductAverageRating.fulfilled, (state, action) => {
      state.productRating = action.payload;
    })
    .addCase(getProductReviews.fulfilled, (state, action) => {
      state.productReviews = action.payload;
    })
    .addCase(setIsFromReviewsPage, (state, action) => {
      state.isFromReviewsPage = action.payload;
    })
    .addCase(getProductGroupBuyListing.fulfilled, (state, action) => {
      state.productGroupBuy = action.payload;
    })
    .addCase(getProductGroupBuyListing.rejected, (state) => {
      state.productGroupBuy = initialState.productGroupBuy;
    })
    .addCase(createGroupBuyGroup.fulfilled, (state, action) => {
      state.productGroupBuyGroup = action.payload;
    })
    .addCase(setActiveGroupBuyId, (state, action) => {
      state.activeGroupBuyId = action.payload;
    })
    .addCase(getProductGroupBuyGroupInfo.fulfilled, (state, action) => {
      state.productGroupBuyGroup = action.payload;
    })
    .addMatcher(
      isFulfilled(
        getProduct,
        getProductsByFilters,
        getReversedProductsByFilters,
        getProductsAndOptionsByKeyword,
        getProductsByCategoryId,
        getProducts,
        getProductsByBrands,
        getBuyAgainProductsByBrands,
        getProductsBySubcategoryId,
        getProductsBySlug,
        getProductsByOrderHistory,
        getBestSellerProductsBySlug,
        getProductAverageRating,
        getProductReviews,
        getProductGroupBuyListing,
        createGroupBuyGroup,
        getProductGroupBuyGroupInfo,
      ),
      (state) => {
        state.isLoading = false;
        state.error = null;
      },
    )
    .addMatcher(
      isPending(
        getProduct,
        getProductsByFilters,
        getReversedProductsByFilters,
        getProductsAndOptionsByKeyword,
        getProductsByCategoryId,
        getProducts,
        getProductsByBrands,
        getBuyAgainProductsByBrands,
        getProductsBySubcategoryId,
        getProductsBySlug,
        getProductsByOrderHistory,
        getBestSellerProductsBySlug,
        getProductListByClassification,
        getProductAverageRating,
        getProductReviews,
        getProductGroupBuyListing,
        createGroupBuyGroup,
        getProductGroupBuyGroupInfo,
      ),
      (state) => {
        state.isLoading = true;
      },
    )
    .addMatcher(
      isRejected(
        getProduct,
        getProductsByFilters,
        getReversedProductsByFilters,
        getProductsAndOptionsByKeyword,
        getProductsByCategoryId,
        getProducts,
        getProductsByBrands,
        getBuyAgainProductsByBrands,
        getProductsBySubcategoryId,
        getProductsBySlug,
        getProductsByOrderHistory,
        getBestSellerProductsBySlug,
        getProductListByClassification,
        getProductAverageRating,
        getProductReviews,
        getProductGroupBuyListing,
        createGroupBuyGroup,
        getProductGroupBuyGroupInfo,
      ),
      (state, { error }) => {
        state.isLoading = false;
        state.error = {
          message: error.message || 'error while retrieving products',
          code: error.code || '',
        };
        log({ errorId: 'Products Error', isMobile: false, error, info: '/products Error' });
      },
    );
});

export default productsReducer;
