import { Modal, ModalOverlay, Text, ModalContent, ModalHeader, ModalCloseButton, InputGroup, Input, InputRightElement, IconButton } from '@chakra-ui/react';
import { capitalizeFirstLetter } from '@customTypes/capitalizeFirstLetter';
import { BaseModalProps } from '@sarisuki/modal/types';
import React, { useEffect, useState } from 'react';
import { FiCopy } from 'react-icons/fi';
import mobileNumberFormatter from '@utils/mobileNumberFormatter';
import copyToClipboard from '@utils/copyToClipboard';

interface IProps extends BaseModalProps {}
const OrderHistoryCLModal: React.FC<IProps> = ({ open, onDismiss, ...modalProps }: IProps) => {
  const [copyMobileText, setCopyMobileText] = useState('');
  const [showIcon, setShowIcon] = useState(true);
  const [CLName, setCLName] = useState('');
  const [internationalFormattedNumber, setInternationalFormattedNumber] = useState('');
  const [storeName, setStoreName] = useState('');

  useEffect(() => {
    if (modalProps) {
      Object.values(modalProps).forEach((info: any) => {
        setStoreName(info.name ?? '');
        if (info.user.first_name && info.user.last_name) {
          const formattedFirstname = capitalizeFirstLetter(info.user.first_name);
          const formattedLastname = capitalizeFirstLetter(info.user.last_name);
          setCLName(`${formattedFirstname} ${formattedLastname}`);
        }

        if (info.user.cl_info.mobile_number) {
          setInternationalFormattedNumber(mobileNumberFormatter.internationalMobileNumber(
            info.user.cl_info.mobile_number,
          ));
          setCopyMobileText(mobileNumberFormatter.internationalMobileNumber(
            info.user.cl_info.mobile_number,
          ));
        }
      });
    }
  }, []);

  const onCopyButtonClick = () => {
    if (internationalFormattedNumber) {
      copyToClipboard(internationalFormattedNumber);
      setShowIcon(false);
      setCopyMobileText('Copied!');
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!showIcon) {
        setShowIcon(true);
        setCopyMobileText(internationalFormattedNumber);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [showIcon]);

  return (
    <Modal isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent w="360px" h="194px" borderRadius="16px">
        <ModalHeader fontSize="16px" fontWeight={600} color="gray.900">{storeName}</ModalHeader>
        <ModalCloseButton _focus={{ border: 'none' }} _hover={{ border: 'none' }} color="gray.60" />
        <Text align="center" fontSize="16px" fontWeight={400} color="gray.900">Contact CL {CLName}</Text>
        <InputGroup
          w="90%"
          h="50px"
          bg="gray.100"
          py={1}
          borderRadius="8px"
          mx={4}
          pl={copyMobileText === 'Copied!' ? '120px' : '70px'}
          mt="8px"
        >
          <Input
            value={copyMobileText}
            isReadOnly
            border="none"
            color="gray.900"
            fontWeight={600}
            fontSize="16px"
            px="12px"
          />
          <InputRightElement width="10.0rem">
            <IconButton
              aria-label="copy button"
              icon={<FiCopy />}
              boxSize={6}
              mt="10px"
              color="orange.300"
              _focus={{ bg: 'white' }}
              _hover={{ bg: 'white' }}
              bg="white"
              onClick={onCopyButtonClick}
            />
          </InputRightElement>
        </InputGroup>
      </ModalContent>
    </Modal>
  );
};
export default OrderHistoryCLModal;
