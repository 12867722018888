const sarisukiColors = {
  'paper-gray': '#F7F8FA',
  'black-pearl': '#121619',
  'pale-blue': '#E8F0FE',
  'dark-orange': '#F15E0E',
  'vivid-red': '#F15524',
  'yellow-green': '#98C93C',
  'sea-nettle': '#F27932',
  'broccoli': '#004B35',
  'cabbage': '#DBF051',
  'pongkan': '#ED6000',
  'tomato': '#FF3900',
  gray: {
    50: '#FAFAFA',
    60: '#697077',
    90: '#E5E5E5',
    100: '#F4F4F5',
    200: '#E4E4E7',
    300: '#D4D4D8',
    400: '#A1A1AA',
    500: '#71717A',
    600: '#52525B',
    700: '#3F3F46',
    800: '#27272A',
    900: '#18181B',
  },
  'cool-blue': {
    50: '#E6EDFB',
    100: '#CDDBF7',
    200: '#B4C9F3',
    300: '#83A4EC',
    400: '#6A92E8',
    500: '#386EE0',
    600: '#215BD4',
    700: '#1C4EB6',
    800: '#184197',
    900: '#133479',
  },
  'light-blue': {
    50: '#DFF0FF',
    100: '#BFE0FF',
    200: '#80C2FF',
    300: '#60B3FF',
    400: '#40A3FF',
    500: '#0085FF',
    600: '#215BD4',
    700: '#1C4EB6',
    800: '#184197',
    900: '#133479',
  },
  'cyan-blue': {
    50: '#343A3F',
    900: '#DDE1E6',
  },
  cyan: {
    50: '#EAF9FB',
    100: '#D4F3F7',
    200: '#BFEDF4',
    300: '#7FDCE8',
    400: '#54D0E1',
    500: '#3FCADD',
    600: '#25BED3',
    700: '#20A3B5',
    800: '#156D79',
    900: '#10525A',
  },
  yellow: {
    50: '#FFFBEB',
    100: '#FEF3C8',
    200: '#FDE68B',
    300: '#FCD44F',
    400: '#FBBD23',
    500: '#F59F0A',
    600: '#DB7706',
    700: '#B35309',
    800: '#91400D',
    900: '#76350F',
  },
  red: {
    50: '#FFF1F1',
    100: '#FEE1E1',
    200: '#FEC8C8',
    300: '#FCA6A6',
    400: '#F87272',
    500: '#EF4343',
    600: '#DC2828',
    700: '#BA1C1C',
    800: '#981B1B',
    900: '#811D1D',
  },
  'sarisuki-green': {
    10: '#DEFBE6',
    50: '#F3FFF6',
    100: '#DEFBE6',
    200: '#A7F0BA',
    300: '#6FDC8C',
    400: '#4BCC6F',
    500: '#007E33', // Malunggay
    600: '#198038',
    700: '#0E6027',
    800: '#044317',
    900: '#022D0D',
  },
  orange: {
    50: '#FFEEDF',
    100: '#FFCB9F',
    200: '#FFBA80',
    300: '#FFA960',
    400: '#FF8620',
    500: '#FF7500',
    600: '#F27002',
    700: '#CF5F00',
    800: '#BA5500',
    900: '#9D4800',
  },
  white: '#FFFFFF',
  black: '#000000',
};

export default sarisukiColors;
