import { createSelector } from 'reselect';
import _, { memoize } from 'lodash';
import { RootState } from '@app/store';

export const selectCart = (state: RootState) => state.cart;
export const cartSelector = createSelector(selectCart, (state) => state);
export const cartItemSelector = createSelector(selectCart, (state) => memoize((sku: string) => {
  if (!state.products) return null;
  return state.products.find(({ product }) => sku === product.sku);
}));

export const cartTotalAmountSelector = createSelector(selectCart, (state) => {
  if (!state.products) return 0;
  return _.sum(state.products.map((p) => Number(p.subtotal)));
});

export const cartCountSelector = createSelector(selectCart, (state) =>
  _.sum(state.products?.map((p) => Number(p.quantity))),
);
