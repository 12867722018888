import { configureStore } from '@reduxjs/toolkit';
import bottomSheetReducer from '@sarisuki/bottomSheet/bottomSheetSlice/reducers';
import cartReducer from '@sarisuki/cart/cartSlice/reducers';
import checkoutReducer from '@sarisuki/checkout/checkoutSlice/reducers';
import dropdownReducer from '@sarisuki/dropdown/dropdownSlice/reducers';
import locationReducer from '@sarisuki/location/locationSlice/reducers';
import modalReducer from '@sarisuki/modal/modalSlice/reducers';
import ordersReducer from '@sarisuki/orders/ordersSlice/reducers';
import productCategoriesReducer from '@sarisuki/productCategories/productCategoriesSlice/reducers';
import productSubcategoriesReducer from '@sarisuki/productSubcategories/productSubcategoriesSlice/reducers';
import productsReducer from '@sarisuki/products/productsSlice/reducers';
import storeReducer from '@sarisuki/store/storeSlice/reducers';
import promoReducer from '@sarisuki/promo/promoSlice/reducers';
import homeReducer from '@sarisuki/home/homeSlice/reducers';
import signupReducer from '@sarisuki/signup/signupSlice/reducers';
import referralReducer from '@sarisuki/referral/referralSlice/reducers';
import loginReducer from '@sarisuki/login/loginSlice/reducers';
import filterReducer from '@sarisuki/filter/filterSlice/reducers';
import filterSortReducer from '@components/filterSort/filterSlice/reducers';
import orderHistoryReducer from '@sarisuki/orderHistory/orderHistorySlice/reducers';

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    home: homeReducer,
    store: storeReducer,
    location: locationReducer,
    products: productsReducer,
    unfilteredProducts: productsReducer,
    productCategories: productCategoriesReducer,
    productSubcategories: productSubcategoriesReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    promo: promoReducer,
    bottomSheet: bottomSheetReducer,
    orders: ordersReducer,
    dropdown: dropdownReducer,
    signup: signupReducer,
    login: loginReducer,
    referral: referralReducer,
    filter: filterReducer,
    filterSort: filterSortReducer,
    orderHistory: orderHistoryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
