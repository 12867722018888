export const getMonth = (dateString: string, format?: string) => {
      let month = '';
      const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
      ];
      const shortMonths = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
      ];

      if (format === 'short') {
            const date = new Date(dateString);
            month = shortMonths[date.getMonth()];
      } else {
            const date = new Date(dateString);
            month = months[date.getMonth()];
      }

      return month;
  };
