const storeScheduleFormatter = (daysArray: any[]) => {
    let formattedStoreSchedule = '';

    if (daysArray.length === 0) {
        formattedStoreSchedule = '';
    } else if (daysArray.length === 1) {
        formattedStoreSchedule = `${daysArray[0]}`;
    } else {
        formattedStoreSchedule = `${daysArray.slice(0, -1).join(', ')} and ${daysArray[daysArray.length - 1]}`;
    }

    return formattedStoreSchedule;
};
export default storeScheduleFormatter;
