import { createReducer } from '@reduxjs/toolkit';
import { SarisukiApiError } from '@customTypes/api';
import log from '@utils/log';
import { getPreviousStoreInfo, setOrderID, resetPreviousOrderInfo, getPreviousCart, isFromOrderAgain, setOrderAgainItemQty } from './actions';
import { OrderHistoryItems } from '../types';

export interface OrderHistoryState {
  isLoading: boolean;
  error: SarisukiApiError | null;
  previousOrderId: string | null
  previousStoreId: number | null;
  previousStoreName: string;
  previousStoreUrl: string;
  previousCartId: string;
  isUserFromOrderAgain: boolean;
  orderAgainItemQty: OrderHistoryItems[] | null;
}

const initialState: OrderHistoryState = {
  error: null,
  isLoading: false,
  previousOrderId: null,
  previousStoreId: null,
  previousStoreName: '',
  previousStoreUrl: '',
  previousCartId: '',
  isUserFromOrderAgain: false,
  orderAgainItemQty: null,
};

const orderHistoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setOrderID, (state, action) => {
      state.previousOrderId = action.payload;
    })
    .addCase(setOrderAgainItemQty, (state, action) => {
      state.orderAgainItemQty = action.payload;
    })
    .addCase(isFromOrderAgain, (state, action) => {
      state.isUserFromOrderAgain = action.payload;
    })
    .addCase(getPreviousStoreInfo.fulfilled, (state, action) => {
      const { id, name, url } = action.payload;
      state.previousStoreId = id;
      state.previousStoreName = name || '';
      state.previousStoreUrl = url || '';
      state.error = null;
      state.isLoading = false;
    })
    .addCase(getPreviousStoreInfo.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = {
        name: error.name || '',
        code: error.code || '',
        message: error.message || '',
      };
      log({ errorId: 'Order Again Error', isMobile: false, error: action.error, info: '/getPreviousStoreInfo Error' });
    })
    .addCase(resetPreviousOrderInfo, (state) => {
      state.previousStoreId = null;
      state.previousStoreName = '';
      state.previousStoreUrl = '';
      state.previousCartId = '';
    })
    .addCase(getPreviousCart.fulfilled, (state, action) => {
      const { id } = action.payload;
      state.previousCartId = id;
      state.isLoading = false;
    })
    .addCase(getPreviousCart.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = {
        name: error.name || '',
        code: error.code || '',
        message: error.message || '',
      };
      log({ errorId: 'Order Again Error', isMobile: false, error: action.error, info: '/getPreviousCart Error' });
    });
});
export default orderHistoryReducer;
