export enum LocalStorageKeys {
  CART_ID = 'cart_id',
  USER_TOKEN = 'user_token',
  DISPLAY_REFERRAL_MODAL = 'display_referral_modal',
  STORE_ID = 'store_id',
  STORE_URL = 'store_url',
  STORE_NAME = 'store_name',
  PRODUCT_CATEGORY = 'product_category',
  PRODUCT_SUBCATEGORY = 'product_subcategory',
  RAFFLE_QUANTITY = 'raffle_quantity',
  PAYMENT_TOKEN = 'payment_token',
  GROUP_BUY_ID = 'group_buy_id',
  HIDE_NETCORE_NUDGE = 'hide_netcore_nudge',
}
