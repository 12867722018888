import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { CATEGORY_FLAG } from '@const/api';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { getStoreInfo } from '@sarisuki/store/storeSlice';
import { Product } from '@sarisuki/products/type/product';
import { setFilterByPriceOptions, updateFilterByPriceOptions } from '@sarisuki/filter/filterSlice';
import { setBrandOptions, setCategoryOptions } from '@components/filterSort/filterSlice';
import flag from '@utils/flag';

import { ProductCategory } from '@sarisuki/productCategories/types/productCategory';
import { ProductSubcategory } from '@sarisuki/productSubcategories/types/productSubcategory';
import { ProductRating } from '@sarisuki/products/type/productRating';
import { ProductReview } from '@sarisuki/products/type/productReview';
import { GroupBuyProduct } from '@sarisuki/products/type/groupBuyProduct';
import { GroupBuyGroup } from '@sarisuki/products/type/groupBuyGroup';

interface GetProductsByFilters {
  sort?: string;
  storeUrl: string;
  keyword: string;
  categoryOptions?: string[];
  brandOptions?: string[];
  min_price?: number;
  max_price?: number;
  show_available?: boolean;
}

interface GetProductsByBrands {
  storeUrl: string;
  categoryId: number;
  subcategoryId: number | null;
  brands: string[];
}

interface GetBuyAgainProductsByBrands {
  storeUrl: string;
  brands: string[];
}

export const getProduct = createAsyncThunk< Product, { storeUrl: string; productId: number; }>(
  'products/getProduct', async (value, { dispatch }) => {
  const { productId, storeUrl } = value;
  await dispatch(getStoreInfo(storeUrl)).unwrap();
  const response = await axios(`/stores/${storeUrl}/products/${productId}`);
  return response.data;
});

export const setSearchKeyword = createAction<string | null>(
  'products/setSearchKeyword',
);

export const resetSearchResultCount = createAction(
  'products/resetSearchResultCount',
);

export const getProductsAndOptionsByKeyword = createAsyncThunk<
  PaginatedResponse<Product>,
  GetProductsByFilters>(
  'products/getProductsAndOptionsByKeyword',
  async (payload, { dispatch }) => {
    const {
      keyword,
      storeUrl,
      min_price,
      max_price,
      brandOptions,
      show_available,
      categoryOptions,
    } = payload;

    let url = `/stores/${storeUrl}/products/`;
    let params = {
      search: keyword,
    };

    if (flag.on(CATEGORY_FLAG)) {
      url = `/catalog/${storeUrl}/search/`;
      params = {
        ...params,
        ...categoryOptions && { categories: categoryOptions.toString() },
        ...brandOptions && { brands: brandOptions.toString() },
        ...min_price && { min_price },
        ...max_price && { max_price },
        ...show_available && { show_available },
      };
    }

    const response = await axios.get(url, { params });

    const { brands, categories, results } = response.data;

    if (brands) {
      await dispatch(setBrandOptions(brands));
    }

    if (categories) {
      await dispatch(setCategoryOptions(categories));
    }

    await dispatch(setSearchKeyword(keyword));
    await dispatch(setFilterByPriceOptions(results));
    return response.data;
  },
);

export const setSearchResults = createAction<PaginatedResponse<Product>>(
  'products/setSearchResults',
);

export const getProductsByFilters = createAsyncThunk<
  PaginatedResponse<Product>,
  GetProductsByFilters>(
  'products/getProductsByFilters',
  async (payload, { dispatch }) => {
    const {
      sort,
      keyword,
      storeUrl,
      min_price,
      max_price,
      brandOptions,
      show_available,
      categoryOptions,
    } = payload;

    let url = `/stores/${storeUrl}/products/`;
    let params = {
      search: keyword,
    };

    if (flag.on(CATEGORY_FLAG)) {
      url = `/catalog/${storeUrl}/search/`;
      params = {
        ...params,
        ...categoryOptions && { categories: categoryOptions.toString() },
        ...brandOptions && { brands: brandOptions.toString() },
        ...min_price && { min_price },
        ...max_price && { max_price },
        ...show_available && { show_available },
        ...sort && { sort },
      };
    }

    const response = await axios.get(url, { params });

    const { results } = response.data;
    await dispatch(setSearchKeyword(keyword));
    await dispatch(setFilterByPriceOptions(results));
    return response.data;
  },
);

export const getReversedProductsByFilters = createAsyncThunk<
  PaginatedResponse<Product>,
  GetProductsByFilters>(
  'products/getReversedProductsByFilters',
  async (payload, { dispatch }) => {
    const {
      sort,
      keyword,
      storeUrl,
      min_price,
      max_price,
      brandOptions,
      show_available,
      categoryOptions,
    } = payload;

    let url = `/stores/${storeUrl}/products/`;
    let params = {
      search: keyword,
    };

    if (flag.on(CATEGORY_FLAG)) {
      url = `/catalog/${storeUrl}/search/`;
      params = {
        ...params,
        ...categoryOptions && { categories: categoryOptions.toString() },
        ...brandOptions && { brands: brandOptions.toString() },
        ...min_price && { min_price },
        ...max_price && { max_price },
        ...show_available && { show_available },
        ...sort && { sort },
      };
    }

    const response = await axios.get(url, { params });

    const { results } = response.data;
    await dispatch(setSearchKeyword(keyword));
    await dispatch(setFilterByPriceOptions(results));
    return response.data;
  },
);

export const getProductsByCategoryId = createAsyncThunk<PaginatedResponse<Product>, any>(
  'products/getProductsByCategoryId',
  async (payload, { dispatch }) => {
  const { categoryId, storeUrl } = payload;
  const response = await axios.get(`/stores/${storeUrl}/products/`, {
    params: { categories: categoryId },
  });

  const { results } = response.data;
  dispatch(setFilterByPriceOptions(results));
  return response.data;
});

export const getProductsByURL = createAsyncThunk<PaginatedResponse<Product>, any>(
  'products/getProductsByURL',
  async (payload, { dispatch }) => {
  const response = await axios.get(payload);

  const { results } = response.data;
  dispatch(updateFilterByPriceOptions(results));
  return response.data;
});

export const getProducts = createAsyncThunk<PaginatedResponse<Product>, string>(
  'products/getProducts',
  async (storeUrl) => {
    const response = await axios.get(`/stores/${storeUrl}/products/`);
    return response.data;
  },
);

export const getProductsBySubcategoryId = createAsyncThunk<PaginatedResponse<Product>, any>(
  'products/getProductsBySubcategoryId',
  async (payload, { dispatch }) => {
    const { subcategoryId, categoryId, storeUrl } = payload;
    const response = await axios.get(`/stores/${storeUrl}/products/`, {
      params: {
        categories: categoryId,
        subcategories: subcategoryId,
      },
    });

    const { results } = response.data;
    dispatch(setFilterByPriceOptions(results));
    return response.data;
  },
);

export const getProductsBySlug = createAsyncThunk<PaginatedResponse<Product>, any>(
  'products/getProductsBySlug',
  async (payload, { dispatch }) => {
    const { storeUrl, slug, brands } = payload;
    const filterParameters = {
      ...slug && { categories: slug },
      ...brands && { brand: brands.toString() },
    };
    const response = await axios.get(`/catalog/${storeUrl}/products/`, { params: filterParameters });

    const { results } = response.data;
    await dispatch(setFilterByPriceOptions(results));
    return response.data;
  },
);

export const setProducts = createAction<PaginatedResponse<Product>>(
  'products/setProducts',
);

export const getProductsByBrands = createAsyncThunk<
  PaginatedResponse<Product>,
  GetProductsByBrands>(
  'products/getProductsByBrands',
  async (payload) => {
    const { storeUrl, categoryId, subcategoryId, brands } = payload;
    const response = await axios.get(`/stores/${storeUrl}/products/`, {
      params: {
        categories: categoryId,
        subcategories: subcategoryId,
        brands: brands.toString(),
      },
    });

    return response.data;
  },
);

export const getBuyAgainProductsByBrands = createAsyncThunk<
  PaginatedResponse<Product>,
  GetBuyAgainProductsByBrands>(
  'products/getBuyAgainProductsByBrands',
  async (payload) => {
    const { storeUrl, brands } = payload;
    const response = await axios.get(`/stores/${storeUrl}/products/order-again/`, {
      headers: { Authorization: `Token ${localStorage.getItem('user_token')}` },
      params: { brand: brands.toString() },
    });

    return response.data;
  },
);

export const revertToUnfilteredProducts = createAction(
  'products/revertToUnfilteredProducts',
);

export const getProductsByOrderHistory = createAsyncThunk<PaginatedResponse<Product>, any>(
  'products/getProductsByOrderHistory',
  async (storeUrl, { dispatch }) => {
    const response = await axios.get(`/stores/${storeUrl}/products/order-again/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });

    const { results } = response.data;
    dispatch(setFilterByPriceOptions(results));
    return response.data;
  },
);

export const getBestSellerProductsBySlug = createAsyncThunk<PaginatedResponse<Product>, any>(
  'products/getBestSellerProductsBySlug',
  async (payload) => {
    const { storeUrl, slug } = payload;
    const response = await axios.get(`/catalog/${storeUrl}/products/?categories=bestsellers-${slug}`);
    return response.data;
  },
);

export const getBestSellerProductsLastThreeDays = createAsyncThunk<any, any>(
  'products/getBestSellerProductsLastThreeDays',
  async (storeUrl, { dispatch }) => {
    dispatch(getBestSellerProductsBySlug({ storeUrl, slug: 'last-3-days' }));
  },
);

export const setIsFiltering = createAction<boolean>(
  'products/setIsFiltering',
);

/* The following actions are exclusive only for the redesigned webstore */

export const resetProductLists = createAction(
  'products/resetProductLists',
);

interface AddClassificationInProductLists {
  listType: string;
  index: number;
  hasNext: boolean;
  classification: ProductCategory | ProductSubcategory;
}

export const addClassificationInProductLists = createAction<AddClassificationInProductLists>(
  'products/addClassificationInProductLists',
);

interface GetClassification {
  storeUrl: string;
  classification: ProductCategory | ProductSubcategory;
}

export const getProductListByClassification = createAsyncThunk<any, GetClassification>(
  'products/getProductListByClassification',
  async (payload) => {
    const { storeUrl, classification } = payload;
    const response = await axios.get(`/catalog/${storeUrl}/products/`, { params: { categories: classification.slug } });
    return { classification, data: response.data };
  },
);

export const getProductAverageRating = createAsyncThunk<ProductRating, number>(
  'products/getProductAverageRating',
  async (product_id) => {
    const response = await axios.get(`/rating/products/${product_id}/average/`);
    return response.data;
  },
);

export const getProductReviews = createAsyncThunk<PaginatedResponse<ProductReview>, number>(
  'products/getProductReviews',
  async (product_id) => {
    const response = await axios.get(`/rating/products/${product_id}/reviews/`);
    return response.data;
  },
);

export const setIsFromReviewsPage = createAction<boolean>(
  'products/setIsFromReviewsPage',
);

export const getProductGroupBuyListing = createAsyncThunk<GroupBuyProduct, number>(
  'products/getProductGroupBuyListing',
  async (product_id) => {
    const response = await axios.get(`/groupbuy/product/${product_id}/`);
    return response.data;
  },
);

export const setActiveGroupBuyId = createAction<string>(
  'products/setActiveGroupBuyId',
);

export const createGroupBuyGroup = createAsyncThunk<GroupBuyGroup, number>(
  'products/createGroupBuyGroup',
  async (group_buy_product_id, { dispatch }) => {
    let response;

    try {
      response = await axios({
        method: 'post',
        url: '/groupbuy/group/create/',
        data: { group_buy_product_id },
        headers: {
          Authorization: `Token ${localStorage.getItem('user_token')}`,
        },
      });
    } catch (create_error: any) {
      if (create_error.response) {
        const { errors } = create_error.response.data;
        const error = errors[0];

        if (error.code === 'invalid_group_membership') {
          dispatch(setActiveGroupBuyId(error.extra.group_ID));
        }

        throw error;
      }
      throw create_error;
    }

    return response.data;
  },
);

export const getProductGroupBuyGroupInfo = createAsyncThunk<GroupBuyGroup, string>(
  'products/getProductGroupBuyGroupInfo',
  async (group_id) => {
    const response = await axios.get(`/groupbuy/group/${group_id}/`);
    return response.data;
  },
);
