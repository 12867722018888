import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { VStack, Text, HStack, Spacer, Container } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';
import { MdContentCopy } from 'react-icons/md';
import { Divider, Icon, IconButton } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BiLinkAlt } from 'react-icons/bi';
import MessengerIcon from '@assets/icons/messenger-icon.svg';
import FacebookIcon from '@assets/icons/facebook-icon.svg';
import ViberIcon from '@assets/icons/viber-icon.svg';
import { isMobile } from 'react-device-detect';
import { APP_ID, URL } from '@const/api';
import { FacebookShareButton, FacebookMessengerShareButton, ViberShareButton } from 'react-share';
import { calculateDiscountPercentage } from '@customTypes/calculateDiscountPercentage';
import router from 'next/router';
import { clipboard } from '@customTypes/clipboard';

interface IProps extends BaseModalProps {}
const ProductShareGroupBuyModal: React.FC<IProps> = (
    { open, onDismiss, ...modalProps }: IProps,
) => {
  const [productName, setProductName] = useState('');
  const [productGroupBuyPrice, setProductGroupBuyPrice] = useState('');
  const [productPrice, setProductPrice] = useState(0);
  const [groupBuyMaxMembers, setGroupBuyMaxMembers] = useState(0);
  const [groupBuyDiscount, setGroupBuyDiscount] = useState('');
  const [groupBuyId, setGroupBuyId] = useState('');
  const [url, setUrl] = useState('');
  const DECIMAL_NUM = 10;
  const { storeUrl } = router.query;
  const [copyButtonText, setCopyButtonText] = useState('Copy');

  useEffect(() => {
    if (modalProps) {
      Object.values(modalProps).forEach((groupbuyproduct: any) => {
        if (groupbuyproduct) {
            setGroupBuyId(groupbuyproduct.id);
            if (storeUrl !== '') {
              setUrl(`${URL}${storeUrl}/groupbuy/${groupBuyId}`);
            }

          if (groupbuyproduct.group_buy_product) {
            setProductGroupBuyPrice(`${Math.round(groupbuyproduct.group_buy_product.price * DECIMAL_NUM) / DECIMAL_NUM}`);
            setGroupBuyMaxMembers(groupbuyproduct.group_buy_product.max_members);

            if (groupbuyproduct.group_buy_product.product) {
              setProductName(groupbuyproduct.group_buy_product.product.name);
              setGroupBuyDiscount(
                calculateDiscountPercentage(
                  groupbuyproduct.group_buy_product.product.default_srp_price,
                  groupbuyproduct.group_buy_product.price,
                ),
              );
              setProductPrice(Math.round(
                groupbuyproduct.group_buy_product.product.default_srp_price * 10) / 10,
              );
            }
          }
        }
      });
    }
  }, [modalProps]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copyButtonText === 'Copied!') setCopyButtonText('Copy');
    }, 3000);

    return () => clearTimeout(timer);
  }, [copyButtonText]);

  const onCopyButtonClick = () => {
    const STRING_TO_COPY = `Friend, sali ka sa group buy ko! I'm buying ${productName} from SariSuki at only ₱${productGroupBuyPrice} kapag ${groupBuyMaxMembers} tayo na nag-order together!! ${groupBuyDiscount} discount kaysa regular price na ₱${productPrice}!`;
    setCopyButtonText('Copied!');
    clipboard(STRING_TO_COPY);
  };

  return (
    <Modal isCentered isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent closeOnOverlayClick={false} rounded="xl" overflow="hidden" maxW="576px">
        <Container pt={5} pb={5} w="100%">
          <VStack position="relative" w="full" px={[0, 5]}>
            <HStack minW="full">
              <Text color="black-pearl" fontWeight={700} fontSize="24px" py={3} w="100%" textAlign="center">Invite Friends</Text>
              <Spacer />
              <ModalCloseButton pt={3} color="gray.500" _focus={{ border: 'none', bg: 'white' }} _active={{ border: 'none', bg: 'white' }} _hover={{ border: 'none', bg: 'white' }} />
            </HStack>

            <HStack w="100%" border="1px" py="16px" pl="24px" borderRadius="8px" borderColor="gray.200" bg="gray.50" spacing={0}>
              <VStack spacing={[5, 8]} w="85%" placeSelf="flex-start">
                <Text fontSize={['14px', '16px']} fontWeight={400} color="gray.900">
                  Friend, sali ka sa group buy ko! I&apos;m buying {productName}{' '}
                  from SariSuki at only ₱{productGroupBuyPrice} kapag {groupBuyMaxMembers} tayo na nag-order together!!{' '}
                  {groupBuyDiscount} discount kaysa regular price na ₱{productPrice}!
                </Text>

                <Text w="full" fontSize={['14px', '16px']} fontWeight={400}>Join my group buy here:{' '}<Text as="u" noOfLines={2}>{url}</Text></Text>
              </VStack>

              <VStack px={2} m={0} spacing={0} as="button" onClick={onCopyButtonClick} _active={{ bg: 'gray.50', BorderRadius: '8px' }}>
                <Icon as={MdContentCopy} boxSize={6} color="gray.400" />
                <Text color="gray.400" fontSize={['14px', '16px']} fontWeight={500}>{copyButtonText}</Text>
              </VStack>
            </HStack>
          </VStack>
        </Container>
        <Divider />
        <Container pt={5} pb={5} w="100%">
          <HStack w="100%" px={5} py={2} spacing={6} placeContent={['left', 'center']} overflow={isMobile ? 'scroll' : 'hidden'}>
            <VStack spacing={0}>
              <IconButton
                aria-label="copy-link"
                borderRadius="8px"
                icon={<Icon as={BiLinkAlt} color="cool-blue.500" boxSize={8} />}
                w="64px"
                h="64px"
                bg="white"
                boxShadow="md"
                _hover={{ bg: 'white' }}
                onClick={() => clipboard(url)}
              />
              <Text color="gray.900" fontSize={['14px', '16px']} fontWeight={400}>Copy link</Text>
            </VStack>

            <VStack>
              <FacebookMessengerShareButton appId={APP_ID ?? ''} url={url}>
                <IconButton
                  aria-label="messenger"
                  borderRadius="8px"
                  icon={<Icon as={MessengerIcon} boxSize={10} />}
                  w="64px"
                  h="64px"
                  bg="white"
                  boxShadow="md"
                  _hover={{ bg: 'white' }}
                />
                <Text color="gray.900" fontSize={['14px', '16px']} fontWeight={400}>Messenger</Text>
              </FacebookMessengerShareButton>
            </VStack>

            <VStack>
              <ViberShareButton url={url}>
                <IconButton
                  aria-label="viber"
                  borderRadius="8px"
                  icon={<Icon as={ViberIcon} boxSize={10} />}
                  w="64px"
                  h="64px"
                  bg="white"
                  boxShadow="md"
                  _hover={{ bg: 'white' }}
                />
                <Text color="gray.900" fontSize={['14px', '16px']} fontWeight={400}>Viber</Text>
              </ViberShareButton>
            </VStack>

            <VStack>
              <FacebookShareButton url={url} hashtag="">
                <IconButton
                  aria-label="facebook"
                  borderRadius="8px"
                  icon={<Icon as={FacebookIcon} boxSize={10} />}
                  w="64px"
                  h="64px"
                  bg="white"
                  boxShadow="md"
                  _hover={{ bg: 'white' }}
                />
                <Text color="gray.900" fontSize={['14px', '16px']} fontWeight={400}>Facebook</Text>
              </FacebookShareButton>
            </VStack>
          </HStack>
        </Container>
      </ModalContent>
    </Modal>
  );
};
export default ProductShareGroupBuyModal;
