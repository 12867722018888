import { createReducer } from '@reduxjs/toolkit';
import { CheckoutFormData } from '@sarisuki/checkout/types';
import log from '@utils/log';
import { AntiHoardingConfig } from '@sarisuki/checkout/types/AntiHoardingConfig';
import { PaymentDetails } from '@sarisuki/checkout/types/PaymentDetails';
import { CreditDebitToken } from '@sarisuki/checkout/types/CreditDebitToken';
import { checkoutCart, setAntiHoardingConfig, selectDiscount } from '.';
import { setUpdateInfoFromCheckout, setIsCheckoutFormValid, setMobileNumber, submitCheckoutForm, setClaimRaffle, setEWalletDetails, generateCreditDebitToken, setShowPaymentForm, retryPayment, setPaymentMethod } from './actions';

interface CheckoutState {
  isFormValid: boolean;
  isLoading: boolean;
  isOTPLoading: boolean;
  error: any | null;
  form: CheckoutFormData | null;
  mobileNumber: string;
  selectedDiscount: any;
  updateInfoFromCheckout: boolean | null;
  antiHoardingConfig: AntiHoardingConfig[] | null;
  claimRaffle: boolean;
  paymentDetails: PaymentDetails | null;
  creditDebitToken: CreditDebitToken | null;
  showPaymentForm: boolean;
  paymentMethod: string;
}

const initialState: CheckoutState = {
  isFormValid: false,
  isLoading: false,
  isOTPLoading: false,
  error: null,
  form: null,
  selectedDiscount: { discount_type: '', code: '' },
  mobileNumber: '',
  updateInfoFromCheckout: null,
  antiHoardingConfig: null,
  claimRaffle: true,
  paymentDetails: null,
  creditDebitToken: null,
  showPaymentForm: false,
  paymentMethod: '',
};

const checkoutReducer = createReducer(initialState, (builder) => {
  builder
  .addCase(checkoutCart.fulfilled, (state) => {
    state.isLoading = false;
  })
  .addCase(checkoutCart.pending, (state) => {
    state.isLoading = true;
  })
  .addCase(checkoutCart.rejected, (state, action) => {
    const { error } = action;
    state.isLoading = false;
    state.error = {
      code: error.code!,
      message: error.message!,
      name: error.name!,
    };
    log({ errorId: 'Checkout Error', isMobile: false, error: action.error, info: '/checkoutCart Error' });
  })

  .addCase(setUpdateInfoFromCheckout, (state, action) => {
    state.updateInfoFromCheckout = action.payload;
  })
  .addCase(setAntiHoardingConfig, (state, action) => {
    state.antiHoardingConfig = action.payload;
  })
  .addCase(setIsCheckoutFormValid, (state, action) => {
    state.isFormValid = action.payload;
  })
  .addCase(setMobileNumber, (state, action) => {
    state.mobileNumber = action.payload;
  })
  .addCase(selectDiscount.fulfilled, (state, action) => {
    state.selectedDiscount = action.payload;
  })

  .addCase(submitCheckoutForm.pending, (state) => {
    state.isLoading = true;
  })
  .addCase(submitCheckoutForm.fulfilled, (state, action) => {
    const { payload } = action;
    state.form = payload;
    state.isLoading = false;
  })
  .addCase(submitCheckoutForm.rejected, (state) => {
    state.form = null;
    state.isLoading = false;
  })
  .addCase(setClaimRaffle, (state, action) => {
    state.claimRaffle = action.payload;
  })
  .addCase(setEWalletDetails, (state, action) => {
    state.paymentDetails = action.payload;
  })
  .addCase(generateCreditDebitToken.fulfilled, (state, action) => {
    state.creditDebitToken = action.payload;
    state.isLoading = false;
    state.error = null;
  })
  .addCase(generateCreditDebitToken.rejected, (state, action) => {
    state.creditDebitToken = null;
    state.isLoading = false;

    const { error } = action;
    state.error = {
      code: error.code!,
      message: error.message!,
      name: error.name!,
    };
    log({ errorId: 'Checkout Error', isMobile: false, error: action.error, info: '/generateCreditDebitToken Error' });
  })
  .addCase(setShowPaymentForm, (state, action) => {
    state.showPaymentForm = action.payload;
  })
  .addCase(retryPayment.fulfilled, (state, action) => {
    state.paymentDetails = action.payload;
    state.isLoading = false;
    state.error = null;
  })
  .addCase(retryPayment.rejected, (state, action) => {
    state.paymentDetails = null;
    state.isLoading = false;

    const { error } = action;
    state.error = {
      code: error.code!,
      message: error.message!,
      name: error.name!,
    };
    log({ errorId: 'Checkout Error', isMobile: false, error: action.error, info: '/generateCreditDebitToken Error' });
  })
  .addCase(setPaymentMethod, (state, action) => {
    state.paymentMethod = action.payload;
  });
});
export default checkoutReducer;
