import { createAction } from '@reduxjs/toolkit';
import { Brand } from '@components/filterSort/type/Brand';
import { SortOption } from '@components/filterSort/type/SortOption';
import { ProductCategory } from '@sarisuki/productCategories/types/productCategory';

export const setSwitchStatus = createAction<boolean>(
  'filterSort/setSwitchStatus',
);

export const setBrandOptions = createAction<Brand[]>(
  'filterSort/setBrandOptions',
);

export const setCategoryOptions = createAction<ProductCategory[]>(
  'filterSort/setCategoryOptions',
);

export const setMinPriceSliderValue = createAction<number>(
  'filterSort/setMinPriceSliderValue',
);

export const setMaxPriceSliderValue = createAction<number>(
  'filterSort/setMaxPriceSliderValue',
);

export const setSortOption = createAction<SortOption>(
  'filterSort/setSortOption',
);

export const updateSelectedBrandOptions = createAction<string>(
  'filterSort/updateSelectedBrandOptions',
);

export const updateSelectedCategoryOptions = createAction<string>(
  'filterSort/updateSelectedCategoryOptions',
);

export const resetSelectedBrandOptions = createAction(
  'filterSort/resetSelectedBrandOptions',
);

export const resetSelectedCategoryOptions = createAction(
  'filterSort/resetSelectedCategoryOptions',
);

export const resetPriceSliderValue = createAction(
  'filterSort/resetPriceSliderValue',
);

export const resetSortOption = createAction(
  'filterSort/resetSortOption',
);

export const resetAllFilterOptions = createAction(
  'filterSort/resetAllFilterOptions',
);

export const setIsApplyFilterButtonDisabled = createAction<boolean>(
  'filterSort/setIsApplyFilterButtonDisabled',
);

export const updatePreviousFilterStates = createAction(
  'filterSort/updatePreviousFilterStates',
);

export const revertToPreviousFilterStates = createAction(
  'filterSort/revertToPreviousFilterStates',
);

export const showFilterSortPanel = createAction(
  'filterSort/showFilterSortPanel',
);

export const hideFilterSortPanel = createAction(
  'filterSort/hideFilterSortPanel',
);

export const showFilterSortExitBottomsheet = createAction(
  'filterSort/onFilterSortCloseModalOpen',
);

export const hideFilterSortExitBottomsheet = createAction(
  'filterSort/hideFilterSortExitBottomsheet',
);
