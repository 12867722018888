const getStoreSchedule = (storeSched: any[]) => storeSched.map((day) => {
        switch (day[0]) {
            case 'sun':
                return 'Sunday';
            case 'mon':
                return 'Monday';
            case 'tue':
                return 'Tuesday';
            case 'wed':
                return 'Wednesday';
            case 'thu':
                return 'Thursday';
            case 'fri':
                return 'Friday';
            case 'sat':
                return 'Saturday';
            default:
                return 'No Store Schedule';
        }
    });

const getActiveDays = (schedule: any) => {
    const activeDays = Object.entries(schedule).filter(([, isActive]) => isActive);
    return getStoreSchedule(activeDays);
};

const getInActiveDays = (schedule: any) => {
    const inActiveDays = Object.entries(schedule).filter(([, isActive]) => !isActive);
    return getStoreSchedule(inActiveDays);
};
export default { getStoreSchedule, getActiveDays, getInActiveDays };
