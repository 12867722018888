import { Modal, ModalOverlay, ModalContent, VStack, Text, ModalCloseButton, Button } from '@chakra-ui/react';
import { BaseModalProps } from '@sarisuki/modal/types';

interface IProps extends BaseModalProps {}
const OrderHistoryNoItemsAddedModal: React.FC<IProps> = ({ open, onDismiss }: IProps) => (
  <Modal isOpen={open} onClose={onDismiss}>
    <ModalOverlay />
    <ModalContent w={['95%', 'full']} maxH={['38%', 'full']} borderRadius="4px" px="80px" py="46px" placeSelf="center">
      <ModalCloseButton />
      <VStack>
        <Text fontWeight={700} fontSize={['18px', '20px']} color="black" textAlign="center">
          All items for this order are out of stock
        </Text>
        <Text fontWeight={400} fontSize={['12px', '14px']} color="gray.60" textAlign="center" pb="20px">
          We won’t be adding them to your cart.
        </Text>
        <Button
          bg="sarisuki-green.500"
          color="white"
          borderRadius="4px"
          fontWeight={700}
          fontSize="16px"
          w="80%"
          p={5}
          _focus={{ border: 'none', bg: 'sarisuki-green.500' }}
          _hover={{ border: 'none', bg: 'sarisuki-green.500' }}
          onClick={onDismiss}
        >
          Got it, proceed
        </Button>
      </VStack>
    </ModalContent>
  </Modal>
);
export default OrderHistoryNoItemsAddedModal;
