import axios from '@const/api';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ReferralCodeFormData } from '@sarisuki/referral/types';
import { AvailableReferralVouchers } from '@sarisuki/referral/types/AvailableReferralVouchers';
import { ReferralVoucherList } from '@sarisuki/referral/types/ReferralVoucherList';
import { VoucherConfig } from '@sarisuki/referral/types/VoucherConfig';
import mobileNumberFormatter from '@utils/mobileNumberFormatter';

interface ValidateReferralCodeOptions {
  referral_code: string;
  mobile_number: string;
}

interface AvailableReferralVouchersOptions {
  code: string,
  cart_id: string,
}

export const submitReferralCodeForm = createAsyncThunk<ReferralCodeFormData, ReferralCodeFormData>(
  'referral/submitReferralCodeForm',
  async (formData) => formData,
);

export const getReferralCode = createAsyncThunk<any>(
  'referral/getReferralCode',
  async () => {
    const response = await axios({
      method: 'post',
      url: '/referral/retrieve-code/',
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });
    return response.data;
  },
);

export const getReferralVoucherList = createAsyncThunk<PaginatedResponse<ReferralVoucherList>>(
  'referral/getReferralVoucherList',
  async () => {
    const response = await axios({
      method: 'post',
      url: '/referral/retrieve-list/',
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });
    return response.data;
  },
);

interface ValidateReferralCodeOptions {
  referral_code: string;
  mobile_number: string;
}

export const validateReferralCode = createAsyncThunk<any, ValidateReferralCodeOptions>(
  'referral/validateReferralCode',
  async (payload) => {
    let response;
    const generalizedMobileNumber =
      mobileNumberFormatter.generalizeMobileNumber(payload.mobile_number);

    try {
      response = await axios({
        method: 'post',
        url: `/referral/validate-code/${payload.referral_code}/`,
        data: { mobile_number: generalizedMobileNumber },
      });
    } catch (error: any) {
      throw error.response.data.errors[0];
    }

    return response?.data;
  },
);

export const getAvailableReferralVouchers = createAsyncThunk<
  PaginatedResponse<AvailableReferralVouchers>,
  AvailableReferralVouchersOptions>(
  'referral/getAvailableReferralVouchers',
  async (payload) => {
    const response = await axios({
      method: 'post',
      url: '/vouchers/for-cart/',
      data: { ...payload },
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });

    return response.data;
  },
);

export const getAvailableReferralVouchersForUser = createAsyncThunk<
  PaginatedResponse<AvailableReferralVouchers>>(
  'referral/getAvailableReferralVouchersForUser',
  async () => {
    const response = await axios({
      method: 'get',
      url: '/vouchers/referral-program/',
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });

    return response.data;
  },
);

export const getVoucherConfig = createAsyncThunk< VoucherConfig, string>(
  'referral/getVoucherConfig',
  async (type) => {
    const response = await axios({
      method: 'post',
      url: '/referral/voucher-config/',
      data: { type },
    });

    return response.data;
  },
);

export const setUserFromBanner = createAction<boolean>(
  'referral/setUserFromBanner',
);

export const setReferralCodeText = createAction<string>(
  'referral/setReferralCodeText',
);
