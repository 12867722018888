import { VStack, IconButton, Container, Avatar, Heading, Box, HStack, Icon, Link, Text } from '@chakra-ui/react';
import { BaseBottomSheetProps } from '@sarisuki/bottomSheet/types';
import React, { useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import StoreHeaderBG from '@assets/images/store-bg.png';
import CloseIcon from '@assets/icons/close.svg';
import { useAppSelector } from '@app/hooks';
import { selectStore } from '@sarisuki/store/storeSlice';
import { FiCopy } from 'react-icons/fi';
import mobileNumberFormatter from '@utils/mobileNumberFormatter';
import { getMonth } from '@customTypes/getMonth';
import storeSchedule from '@utils/storeSchedule';
import storeScheduleFormatter from '@utils/storeScheduleFormatter';

interface IProps extends BaseBottomSheetProps {}

const StoreInfoBottomSheet = ({ open, onDismiss }: IProps) => {
  const { storeName, emoji, storeCL } = useAppSelector(selectStore);
  const store = useAppSelector(selectStore);
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const TEXT_DISPLAY_WAIT_TIME = 3000;
  const [storeCLName, setStoreCLName] = useState('');
  const [storeCLMobileNumber, setStoreCLMobileNumber] = useState('');
  const [storeCLAddress, setStoreCLAddress] = useState('');
  const [formattedInactiveDays] = useState('');
  const [formattedActiveDays, setFormattedActiveDays] = useState('');
  const [dateCreated, setDateCreated] = useState('');
  const activeDays = storeSchedule.getActiveDays(store.data?.schedule);

  useEffect(() => {
    if (storeCL?.first_name && storeCL.last_name) {
      setStoreCLName(`${storeCL.first_name} ${storeCL.last_name.charAt(0)}.`);
    }

    if (storeCL?.cl_info?.mobile_number) {
      setStoreCLMobileNumber(mobileNumberFormatter.internationalMobileNumber(
        storeCL?.cl_info?.mobile_number),
      );
    }

    if (storeCL?.cl_info?.address && storeCL.cl_info.barangay && storeCL.cl_info.city) {
      setStoreCLAddress(`${storeCL.cl_info.address}, ${storeCL.cl_info.barangay}, ${storeCL.cl_info.city}`);
    }

    if (store.data?.created_at) {
      const date = new Date(store.data?.created_at);
      const month = getMonth(date.toString(), 'short');
      const year = date.getFullYear();
      setDateCreated(`${month} ${year}`);
    }

    setFormattedActiveDays(storeScheduleFormatter(activeDays));
  }, []);

  const onCopyButtonClick = () => {
    if (storeCL?.cl_info?.mobile_number) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(
          mobileNumberFormatter.generalizeMobileNumber(storeCL?.cl_info?.mobile_number),
        );
      } else {
        console.error('Note to developers: you need HTTPS for this to work.');
      }

      setCopyButtonText('Copied!');
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => { setCopyButtonText('Copy'); }, TEXT_DISPLAY_WAIT_TIME);
    return () => clearTimeout(timer);
  }, [copyButtonText]);

  const renderStoreInfo = () => (
    <Container maxW="100vw" mb={10} px={0} overflow="scroll" pb={5}>
      <Box
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundImage={StoreHeaderBG.src}
        bgSize="cover"
        h="200px"
        w="100%"
        objectFit="cover"
        p={0}
      >
        <IconButton
          size="lg"
          isRound
          color="white"
          float="right"
          aria-label="Search database"
          icon={<CloseIcon color="green" />}
          variant="solid"
          bgColor="white"
          _focus={{ borderColor: 'white' }}
          mr={5}
          mt={3}
          onClick={onDismiss}
        />
      </Box>

      <Avatar icon={<span role="img">{emoji}</span>} bg="white" placeSelf="flex-start" mt="-20px" ml="30px" />

      <VStack w="full" px={8} spacing={0} pt={2}>
        <Heading w="100%" textAlign="left" color="#121619" fontWeight={600} fontSize="20px">{storeName}</Heading>
        <Text fontWeight={400} pt={4} fontSize="12px" w="100%" textAlign="left" color="gray.60">Managed by your Community Leader</Text>
        <Text fontWeight={400} fontSize="14px" w="100%" textAlign="left" color="gray.700">{storeCLName}</Text>

        <HStack w="100%">
          <Text fontWeight={400} fontSize="14px" textAlign="left" color="gray.700" pr={3} py={1}>
            {storeCLMobileNumber}
          </Text>

          <HStack as="button" onClick={onCopyButtonClick} _focus={{ bg: 'white' }} _hover={{ bg: 'white' }}>
            <Icon as={FiCopy} boxSize={4} color="green.600" bg="white" />
            <Text fontWeight={600} fontSize="14px" w="100%" textAlign="left" color="green.500">
              {copyButtonText}
            </Text>
          </HStack>
        </HStack>

        <Text fontWeight={400} fontSize="14px" textAlign="left" w="100%" color="gray.700" isTruncated>{storeCLAddress}</Text>
        <Link
          href={`http://maps.google.com/maps?q=${storeCL?.cl_info?.latitude},${storeCL?.cl_info?.longitude}&z=30`}
          isExternal
          fontWeight={600}
          fontSize="14px"
          w="100%"
          textAlign="left"
          color="green.500"
          _focus={{ border: 'none' }}
          _hover={{ border: 'none' }}
          _active={{ border: 'none' }}
        >
          Open in Google Maps
        </Link>

        <Text fontWeight={400} fontSize="12px" w="100%" textAlign="left" color="gray.60" pt={4}>Since</Text>
        <Text fontWeight={400} fontSize="16px" w="100%" textAlign="left" color="gray.700">{dateCreated}</Text>
        <Text fontWeight={400} ontSize="12px" w="100%" textAlign="left" color="gray.60" pt={4}>Suki customers</Text>
        <Text fontWeight={400} fontSize="16px" w="100%" textAlign="left" color="gray.700">{store?.data?.valid_suki ?? 0}</Text>

        {(formattedActiveDays || formattedInactiveDays) &&
          <Text fontWeight={400} fontSize="12px" w="100%" textAlign="left" color="gray.60" pt={4}>Schedule</Text>
        }

        {formattedActiveDays &&
          <Text fontWeight={400} fontSize="16px" w="100%" textAlign="left" color="gray.700">Order every {formattedActiveDays}</Text>
        }

        {formattedInactiveDays &&
          <Text fontWeight={400} fontSize="16px" w="100%" textAlign="left" color="gray.700">Pickup or delivery every {formattedInactiveDays}</Text>
        }
      </VStack>
    </Container>
  );

  return (
    <BottomSheet
      open={open}
      onDismiss={onDismiss}
      scrollLocking
      blocking
      expandOnContentDrag={false}
      maxHeight={450}
    >
      {renderStoreInfo()}
    </BottomSheet>
  );
};

export default StoreInfoBottomSheet;
