import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { createReducer } from '@reduxjs/toolkit';
import { ReferralCodeFormData } from '@sarisuki/referral/types';
import log from '@utils/log';
import { AvailableReferralVouchers } from '@sarisuki/referral/types/AvailableReferralVouchers';
import { ReferralVoucherList } from '@sarisuki/referral/types/ReferralVoucherList';
import { VoucherConfig } from '@sarisuki/referral/types/VoucherConfig';
import {
  getAvailableReferralVouchers,
  getAvailableReferralVouchersForUser,
  getReferralCode,
  setReferralCodeText,
  getReferralVoucherList,
  getVoucherConfig,
  setUserFromBanner,
  submitReferralCodeForm,
  validateReferralCode,
} from '.';

export interface ReferralState {
  form: ReferralCodeFormData | null;
  availableReferralVouchers: PaginatedResponse<AvailableReferralVouchers>;
  availableReferralVouchersForUser: PaginatedResponse<AvailableReferralVouchers>;
  referralVoucherList: PaginatedResponse<ReferralVoucherList>;
  isLoading: boolean;
  error: any;
  referralCodeText: string;
  referralCode: string | null;
  voucherConfig: VoucherConfig;
  isUserFromBanner: boolean;
}

const initialState: ReferralState = {
  form: null,
  availableReferralVouchers: {
    count: 0,
    limit: 0,
    next: '',
    offset: 0,
    previous: '',
    results: [],
  },
  availableReferralVouchersForUser: {
    count: 0,
    limit: 0,
    next: '',
    offset: 0,
    previous: '',
    results: [],
  },
  referralVoucherList: {
    count: 0,
    limit: 0,
    next: '',
    offset: 0,
    previous: '',
    results: [],
  },
  isLoading: false,
  error: null,
  referralCode: null,
  referralCodeText: '',
  voucherConfig: {
    configuration_for: '',
    voucher_name: '',
    voucher_type: '',
    discount_value: 0,
    minimum_order_amount: '',
  },
  isUserFromBanner: false,
};

const referralReducer = createReducer(initialState, (builder) => {
  builder.addCase(submitReferralCodeForm.fulfilled, (state, action) => {
    state.form = action.payload;
  });

  builder.addCase(setReferralCodeText, (state, action) => {
    state.referralCodeText = action.payload;
  });

  builder.addCase(getAvailableReferralVouchers.fulfilled, (state, action) => {
    state.isLoading = false;
    state.availableReferralVouchers = action.payload;
  });

  builder.addCase(getAvailableReferralVouchersForUser.fulfilled, (state, action) => {
    state.isLoading = false;
    state.availableReferralVouchersForUser = action.payload;
  });

  builder.addCase(getReferralVoucherList.fulfilled, (state, action) => {
    state.isLoading = false;
    state.referralVoucherList = action.payload;
  });

  builder.addCase(getReferralCode.fulfilled, (state, action) => {
    state.isLoading = false;
    state.referralCode = action.payload.code;
    state.referralCodeText = action.payload.code;
  });
  builder.addCase(getReferralCode.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getReferralCode.rejected, (state, action) => {
    state.isLoading = false;
    state.error = action.error;
    log({ errorId: 'Referral Error', isMobile: false, error: action.error, info: '/getReferralCode Error' });
  });

  builder.addCase(validateReferralCode.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(validateReferralCode.fulfilled, (state) => {
    state.isLoading = false;
  });
  builder.addCase(validateReferralCode.rejected, (state, action) => {
    state.isLoading = false;
    const { error } = action;
    state.error = {
      name: error.name || '',
      code: error.code || '',
      message: error.message || '',
    };
  });

  builder.addCase(getVoucherConfig.fulfilled, (state, action) => {
    state.voucherConfig = action.payload;
  });

  builder.addCase(setUserFromBanner, (state, action) => {
    state.isUserFromBanner = action.payload;
  });
});

export default referralReducer;
