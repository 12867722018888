import { createReducer, isPending } from '@reduxjs/toolkit';
import { SarisukiApiError } from '@customTypes/api';
import { StoreCL } from '@sarisuki/store/types/storeCL';
import { StoreSuki } from '@sarisuki/store/types/storeSuki';
import { Store } from '@sarisuki/store/types/store';
import emojis from 'src/theme/emojis';
import log from '@utils/log';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { cutOffInfo } from '@sarisuki/store/types/cutOffInfo';
import { redBannerInfo } from '@sarisuki/store/types/redBannerInfo';
import { storeBanner } from '@sarisuki/store/types/storeBanner';
import { NearbyStore } from '@sarisuki/store/types/nearbyStore';
import { StoreLandingPage } from '@sarisuki/store/types/storeLandingPage';
import { getBanners, setStoreUrl, getIPAddress, getLandingPageBanners, setShowClearSearchIcon, setShowFilterSortButton } from './actions';
import { addStoreSuki, getCutoffs, getStoreInfo, getStores, setSearchingState, setEmoji, verifyStoreSuki, getNearbyStores, getRedBanner } from '.';

export interface StoreState {
  isLoading: boolean;
  data: Store | null;
  error: SarisukiApiError | null;
  storeId: number | null;
  storeName: string;
  storeUrl: string;
  storeCL: StoreCL | null;
  storeImgUrl: string;
  suki: StoreSuki | null;
  isSearching: boolean;
  cutoff: cutOffInfo | null
  active: boolean;
  emoji: string;
  banners: PaginatedResponse<storeBanner> | null;
  landingPageBanners: StoreLandingPage | null;
  nearbyStores: NearbyStore[] | null;
  ipAddress: string | null;
  showClearSearchIcon: boolean;
  showFilterSortButton: boolean;
  redBanner: redBannerInfo | null;
}

const initialState: StoreState = {
  isLoading: true,
  data: null,
  error: null,
  storeId: null,
  storeName: '',
  storeUrl: '',
  storeCL: null,
  storeImgUrl: '',
  suki: null,
  isSearching: false,
  cutoff: null,
  active: false,
  emoji: '',
  banners: null,
  landingPageBanners: null,
  nearbyStores: null,
  ipAddress: null,
  showClearSearchIcon: false,
  showFilterSortButton: false,
  redBanner: null,
};

const storeReducer = createReducer(initialState, (builder) => {
  builder.addCase(setEmoji, (state) => {
    const random_index = Math.floor(Math.random() * emojis.length) + 1;
    state.emoji = emojis[random_index];
  });
  builder.addCase(setShowClearSearchIcon, (state, action) => {
    state.showClearSearchIcon = action.payload;
  });
  builder.addCase(setShowFilterSortButton, (state, action) => {
    state.showFilterSortButton = action.payload;
  });

  builder.addCase(getStores.fulfilled, (state) => {
    state.isLoading = false;
  });
  builder.addCase(getStores.rejected, (state, action) => {
    const { error } = action;
    state.isLoading = false;
    state.error = {
      code: error.code || '',
      message: error.message || '',
      name: error.name || '',
    };
    log({ errorId: 'Store Error', isMobile: false, error: action.error, info: '/getStores Error' });
  });

  builder.addCase(addStoreSuki.fulfilled, (state, action) => {
    state.suki = action.payload;
  });
  builder.addCase(addStoreSuki.rejected, (state, action) => {
    const { error } = action;

    state.error = {
      name: 'invalid otp',
      code: error.code || '',
      message: 'Invalid OTP',
    };
    log({ errorId: 'Store Error', isMobile: false, error: action.error, info: '/addStoreSuki Error' });
  });

  builder.addCase(verifyStoreSuki.fulfilled, (state, action) => {
    state.suki = action.payload;
  });
  builder.addCase(verifyStoreSuki.rejected, (state, action) => {
    const { error } = action;
    state.error = {
      name: error.name || '',
      code: error.code || '',
      message: error.message || '',
    };
    log({ errorId: 'Store Error', isMobile: false, error: action.error, info: '/verifyStoreSuki Error' });
  });

  builder.addCase(getStoreInfo.fulfilled, (state, action) => {
    const { id, name, url, user, image } = action.payload;
    state.data = action.payload;
    state.storeId = id;
    state.storeName = name || '';
    state.storeUrl = url || '';
    state.storeCL = user;
    state.storeImgUrl = image || '';
    state.error = null;
    state.isLoading = false;
  });
  builder.addCase(getStoreInfo.rejected, (state, action) => {
    const { error } = action;
    state.isLoading = false;
    state.error = {
      name: error.name || '',
      code: error.code || '',
      message: error.message || '',
    };
    log({ errorId: 'Store Error', isMobile: false, error: action.error, info: '/getStoreInfo Error' });
  });

  builder.addCase(getBanners.fulfilled, (state, action) => {
    state.banners = action.payload;
  });
  builder.addCase(getBanners.rejected, (state, action) => {
    const { error } = action;
    state.isLoading = false;
    state.error = {
      code: error.code || '',
      message: error.message || '',
      name: error.name || '',
    };
    log({ errorId: 'Store Error', isMobile: false, error: action.error, info: '/getBanners Error' });
  });

  builder.addCase(getNearbyStores.fulfilled, (state, action) => {
    state.nearbyStores = action.payload;
  });

  builder.addCase(getCutoffs.fulfilled, (state, action) => {
    const { cutoff } = action.payload;
    state.cutoff = cutoff;
    state.isLoading = false;
  });

  builder.addCase(getLandingPageBanners.fulfilled, (state, action) => {
    state.landingPageBanners = action.payload;
  });

  builder.addCase(getIPAddress.fulfilled, (state, action) => {
    const { IPv4 } = action.payload;
    state.ipAddress = IPv4;
  });
  builder.addCase(setSearchingState.fulfilled, (state, action) => {
    state.isSearching = action.payload;
  });
  builder.addCase(setSearchingState.rejected, (state) => {
    state.isSearching = initialState.isSearching;
  });

  builder.addCase(setStoreUrl, (state, action) => {
    state.storeUrl = action.payload;
  });

  builder.addCase(getRedBanner.fulfilled, (state, action) => {
    const { text } = action.payload;
    state.redBanner = text;
    state.isLoading = false;
  });

  builder.addMatcher(
    isPending(getStoreInfo, verifyStoreSuki, getStores),
    (state) => {
      state.isLoading = true;
      state.error = null;
    },
  );
});
export default storeReducer;
