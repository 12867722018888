import axios from '@const/api';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { FeaturedProduct } from '@sarisuki/home/types/featuredProduct';
import { FeaturedCL } from '../types/featuredCL';

export const setIsSearchingAddress = createAction<boolean>(
  'home/setIsSearchingAddress',
);

export const setIsFromHomeLandingPage = createAction<boolean>(
  'home/setIsFromHomeLandingPage',
);

export const addStoreLocations = createAsyncThunk<any, any>(
  'home/addStoreLocations',
  async (stores) => stores,
);

export const getStoreLocations = createAsyncThunk<any, any>(
  'home/getStoreLocations',
  async (payload, { dispatch }) => {
    const { startOffset } = payload;
    let currOffset = startOffset;
    const getStoreLocationPromises = [];
    const currLimit = 500;

    // initial call to get total number of stores
    const response = await axios.get('/stores/', {
      params: {
        limit: currLimit,
        offset: currOffset,
      },
    });
    const { results, count } = response.data;
    dispatch(addStoreLocations(results));
    currOffset += currLimit;

    while (currOffset < count) {
      const getStoreLocationPromise = axios.get('/stores/', {
        params: {
          limit: currLimit,
          offset: currOffset,
        },
      });
      getStoreLocationPromises.push(getStoreLocationPromise);

      currOffset += currLimit;
    }

    const responses = await Promise.all(getStoreLocationPromises);

    responses.forEach((currResponse) => {
      const { results: currResults } = currResponse.data;
      dispatch(addStoreLocations(currResults));
    });
  },
);

export const setTutorial = createAction<number>(
  'home/setTutorial',
);

export const filterPins = createAction<string>(
  'home/filterPins',
);

export const setInputtedAddress = createAction<string>(
  'home/setInputtedAddress',
);

export const setCityProvince = createAction<any>(
  'home/setCityProvince',
);

export const revertToAllStoreLocations = createAction(
  'home/revertToAllStoreLocations',
);

export const getFeaturedProducts = createAsyncThunk<PaginatedResponse<FeaturedProduct>>(
  'home/getFeaturedProducts',
  async () => {
    const response = await axios.get('/products/featured/');
    return response.data;
  },
);

export const getFeaturedCL = createAsyncThunk<FeaturedCL>(
  'home/getFeaturedCL',
  async () => {
    const response = await axios.get('/stores/featured/');
    return response.data;
  },
);

export const setSelectedStoreLocation = createAction<any>(
  'home/setSelectedStoreLocation',
);

export const closeStoreInfoWindow = createAction(
  'home/closeStoreInfoWindow',
);
