import { Box, BoxProps, HStack, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { ReactNode, useState } from 'react';
import { MixpanelNetcore } from '@sarisuki/store/components/MixpanelNetcore';
import { useDesktopMediaQuery } from '@hooks/responsive_hook';
import { LocalStorageKeys } from '@const/keys';
import { NETCORE_CATEGORY_ID } from '@const/api';

interface IProps extends BoxProps {
  id: string;
  label: string;
  icon: ReactNode;
  isActive: boolean;
  isMobile: boolean;
  onClick: () => void;
}

const ProductCategoryItem = forwardRef<IProps, 'div'>(({ label, icon, isActive, onClick, id }) => {
    const [toolTipConfig, setToolTipConfig] = useState<any>({ categoryName: '', isOpen: false });
    const FIRST_CATEGORY = document.querySelector(`#${NETCORE_CATEGORY_ID}`);
    const isDesktop = useDesktopMediaQuery();

    const isInViewport = (element: any) => {
      if (!element) return null;
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    document.addEventListener('scroll', () => {
      if (isInViewport(FIRST_CATEGORY) && !window.localStorage.getItem('hide_netcore_nudge') && isDesktop) {
        MixpanelNetcore.track('Product Category Visible', {
          url: window.location.href,
        });
        localStorage.setItem(LocalStorageKeys.HIDE_NETCORE_NUDGE, 'TRUE');
      }
    });

    return (
      <Box
        id={id}
        zIndex={1}
        as="button"
        display="flex"
        flexDir="column"
        flex="1 1 0"
        alignItems="center"
        justifyContent="flex-start"
        pt={['0px', '10px']}
        transition="all"
        transitionDuration="300ms"
        rounded="md"
        onClick={onClick}
        type="button"
        _hover={{ bg: 'gray.200' }}
        w="100%"
      >
        <HStack
          position="relative"
          align="center"
          justify="center"
          flexShrink={0}
          objectFit="fill"
          bg="white"
          overflow="hidden"
          rounded="full"
          w={['56px', '80px']}
          h={['56px', '80px']}
        >
          {icon}
        </HStack>

        <HStack align="center" justify="center" overflow="inherit">
          <Text
            color={isActive ? 'sarisuki-green.500' : ''}
            textAlign="center"
            maxH={['48px', '120px']}
            maxW={['55px', 'full']}
            flex={1}
            fontSize={['12px', '16px']}
            pt={0}
            noOfLines={2}
            placeSelf="flex-start"
            onMouseEnter={() => setToolTipConfig({ categoryName: label, isOpen: true })}
            onMouseLeave={() => setToolTipConfig({ categoryName: label, isOpen: false })}
          >
            <Tooltip
              hasArrow
              label={label}
              isOpen={toolTipConfig.categoryName === label && toolTipConfig.isOpen}
            >
              {label}
            </Tooltip>
          </Text>
        </HStack>
      </Box>
    );
  },
);
export default ProductCategoryItem;
