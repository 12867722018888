import { HStack, Spacer, VStack, Text, IconButton, Icon, Image, Box, Button, Input, useNumberInput } from '@chakra-ui/react';
import { BaseBottomSheetProps } from '@sarisuki/bottomSheet/types';
import React, { useEffect, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { MdOutlineClose } from 'react-icons/md';
import numberFormatter from '@utils/numberFormatter';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { createGroupBuyCart, selectCart } from '@sarisuki/cart/cartSlice';
import { createGroupBuyGroup, selectProducts, setActiveGroupBuyId } from '@sarisuki/products/productsSlice';
import { FiUsers } from 'react-icons/fi';
import router from 'next/router';
import { hideBottomSheet } from '@sarisuki/bottomSheet/bottomSheetSlice';
import { LocalStorageKeys } from '@const/keys';
import { selectStore } from '@sarisuki/store/storeSlice';

interface IProps extends BaseBottomSheetProps {}
const ProductBuyWithAGroupBottomsheet = ({ open, onDismiss }: IProps) => {
  const { product, productGroupBuy, activeGroupBuyId, productGroupBuyGroup }
  = useAppSelector(selectProducts);
  const { groupBuyCart } = useAppSelector(selectCart);
  const dispatch = useAppDispatch();
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
  useNumberInput({
    step: 1,
    defaultValue: 1,
    min: 1,
    max: product?.available_stock_qty || 0,
    precision: 0,
  });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  const [totalAmount, setTotalAmount] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const { storeUrl } = router.query;
  const { storeId } = useAppSelector(selectStore);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (input['aria-valuenow']) {
      setTotalItems(input['aria-valuenow']);
      if (productGroupBuy?.price) {
        setTotalAmount(numberFormatter.formatNumberToPHCurrency(productGroupBuy.price * input['aria-valuenow']));
      }
    }
  }, [input['aria-valuenow']]);

  useEffect(() => {
    if (activeGroupBuyId) {
      dispatch(hideBottomSheet());
      router.replace({
        pathname: '/[storeUrl]/groupbuy/[groupBuyId]',
        query: { storeUrl, groupBuyId: activeGroupBuyId },
      });
    }
  }, [activeGroupBuyId]);

  useEffect(() => {
    if (groupBuyCart && activeGroupBuyId === '') {
      dispatch(hideBottomSheet());
      localStorage.setItem(LocalStorageKeys.GROUP_BUY_ID, `${groupBuyCart.group}`);
      router.push(`/${storeUrl}/checkout`);
    }
  }, [groupBuyCart]);

  const getGroupBuyCart = async (id: string) => {
    await dispatch(createGroupBuyCart({
      store_id: storeId ?? 0,
      group_id: id,
      quantity: totalItems,
    }));
  };

  useEffect(() => {
    if (productGroupBuyGroup) {
      dispatch(setActiveGroupBuyId(''));
      getGroupBuyCart(productGroupBuyGroup.id);
    }
  }, [productGroupBuyGroup]);

  const onStartAGroupBuyClick = async () => {
    if (!productGroupBuy) return;
    setisLoading(true);
    await dispatch(createGroupBuyGroup(productGroupBuy.id));
  };

  return (
    <BottomSheet open={open} scrollLocking onDismiss={onDismiss}>
      <VStack position="relative" w="full" pb={10}>
        <HStack w="100%">
          <Text pl={5} color="gray.900" fontWeight={700} fontSize="20px" isTruncated>{product?.name}</Text>
          <Spacer />
          <IconButton
            onClick={onDismiss}
            aria-label="close button"
            icon={<Icon as={MdOutlineClose} boxSize={6} color="gray.500" />}
            bg="white"
            _hover={{ border: 'none' }}
            _active={{ border: 'none' }}
            _focus={{ border: 'none' }}
            placeSelf="flex-end"
          />
        </HStack>

        <HStack w="100%" pl={5}>
          <Box w="76px" h="76px" borderRadius="8px" placeSelf="flex-start">
            <Image
              src={product?.images[0].url ?? ''}
              alt="product-image"
              borderRadius="8px"
              object-fit="cover"
            />
          </Box>

          <VStack w="100%" placeSelf="flex-start">
            <Text textAlign="left" w="100%" pl={3} color="gray.500" fontWeight={400} fontSize="14px">Stock: {product?.available_stock_qty}</Text>

            <HStack w="100%" spacing={0} pl={3}>
              <Text textAlign="left" color="red.500" fontWeight={400} fontSize="14px">₱</Text>
              <Text textAlign="left" color="red.500" fontWeight={700} fontSize="18px">{numberFormatter.formatNumberToPHCurrency(product?.total_price ?? 0).substring(1)}</Text>

              {productGroupBuy &&
              <>
                <Text textAlign="left" color="gray.400" px={3} as="s" fontWeight={400} fontSize="14px">{numberFormatter.formatNumberToPHCurrency(productGroupBuy?.price ?? 0)}</Text>
                <Text textAlign="left" color="gray.500" px={1} py={0.8} borderRadius="4px" bg="gray.100" fontWeight={600} fontSize="12px">-{Math.round(((productGroupBuy?.product.default_srp_price - productGroupBuy.price) / productGroupBuy?.product.default_srp_price) * 100)}%</Text>
              </>
              }
            </HStack>
          </VStack>
        </HStack>

        <HStack w="100%" spacing={0} pl={5} pb={3}>
          <Text textAlign="left" color="gray.700" fontWeight={400} fontSize="16px">Quantity</Text>
          <Spacer />
          <HStack maxW="198px" maxH="48px" spacing={0} p={2} borderRadius="32px">
            <Button
              {...dec}
              bg="white"
              p={0}
              _hover={{ bg: 'white', border: 'none' }}
              _active={{ bg: 'white', border: 'none' }}
              _focus={{ bg: 'white', border: 'none' }}
            >
              <Icon as={BiMinus} boxSize={8} color="orange.500" />
            </Button>
            <Input
              {...input}
              border="1px"
              borderColor="gray.200"
              textAlign="center"
              borderRadius="100px"
              color="gray.600"
              fontWeight={400}
              h="48px"
              fontSize="24px"
              _focus={{ boxShadow: 'none', borderColor: 'gray.200' }}
            />
            <Button
              {...inc}
              bg="white"
              p={0}
              _hover={{ bg: 'white', border: 'none' }}
              _active={{ bg: 'white', border: 'none' }}
              _focus={{ bg: 'white', border: 'none' }}
            >
              <Icon as={BiPlus} boxSize={8} color="orange.500" />
            </Button>
          </HStack>
        </HStack>

        <Button
          w="90%"
          h="48px"
          py="16px"
          bg="dark-orange"
          fontWeight={600}
          fontSize="16px"
          color="white"
          _hover={{ bg: 'dark-orange', border: 'none' }}
          _active={{ bg: 'dark-orange', border: 'none' }}
          _focus={{ bg: 'dark-orange', border: 'none' }}
          onClick={onStartAGroupBuyClick}
          isLoading={isLoading}
        >
          Start a group buy ({totalItems} {totalItems === 1 ? 'item' : 'items'}): {totalAmount}
        </Button>

        <Text fontWeight={400} fontSize="12px" color="gray.400" pt={1} px={5} lineHeight="16px" textAlign="center" verticalAlign="center">You need to invite{' '}
          <Text as="span" color="dark-orange" fontWeight={600} fontSize="12px" lineHeight="16px">
            <Icon as={FiUsers} boxSize="17px" mx={1} mb={1} strokeWidth={2.5} />
            {productGroupBuy && productGroupBuy.max_members - 1}{' '}other members
          </Text>
          {' '}to your group before the group buy expires.
        </Text>

        {totalItems === product?.available_stock_qty &&
          <Text fontWeight={600} fontSize="12px" color="red.500">You have reached the maximum order limit of this item.</Text>
        }
      </VStack>
    </BottomSheet>
  );
};
export default ProductBuyWithAGroupBottomsheet;
