import { Grid, GridItem, Heading, SimpleGrid } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { BaseBottomSheetProps } from '@sarisuki/bottomSheet/types';
import { hideBottomSheet } from '@sarisuki/bottomSheet/bottomSheetSlice';
import { getProductCategory, productCategorySelected, selectProductCategories, setProductCategoryPickerVisibility } from '@sarisuki/productCategories/productCategoriesSlice';
import { selectStore } from '@sarisuki/store/storeSlice';
import { getProductsByCategoryId } from '@sarisuki/products/productsSlice';
import { setFilterOptions, setIsSwitchChecked } from '@sarisuki/filter/filterSlice';
import OutsideAlerter from '@components/Common/OutsideAlerter';
import { RiArrowDownSLine } from 'react-icons/ri';
import { Icon, IconButton } from '@chakra-ui/react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useMobileMediaQuery, useTabletMediaQuery } from '@hooks/responsive_hook';
import { CATEGORY_FLAG } from '@const/api';
import flag from '@utils/flag';
import ProductCategoryItem from './ProductCategoryItem';

interface IProps extends BaseBottomSheetProps {}

const ProductCategoryBottomSheet = ({ open, onDismiss }: IProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const { storeUrl } = useAppSelector(selectStore);
  const { categories, selectedCategoryIndex }
    = selectProductCategories(useAppSelector((state) => state));

  const retrieveProductsByCategory = async (categoryId: number, index: number) => {
    if (index < 0 || !categoryId) return;

    await dispatch(getProductsByCategoryId({ categoryId, storeUrl }));
    await dispatch(getProductCategory({ productCategoryId: categoryId, storeUrl }));

    /* Filter by Availability is switched off */
    await dispatch(setIsSwitchChecked(false));

    /* Retrieves all filter by price and brand options based on the selected Category */
    await dispatch(setFilterOptions({ storeUrl, categoryId }));
  };

  const onCategoryPressed = (index: number) => {
    const categorySlug = categories.results[index]?.slug;
    const categoryId = categories.results[index]?.id;
    window.scrollTo({ top: 200, behavior: 'smooth' });

    if (flag.on(CATEGORY_FLAG)) {
      router.push({ pathname: '/[storeUrl]/[categorySlug]', query: { storeUrl, categorySlug } });
    } else {
      dispatch(productCategorySelected(index));
      retrieveProductsByCategory(categoryId, index);
    }

    dispatch(setProductCategoryPickerVisibility(false));
    onDismiss();
  };

  const renderCategories = () => (
    <SimpleGrid
      className="bottomsheet-scrolling"
      overflow="scroll"
      columns={4}
      gap="0"
      px="16px"
      gridRowGap="16px"
      mb="30px"
    >
      {categories.results.map((category, index) => (
        <ProductCategoryItem
          id={`CATEGORY-${category.id}`}
          isActive={index === selectedCategoryIndex}
          onClick={() => onCategoryPressed(index)}
          key={category.id}
          label={category.name}
          icon={
            <Image
              src={category.image}
              fallbackSrc="https://via.placeholder.com/56/?text=No+Image+Provided"
              label={category.name}
            />
          }
          isMobile
        />
      ))}
    </SimpleGrid>
  );

  return (
    <BottomSheet
      open={open}
      scrollLocking
      blocking
      onDismiss={onDismiss}
      maxHeight={(isMobile && isTablet) ? 350 : 450}
      expandOnContentDrag={false}
    >
      <OutsideAlerter onClickOutside={() => dispatch(hideBottomSheet())}>
        <Grid templateColumns="repeat(6, 1fr)" maxW="full">
          <GridItem colStart={3} colEnd={5} minW="140px">
            <Heading as="h4" size="lg" rounded="md" fontSize="18px" textAlign="center">All Categories</Heading>
          </GridItem>

          <GridItem colStart={6}>
            <IconButton
              aria-label="back"
              icon={<Icon as={RiArrowDownSLine} h="30px" w="30px" />}
              pb="15px"
              onClick={onDismiss}
              placeSelf="flex-start"
              bg="white"
              color="green"
              _focus={{ border: 'none' }}
              _active={{ border: 'none' }}
            />
          </GridItem>
        </Grid>
        {renderCategories()}
      </OutsideAlerter>
    </BottomSheet>
  );
};
export default ProductCategoryBottomSheet;
