import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from '@const/api';
import mobileNumberFormatter from '@utils/mobileNumberFormatter';
import { Promo } from '@sarisuki/promo/types/promo';

interface AvailablePromosOptions {
  mobile_number: string;
  order_amount: number;
}

export const getAvailablePromos = createAsyncThunk<Promo, AvailablePromosOptions>(
  'promo/getAvailablePromos',
  async (payload) => {
    const { mobile_number, ...restPayload } = payload;
    const generalizedMobileNumber = mobileNumberFormatter.generalizeMobileNumber(mobile_number);
    const response = await axios.post<any>('/promos/suki/available/', { mobile_number: generalizedMobileNumber, ...restPayload });
    return response.data;
  },
);

export const clearPromos = createAction(
  'promo/clearPromos',
);
