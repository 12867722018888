import { LocalStorageKeys } from '@const/keys';
import { createReducer } from '@reduxjs/toolkit';
import { CartLineItem } from '@sarisuki/cart/types/cartLineItem';
import log from '@utils/log';
import { CartErrors } from '@sarisuki/cart/types/cartErrors';
import { BackToSchoolRaffle } from '@sarisuki/cart/types/backToSchoolRaffle';
import { PaginatedResponse } from '@customTypes/paginatedResponse';
import {
  addToCart,
  checkCartForError,
  removeCart,
  clearAllCartItems,
  getCart,
  removeCartItem,
  setCartError,
  updateCartItem,
  computeRaffleEntries,
  createGroupBuyCart,
} from '.';
import { GroupBuyCart } from '../types/groupBuyCart';

export interface CartState {
  cartId: string;
  products: CartLineItem[] | null;
  is_checked_out: boolean | null;
  storeId: number | null;
  owner: any | null;
  isLoading: boolean;
  isClearLoading: boolean;
  total_amount: number;
  total_promo_items_amount: number;
  cart_error: CartErrors[] | null;
  error: any | null;
  backToSchoolRaffle: PaginatedResponse<BackToSchoolRaffle> | null;
  groupBuyCart: GroupBuyCart | null;
}

const initialState: CartState = {
  cartId: '',
  is_checked_out: null,
  products: [],
  storeId: null,
  owner: null,
  isLoading: false,
  isClearLoading: false,
  total_amount: 0,
  total_promo_items_amount: 0,
  cart_error: null,
  error: null,
  backToSchoolRaffle: null,
  groupBuyCart: null,
};

const cartReducer = createReducer(initialState, (builder) => {
  builder.addCase(removeCart, (state) => {
    localStorage.removeItem(`${LocalStorageKeys.CART_ID}_${state.storeId}`);

    state.cartId = '';
    state.products = [];
    state.owner = '';
    state.error = null;
    state.storeId = null;
    state.total_amount = 0;
    state.total_promo_items_amount = 0;
  });

  builder.addCase(getCart.pending, (state) => {
    state.isLoading = true;
    state.error = null;
  });
  builder.addCase(getCart.fulfilled, (state, action) => {
    const {
      id,
      line_items,
      owner,
      store,
      is_checked_out,
      total_amount,
      total_promo_items_amount,
    } = action.payload;
    state.cartId = id;
    state.is_checked_out = is_checked_out;
    state.products = line_items;
    state.owner = owner;
    state.storeId = store;
    state.total_amount = total_amount;
    state.total_promo_items_amount = total_promo_items_amount;
    state.isLoading = false;
  });
  builder.addCase(getCart.rejected, (state, action) => {
    state.isLoading = false;
    state.error = action.error.message;
    log({
      errorId: 'Cart Error',
      isMobile: false,
      error: action.error,
      info: '/getCart Error',
    });
  });

  builder.addCase(addToCart.fulfilled, (state, action) => {
    const {
      id,
      line_items,
      owner,
      store,
      total_amount,
      total_promo_items_amount,
    } = action.payload;
    state.cartId = id;
    state.products = line_items;
    state.owner = owner;
    state.storeId = store;
    state.total_amount = total_amount;
    state.total_promo_items_amount = total_promo_items_amount;
    state.isLoading = false;
  });
  builder.addCase(addToCart.pending, (state) => {
    state.isLoading = true;
    state.error = null;
  });
  builder.addCase(addToCart.rejected, (state, action) => {
    state.isLoading = false;
    log({
      errorId: 'Cart Error',
      isMobile: false,
      error: action.error,
      info: '/getCart Error',
    });
  });

  builder.addCase(computeRaffleEntries.fulfilled, (state, action) => {
    state.backToSchoolRaffle = action.payload;
    state.isLoading = false;
  });
  builder.addCase(computeRaffleEntries.pending, (state) => {
    state.isLoading = true;
    state.error = null;
  });
  builder.addCase(computeRaffleEntries.rejected, (state, action) => {
    state.isLoading = false;
    log({
      errorId: 'Cart Error',
      isMobile: false,
      error: action.error,
      info: '/computeRaffleEntries Error',
    });
  });

  builder.addCase(removeCartItem.fulfilled, (state, action) => {
    const {
      id,
      line_items,
      owner,
      store,
      total_amount,
      total_promo_items_amount,
    } = action.payload;
    state.cartId = id;
    state.products = line_items;
    state.owner = owner;
    state.storeId = store;
    state.total_amount = total_amount;
    state.total_promo_items_amount = total_promo_items_amount;
    state.isLoading = false;
  });

  builder.addCase(clearAllCartItems.fulfilled, (state, action) => {
    const {
      id,
      line_items,
      owner,
      store,
      total_amount,
      total_promo_items_amount,
    } = action.payload;
    state.cartId = id;
    state.products = line_items;
    state.owner = owner;
    state.storeId = store;
    state.total_amount = total_amount;
    state.total_promo_items_amount = total_promo_items_amount;
    state.isClearLoading = false;
  });
  builder.addCase(clearAllCartItems.pending, (state) => {
    state.isClearLoading = true;
    state.error = null;
  });

  builder.addCase(updateCartItem.fulfilled, (state, action) => {
    const {
      id,
      line_items,
      owner,
      store,
      total_amount,
      total_promo_items_amount,
    } = action.payload;
    state.cartId = id;
    state.products = line_items;
    state.owner = owner;
    state.storeId = store;
    state.total_amount = total_amount;
    state.total_promo_items_amount = total_promo_items_amount;
    state.isLoading = false;
  });

  builder.addCase(checkCartForError.fulfilled, (state, action) => {
    state.cart_error = action.payload;
    state.isLoading = false;
  });
  builder.addCase(checkCartForError.pending, (state) => {
    state.isLoading = true;
    state.error = null;
  });
  builder.addCase(checkCartForError.rejected, (state, action) => {
    state.isLoading = false;
    state.error = action.error.message;
    log({
      errorId: 'Cart Error',
      isMobile: false,
      error: action.error,
      info: '/checkCartForError Error',
    });
  });

  builder.addCase(setCartError, (state) => {
    state.cart_error = null;
    state.isLoading = false;
  });

  builder.addCase(createGroupBuyCart.fulfilled, (state, action) => {
    state.groupBuyCart = action.payload;
    state.isLoading = false;
  });
  builder.addCase(createGroupBuyCart.pending, (state) => {
    state.isLoading = true;
    state.error = null;
  });
  builder.addCase(createGroupBuyCart.rejected, (state, action) => {
    state.isLoading = false;
    state.error = action.error.message;
    log({
      errorId: 'Cart Error',
      isMobile: false,
      error: action.error,
      info: '/createGroupBuyCart Error',
    });
  });
});
export default cartReducer;
