import axios from 'axios';

const Log = async (data: {
    errorId: string;
    isMobile: boolean;
    error: { code?:any; message?: any };
    info?: any;
}) => {
    const location = typeof window !== 'undefined' ? window.location : JSON.stringify(data.info);
    const device = () => (data.isMobile ? 'Mobile' : 'Desktop');
    const stringErrorMessage = `{"id":"${data.errorId}", "date":"${new Date()}", "device":"${device()}", "location": ${location}, "error":${JSON.stringify(data.error)}, "info":${JSON.stringify(data.info)}}`;

    await axios.get(`/api/slack?message=${stringErrorMessage}`);
};
export default Log;
