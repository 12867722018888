import { VStack, Text, HStack } from '@chakra-ui/layout';

interface IProps {
  validUntil?: Date | null;
  discountValue: number;
  minimumOrderAmount: number | string;
}

const VoucherCard: React.FC<IProps> = (
  { validUntil, discountValue, minimumOrderAmount }: IProps,
) => {
  const BORDER_RADIUS = '6px';

  const formatMinimumOrderAmount = () => {
    const DEFAULT_BASE = 10;
    let amount = 0;

    if (typeof minimumOrderAmount === 'string') {
      amount = Math.trunc(parseInt(minimumOrderAmount, DEFAULT_BASE));
    } else {
      amount = Math.trunc(minimumOrderAmount);
    }

    const USNumberFormat = new Intl.NumberFormat('en-US');
    return USNumberFormat.format(amount);
  };

  return (
    <HStack h="105px" spacing="0">
      <VStack
        backgroundColor="sarisuki-green.500"
        h="full"
        width="79px"
        alignItems="center"
        justifyContent="center"
        borderStartRadius={BORDER_RADIUS}
        boxShadow="lg"
      >
        <Text color="white" fontSize="10.98px" ml="2">REFERRAL DISCOUNT</Text>
      </VStack>

      <VStack
        bg="white"
        h="full"
        width="168px"
        pl="3"
        spacing={0}
        alignItems="flex-start"
        justifyContent="center"
        borderEndRadius={BORDER_RADIUS}
        boxShadow="lg"
      >
        <Text color="gray.700" fontSize="14px" fontWeight="700">₱{Math.trunc(discountValue)} OFF!</Text>
        <Text color="gray.700" fontSize="10px">MIN. SPEND ₱{formatMinimumOrderAmount()}</Text>

        {validUntil &&
          <Text color="gray.700" fontSize="10px">
            Valid till {new Date(validUntil).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}
          </Text>
        }
      </VStack>
    </HStack>
  );
};

VoucherCard.defaultProps = { validUntil: null };
export default VoucherCard;
