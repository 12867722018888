import axios from '@const/api';
import { LocalStorageKeys } from '@const/keys';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { SignupFormData } from '@sarisuki/signup/types';
import mobileNumberFormatter from '@utils/mobileNumberFormatter';
import { AxiosResponse } from 'axios';
import { LoginFormData } from '@sarisuki/login/types';

interface LoginSukiOptions extends LoginFormData {}

interface LoginSukiResponse {
  key: string;
}

export interface SukiInfoOptions {
  first_name: string,
  last_name: string,
  birthdate?: Date | null,
  email?: string | null,
  address?: string,
  barangay?: string,
  city?: string,
  province?: string,
}

export const submitLoginForm = createAsyncThunk<LoginFormData, LoginFormData>(
  'login/submitLoginForm',
  async (formData) => formData,
);

export const loginSuki = createAsyncThunk<LoginSukiResponse | undefined, LoginSukiOptions>(
  'login/loginSuki',
  async ({ mobile_number, otp }) => {
    let response;
    try {
      const generalizedMobileNumber =
        mobileNumberFormatter.generalizeMobileNumber(mobile_number);

      response = await axios.post<LoginSukiOptions, AxiosResponse<LoginSukiResponse>>(
        '/users/suki/login/', {
          mobile_number: generalizedMobileNumber,
          otp,
        },
      );

      localStorage.setItem(LocalStorageKeys.USER_TOKEN, response.data.key);
    } catch (err: any) {
      if (err.response) {
        const { errors } = err.response.data;
        throw errors[0];
      }
    }

    return response?.data;
  },
  { serializeError: (error: any) => error },
);

export const isUserLoggedIn = ():boolean => !!window.localStorage.getItem('user_token');

export const logout = ():void => { window.localStorage.removeItem('user_token'); };

export const getSukiInfo = createAsyncThunk<any>(
  'login/getSukiInfo',
  async () => {
    const response = await axios({
      method: 'get',
      url: '/users/suki/info/',
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });

    return response.data;
  },
);

export const updateSukiInfo = createAsyncThunk<SignupFormData, SukiInfoOptions>(
  'login/updateSukiInfo',
  async (payload) => {
    if (!payload.email) {
      payload.email = null;
    }

    if (!payload.birthdate) {
      payload.birthdate = null;
    }

    const response = await axios({
      method: 'post',
      url: '/users/suki/info/update/',
      data: { ...payload },
      headers: {
        Authorization: `Token ${localStorage.getItem('user_token')}`,
      },
    });
    return response.data;
  },
);

export const verifyMobileNumber = createAsyncThunk<boolean, string>(
  'login/verifyMobileNumber',
  async (mobile_number) => {
    const response = await axios({
      method: 'post',
      url: '/users/suki/check-mobile-number/',
      data: { mobile_number: `${mobile_number}` },
    });
    return response.data;
  },
);

export const showEditAddressForm = createAction<boolean>(
  'login/showEditAddressForm',
);
