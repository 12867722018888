import 'react-spring-modal/styles.css';
import { ReactNode, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import StoreModal from '@sarisuki/store/components/StoreModal';
import StoreInfoModal from '@sarisuki/store/components/StoreInfoModal';
import CheckoutOOSErrorModal from '@sarisuki/checkout/components/CheckoutOOSErrorModal';
import CheckoutUnexpectedErrorModal from '@sarisuki/checkout/components/CheckoutUnexpectedErrorModal';
import HomeMapLoadingModal from '@sarisuki/home/components/HomeMapLoadingModal';
import ReferralUnlockVoucherModal from '@sarisuki/referral/components/ReferralUnlockVoucherModal';
import OTPSuccessModal from '@sarisuki/otp/components/OTPSuccessModal';
import ShareReferralCodeModal from '@sarisuki/referral/components/ShareReferralCodeModal';
import DifferentNewMobileNumberModal from '@sarisuki/checkout/components/DifferentNewMobileNumberModal';
import DifferentExistingMobileNumberModal from '@sarisuki/checkout/components/DifferentExistingMobileNumberModal';
import UpdateAccountInfoModal from '@sarisuki/checkout/components/UpdateAccountInfoModal';
import CheckoutAntiHoardingModal from '@sarisuki/checkout/components/CheckoutAntiHoardingModal';
import ProductShareLinkModal from '@sarisuki/products/components/ProductShareLinkModal';
import ProductBuyForYourselfModal from '@sarisuki/products/components/ProductBuyForYourselfModal';
import OrderHistoryOrderAgainModal from '@sarisuki/orderHistory/templates/shared/OrderHistoryOrderAgainModal';
import OrderHistoryNoItemsAddedModal from '@sarisuki/orderHistory/templates/shared/OrderHistoryNoItemsAddedModal';
import OrderHistoryCLModal from '@sarisuki/orderHistory/templates/shared/OrderHistoryCLModal';
import FilterSortExitModal from '@components/filterSort/templates/tablet/FilterSortExitModal';
import ProductBuyWithAGroupModal from '@components/products/templates/desktop/ProductBuyWithAGroupModal';
import ProductShareGroupBuyModal from '@components/products/templates/shared/ProductShareGroupBuyModal';

import { hideModal, selectModal } from './modalSlice';

/**
 * This component will handle all logic
 * related to showing and hiding dialog
 * boxes in the screen
 */
const ModalManager: React.FC = () => {
  const { isVisible, modalProps, modalType } = useAppSelector(selectModal);
  const dispatch = useAppDispatch();

  const handleOnDismiss = () => {
    dispatch(hideModal());
  };
  /**
   * In order to properly handle the change
   * of state we need to memoize the callback
   * method for rendering the modals.
   */
  const renderModal = useCallback<() => ReactNode | null>(() => {
    switch (modalType) {
      case 'store-info-modal':
        return (
          <StoreInfoModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'store-modal':
        return (
          <StoreModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'out-of-stock-error-modal':
        return (
          <CheckoutOOSErrorModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'unexpected-error-modal':
        return (
          <CheckoutUnexpectedErrorModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'anti-hoarding-error-modal':
        return (
          <CheckoutAntiHoardingModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'homemap-loading-modal':
        return (
          <HomeMapLoadingModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'unlock-voucher-modal':
        return (
          <ReferralUnlockVoucherModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'share-referral-code-modal':
        return (
          <ShareReferralCodeModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'otp-success-modal':
        return (
          <OTPSuccessModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'different-new-mobile-number-modal':
          return (
            <DifferentNewMobileNumberModal
              open={isVisible}
              onDismiss={handleOnDismiss}
              {...modalProps}
            />
          );
      case 'different-existing-mobile-number-modal':
        return (
          <DifferentExistingMobileNumberModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'update-account-info-modal':
        return (
          <UpdateAccountInfoModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'message-cl-modal':
        return (
          <OrderHistoryCLModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'order-again-modal':
        return (
          <OrderHistoryOrderAgainModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'no-items-added-modal':
        return (
          <OrderHistoryNoItemsAddedModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'product-share-link-modal':
        return (
          <ProductShareLinkModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'buy-for-yourself-modal':
        return (
          <ProductBuyForYourselfModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'filter-sort-exit-modal':
        return (
          <FilterSortExitModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'buy-with-a-group-modal':
        return (
          <ProductBuyWithAGroupModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      case 'buy-share-group-buy-modal':
        return (
          <ProductShareGroupBuyModal
            open={isVisible}
            onDismiss={handleOnDismiss}
            {...modalProps}
          />
        );
      default:
        return null;
    }
  }, [isVisible, modalType]);

  return <>{renderModal()}</>;
};

export default ModalManager;
