import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Text } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { VStack, HStack, Spacer, Divider, IconButton, Icon, Button, useNumberInput, Input, Box } from '@chakra-ui/react';
import { MdOutlineClose } from 'react-icons/md';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import numberFormatter from '@utils/numberFormatter';
import { addToCart, selectCart } from '@sarisuki/cart/cartSlice';
import { isUserLoggedIn } from '@sarisuki/login/loginSlice';
import { Mixpanel } from '@sarisuki/store/components/Mixpanel';
import { selectProductCategories } from '@sarisuki/productCategories/productCategoriesSlice';
import { useTabletMediaQuery } from '@hooks/responsive_hook';
import { selectProducts } from '../productsSlice';

interface IProps extends BaseModalProps {}
const ProductBuyForYourselfModal: React.FC<IProps> = ({ open, onDismiss }: IProps) => {
  const { product } = useAppSelector(selectProducts);
  const isTablet = useTabletMediaQuery();
  const { category } = useAppSelector(selectProductCategories);
  const { cartId, isLoading } = useAppSelector(selectCart);
  const dispatch = useAppDispatch();
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
  useNumberInput({
    step: 1,
    defaultValue: 1,
    min: 1,
    max: product?.available_stock_qty || 0,
    precision: 0,
  });
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  const [totalAmount, setTotalAmount] = useState('');
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (input['aria-valuenow']) {
      setTotalItems(input['aria-valuenow']);
      if (product?.total_price) {
        setTotalAmount(numberFormatter.formatNumberToPHCurrency(product?.total_price * input['aria-valuenow']));
      }
    }
  }, [input['aria-valuenow']]);

  const onAddToCartClick = async () => {
    if (!product) return;
    await dispatch(addToCart({ cart_id: cartId, product_id: product.id, quantity: totalItems }));

    Mixpanel.track('Add to Cart', {
      ...isUserLoggedIn() && { user_id: window.localStorage.getItem('user_token') },
      ...{
          'Product SKU': product.sku,
          'Product Name': product.name,
          // eslint-disable-next-line quote-props
          'SRP': product.original_price,
          'Product Category': category?.name,
          'Product Subcategory': category?.subcategories,
          'Entry Point': 'View Product Details',
        },
    });
  };

  return (
    <Modal isCentered isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent closeOnOverlayClick={false} maxW={isTablet ? '614px' : '1194px'} rounded="xl">
        <VStack pt="20px" px={0} w="100%" pb="72px" spacing={0}>
          <HStack w="100%" px="40px" pb={3}>
            {!isTablet &&
              <Text fontWeight={700} fontSize="20px" color="gray.900">Buy for yourself</Text>
            }
            <Spacer />
            <IconButton
              onClick={onDismiss}
              aria-label="close button"
              icon={<Icon as={MdOutlineClose} boxSize={isTablet ? 10 : 6} color="gray.500" />}
              bg="white"
              _hover={{ border: 'none' }}
              _active={{ border: 'none' }}
              _focus={{ border: 'none' }}
            />
          </HStack>
          {!isTablet && <Divider />}

          <VStack pt={isTablet ? '5px' : '40px'} w="100%" px={isTablet ? '48px' : '202px'} spacing={0}>
            <Text w="100%" fontWeight={500} fontSize="20px" color="black-pearl">{product?.name}</Text>
            <Text w="100%" fontWeight={600} fontSize="16px" color="gray.500">{product?.unit_of_measure}</Text>
            <HStack spacing={0} placeSelf="flex-start" pt={product?.on_sale ? 0 : 5}>
              <Text w="100%" fontWeight={700} fontSize="18px" color="sarisuki-green.500">₱</Text>
              <Text w="100%" fontWeight={700} fontSize="24px" color="sarisuki-green.500">{numberFormatter.formatNumberToPHCurrency(product?.total_price ?? 0).substring(1)}</Text>
              {product?.on_sale &&
                <>
                  <Text pl="16px" pr={4} w="100%" as="s" fontWeight={500} fontSize="20px" color="gray.400">{numberFormatter.formatNumberToPHCurrency(product?.original_price ?? 0)}</Text>
                  <Box>
                    <Text
                      w="80px"
                      h="80px"
                      rotate="15"
                      transform="auto"
                      textAlign="center"
                      className="big-on-sale-bg-green"
                      fontWeight={700}
                      fontSize="24px"
                      color="sarisuki-green.500"
                      spacing={0}
                      pt={3}
                    >
                      {Math.round(((
                        product?.original_price - product?.total_price)
                        / product?.original_price) * 100,
                      )}%
                      <Text fontWeight={500} fontSize="24px" color="sarisuki-green.500" mt="-10px">OFF</Text>
                    </Text>
                  </Box>
                </>
              }
            </HStack>

            <HStack w="100%" pb={3}>
              <Text textAlign="left" w="100%" fontWeight={400} fontSize="20px" color="gray.700">Quantity</Text>

              <HStack maxW="198px" maxH={isTablet ? '58px' : '48px'} spacing={0} border="1px" borderColor="gray.100" p={2} borderRadius="32px">
                <Button
                  {...dec}
                  bg="white"
                  p={0}
                  _hover={{ bg: 'white', border: 'none' }}
                  _active={{ bg: 'white', border: 'none' }}
                  _focus={{ bg: 'white', border: 'none' }}
                >
                  <Icon as={BiMinus} boxSize={7} color="orange.500" />
                </Button>
                <Input
                  {...input}
                  border="none"
                  textAlign="center"
                  color="gray.600"
                  fontWeight={700}
                  fontSize="24px"
                  _focus={{ boxShadow: 'none' }}
                />
                <Button
                  {...inc}
                  bg="white"
                  p={0}
                  _hover={{ bg: 'white', border: 'none' }}
                  _active={{ bg: 'white', border: 'none' }}
                  _focus={{ bg: 'white', border: 'none' }}
                >
                  <Icon as={BiPlus} boxSize={7} color="orange.500" />
                </Button>
              </HStack>
            </HStack>

            <Button
              w="100%"
              h={isTablet ? '66px' : '56px'}
              py="16px"
              bg="sarisuki-green.500"
              fontWeight={700}
              fontSize="20px"
              color="white"
              _hover={{ bg: 'sarisuki-green.500', border: 'none' }}
              _active={{ bg: 'sarisuki-green.500', border: 'none' }}
              _focus={{ bg: 'sarisuki-green.500', border: 'none' }}
              onClick={onAddToCartClick}
              isLoading={isLoading}
            >
              Add to cart ({totalItems} {totalItems === 1 ? 'item' : 'items'}): {totalAmount}
            </Button>
            {totalItems === product?.available_stock_qty &&
              <Text fontWeight={600} fontSize="16px" color="red.500" pt={2}>You have reached the maximum order limit of this item.</Text>
            }
          </VStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
export default ProductBuyForYourselfModal;
