import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { VStack, Text, Container } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';
import VerificationSuccessIcon from '@assets/icons/verification-success.svg';
import { Icon } from '@chakra-ui/react';

interface IProps extends BaseModalProps {}

const OTPSuccessModal: React.FC<IProps> = ({ open, onDismiss }: IProps) => (
  <Modal isCentered isOpen={open} onClose={onDismiss}>
    <ModalOverlay />
    <ModalContent
      closeOnOverlayClick={false}
      rounded="xl"
      overflow="hidden"
      mx="4"
      w={['full', '360px']}
    >
      <Container w="full" px="0">
        <VStack h="164px" justifyContent="center" alignItems="center" spacing="24px">
          <Icon as={VerificationSuccessIcon} width="38px" height="45px" />
          <Text fontSize="20px" fontWeight="bold" textAlign="center">
            Verification successful!
          </Text>
        </VStack>
      </Container>
    </ModalContent>
  </Modal>
);

export default OTPSuccessModal;
