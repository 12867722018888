import { MIXPANEL_KEY } from '@const/api';
import mixpanel, { Dict } from 'mixpanel-browser';

mixpanel.init(MIXPANEL_KEY!);

const actions = {
  track: (name: string, props?: Dict | undefined) => {
    mixpanel.track(name, props);
  },
  identify: (identifier: string) => {
    mixpanel.identify(identifier);
  },
};

export const Mixpanel = actions;
