import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Heading, Text, VStack } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';
import { FC } from 'react';

interface IProps extends BaseModalProps {}
const CheckoutUnexpectedErrorModal: FC<IProps> = ({ open, onDismiss }: IProps) => (
  <Modal isOpen={open} onClose={onDismiss}>
    <ModalOverlay />
    <ModalContent>
      <ModalBody>
        <VStack align="center" py="48px" px="33px">
          <Heading
            maxWidth="264px"
            as="h5"
            fontWeight="bold"
            textColor="gray.900"
            textAlign="center"
            fontSize="20px"
            lineHeight="24px"
          >
            An unexpected error happened while processing your order.
          </Heading>
          <Text
            as="span"
            textColor="gray.60"
            textAlign="center"
            maxWidth="321px"
            fontSize="14px"
            lineHeight="20px"
          >
            Please try again in a few seconds.
          </Text>
        </VStack>
      </ModalBody>
    </ModalContent>
  </Modal>
);
export default CheckoutUnexpectedErrorModal;
