import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Heading, VStack, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { Avatar } from '@chakra-ui/avatar';
import { BaseModalProps } from '@sarisuki/modal/types';
import StoreHeaderBG from '@assets/images/store-bg.png';
import { useAppSelector } from '@app/hooks';
import { selectStore } from '@sarisuki/store/storeSlice';
import { useEffect, useState } from 'react';
import { HStack, Icon, Link, ModalCloseButton, Spacer } from '@chakra-ui/react';
import mobileNumberFormatter from '@utils/mobileNumberFormatter';
import { FiCopy } from 'react-icons/fi';
import { getMonth } from '@customTypes/getMonth';
import storeSchedule from '@utils/storeSchedule';
import storeScheduleFormatter from '@utils/storeScheduleFormatter';

interface IProps extends BaseModalProps { }
const StoreInfoModal: React.FC<IProps> = ({ open, onDismiss }: IProps) => {
  const { storeName, emoji, storeCL } = useAppSelector(selectStore);
  const store = useAppSelector(selectStore);
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const TEXT_DISPLAY_WAIT_TIME = 3000;
  const [storeCLName, setStoreCLName] = useState('');
  const [storeCLMobileNumber, setStoreCLMobileNumber] = useState('');
  const [storeCLAddress, setStoreCLAddress] = useState('');
  const [formattedInactiveDays] = useState('');
  const [formattedActiveDays, setFormattedActiveDays] = useState('');
  const [dateCreated, setDateCreated] = useState('');
  const activeDays = storeSchedule.getActiveDays(store.data?.schedule);

  useEffect(() => {
    if (storeCL?.first_name && storeCL.last_name) {
      setStoreCLName(`${storeCL.first_name} ${storeCL.last_name.charAt(0)}.`);
    }

    if (storeCL?.cl_info?.mobile_number) {
      setStoreCLMobileNumber(mobileNumberFormatter.internationalMobileNumber(
        storeCL?.cl_info?.mobile_number),
      );
    }

    if (storeCL?.cl_info?.address && storeCL.cl_info.barangay && storeCL.cl_info.city) {
      setStoreCLAddress(`${storeCL.cl_info.address}, ${storeCL.cl_info.barangay}, ${storeCL.cl_info.city}`);
    }

    if (store.data?.created_at) {
      const date = new Date(store.data?.created_at);
      const month = getMonth(date.toString(), 'short');
      const year = date.getFullYear();
      setDateCreated(`${month} ${year}`);
    }

    setFormattedActiveDays(storeScheduleFormatter(activeDays));
  }, []);

  const onCopyButtonClick = () => {
    if (storeCL?.cl_info?.mobile_number) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(
          mobileNumberFormatter.generalizeMobileNumber(storeCL?.cl_info?.mobile_number),
        );
      } else {
        console.error('Note to developers: you need HTTPS for this to work.');
      }
      setCopyButtonText('Copied!');
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => { setCopyButtonText('Copy'); }, TEXT_DISPLAY_WAIT_TIME);
    return () => clearTimeout(timer);
  }, [copyButtonText]);

  return (
    <Modal isCentered isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent rounded="md" overflow="hidden" w="700px">
        <ModalCloseButton color="green.500" bg="white" borderRadius={100} _hover={{ bg: 'white' }} />
        <Image objectFit="cover" h="258px" src={StoreHeaderBG.src} alt="store-logo" />
        <Avatar icon={<span role="img">{emoji}</span>} bg="white" placeSelf="flex-start" mt="-20px" ml="30px" />

        <VStack w="full" px={10} spacing={0} pt={2} pb={5}>
          <Heading w="100%" textAlign="left" color="gray.900" fontWeight={700} fontSize="24px">{storeName}</Heading>
          <Text fontWeight={400} pt={4} fontSize="12px" w="100%" textAlign="left" color="gray.60">
            Managed by your Community Leader
          </Text>
          <Text fontWeight={400} fontSize="16px" w="100%" textAlign="left" color="gray.700">{storeCLName}</Text>

          <HStack w="100%">
            <Text fontWeight={400} fontSize="16px" textAlign="left" color="gray.700" pr={3} py={1}>
              {storeCLMobileNumber}
            </Text>

            <HStack as="button" onClick={onCopyButtonClick} _focus={{ bg: 'white' }} _hover={{ bg: 'white' }}>
              <Icon as={FiCopy} boxSize={4} color="green.600" bg="white" />
              <Text fontWeight={600} fontSize="16px" w="100%" textAlign="left" color="green.500">
                {copyButtonText}
              </Text>
            </HStack>
          </HStack>

          <HStack w="100%">
            <Text fontWeight={400} fontSize="16px" textAlign="left" w="100%" color="gray.700" isTruncated>
              {storeCLAddress}
            </Text>
            <Link
              href={`http://maps.google.com/maps?q=${storeCL?.cl_info?.latitude},${storeCL?.cl_info?.longitude}&z=30`}
              isExternal
              fontWeight={600}
              fontSize="16px"
              w="100%"
              textAlign="right"
              color="green.500"
              _focus={{ border: 'none' }}
              _hover={{ border: 'none' }}
              _active={{ border: 'none' }}
            >
              Open in Google Maps
            </Link>
          </HStack>

          <HStack w="100%" pt={5}>
            <Text fontWeight={400} fontSize="12px" w="100%" textAlign="left" color="gray.60">
              Since
            </Text>
            <Spacer />
            <Text fontWeight={400} fontSize="12px" w="100%" textAlign="left" color="gray.60">
              Suki customers
            </Text>
          </HStack>

          <HStack w="100%" pb={5}>
            <Text fontWeight={400} fontSize="16px" w="100%" textAlign="left" color="gray.700">
              {dateCreated}
            </Text>
            <Text fontWeight={400} fontSize="16px" w="100%" textAlign="left" pl={2} color="gray.700">
              {store?.data?.valid_suki ?? 0}
            </Text>
          </HStack>

          {(formattedActiveDays || formattedInactiveDays) &&
            <Text fontWeight={400} fontSize="12px" w="100%" textAlign="left" color="gray.60">
              Schedule
            </Text>
          }

          {formattedActiveDays &&
            <Text fontWeight={400} fontSize="16px" w="100%" textAlign="left" color="gray.700">
              Order every {formattedActiveDays}
            </Text>
          }

          {formattedInactiveDays &&
            <Text fontWeight={400} fontSize="16px" w="100%" textAlign="left" color="gray.700">
              Pickup or delivery every {formattedInactiveDays}
            </Text>
          }
        </VStack>
      </ModalContent>
    </Modal>
  );
};
export default StoreInfoModal;
