import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { VStack, Text, HStack, Spacer, Container, Box } from '@chakra-ui/layout';
import { Center } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { BaseModalProps } from '@sarisuki/modal/types';
import { selectStore } from '@sarisuki/store/storeSlice';
import UnlockVoucherBackground from '@assets/backgrounds/unlock-voucher.png';
import { getVoucherConfig, selectReferral } from '@sarisuki/referral/referralSlice';
import VoucherCard from './VoucherCard';

interface IProps extends BaseModalProps {}

const ReferralUnlockVoucherModal: React.FC<IProps> = ({ open, onDismiss }: IProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { storeUrl } = useAppSelector(selectStore);
  const { voucherConfig } = useAppSelector(selectReferral);
  const { discount_value, minimum_order_amount } = voucherConfig;

  useEffect(() => {
    dispatch(getVoucherConfig('FOR_REFERRAL'));
  }, []);

  const onClose = () => {
    onDismiss();
    router.replace(`/${storeUrl}/`);
  };

  return (
    <Modal isCentered isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        closeOnOverlayClick={false}
        rounded="xl"
        overflow="hidden"
        mx="4"
        w={['full', '360px']}
      >
        <Container w="full" px="0">
          <VStack
            spacing="0"
            position="relative"
            w="full"
            alignItems="center"
            justifyContent="center"
          >
            <HStack minW="full">
              <Spacer />
              <ModalCloseButton />
            </HStack>

            <Box
              w="full"
              h="200px"
              backgroundColor="cyan.50"
              pb="34px"
              pt="50px"
              borderRadius="0px 0px 0px 0px"
              backgroundImage={UnlockVoucherBackground.src}
            >
              <Center>
                <VoucherCard
                  discountValue={discount_value}
                  minimumOrderAmount={minimum_order_amount}
                />
              </Center>
            </Box>

            <Text
              as="h5"
              py="22px"
              px="31px"
              fontFamily="Inter"
              fontWeight={['600', 'bold']}
              fontSize={['16px', '20px']}
              lineHeight="24px"
              textAlign="center"
            >
              Awesome! You&apos;ve unlocked a discount voucher!
            </Text>
          </VStack>
        </Container>
      </ModalContent>
    </Modal>
  );
};
export default ReferralUnlockVoucherModal;
