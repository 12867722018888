import { createReducer } from '@reduxjs/toolkit';
import { FilterBrandOptions } from '@sarisuki/filter/type/FilterBrandOptions';
import { FilterOptions } from '@sarisuki/filter/type/FilterOptions';
import { filterByPriceOptions } from '@sarisuki/filter/type/FilterByPriceOptions';
import { BrandOption } from '@components/filterSort/type/BrandOption';

import {
    setIsSwitchChecked,
    setIsSwitchCheckedAndFilter,
    setFilterOptions,
    setFilterOptionsBySubcategory,
    setFilterOptionsBuyAgain,
    setFilterByBrandVisibility,
    clearFilterByBrandSelection,
    clearFilterByPriceSelection,
    updateFilterBrandCheck,
    updateFilterPriceCheck,
    setFilterByPriceOptions,
    updateFilterByPriceOptions,
    setIsFilteringPrice,
    setIsFilteringBrand,
} from './actions';

export interface FilterState {
    isSwitchChecked: boolean;
    isBuyAgainSelected: boolean;
    filterByBrandOptions: FilterBrandOptions | null;
    filterByPriceOptions: FilterOptions | null;
    isFilterByPriceVisible: boolean;
    isFilterByBrandVisible: boolean;
    isFilteringBrand: boolean;
    isFilteringPrice: boolean;
    selectedCategoryId: number;
    selectedSubcategoryId: number | null;
    brandOptions: BrandOption[];
}

const initialState: FilterState = {
    isSwitchChecked: false,
    isBuyAgainSelected: false,
    filterByBrandOptions: null,
    filterByPriceOptions: null,
    isFilterByPriceVisible: false,
    isFilterByBrandVisible: false,
    isFilteringBrand: false,
    isFilteringPrice: false,
    selectedCategoryId: 0,
    selectedSubcategoryId: null,
    brandOptions: [],
};

const filterReducer = createReducer(initialState, (builder) => {
    builder.addCase(setIsSwitchChecked, (state, action) => {
        state.isSwitchChecked = action.payload;
    })
    .addCase(setIsSwitchCheckedAndFilter, (state, action) => {
        state.isSwitchChecked = action.payload;
        state.isFilteringBrand = true;
    })
    .addCase(setIsFilteringPrice, (state, action) => {
        state.isFilteringPrice = action.payload;
    })
    .addCase(setIsFilteringBrand, (state, action) => {
        state.isFilteringBrand = action.payload;
    })
    .addCase(setFilterOptions.fulfilled, (state, action) => {
        /* Retrieves all brands from a particular Category and filters out all null brands */
        const { categoryId, subcategoryId, brands } = action.payload;
        const brandOptions = brands.map((brandOption, index) => (
            { id: index, ...brandOption }
        )).filter((brandOption) => brandOption.brand !== null);

        /* For new filter implementation */
        state.brandOptions = brands.map(({ brand }) => (
            { label: brand, value: brand, isSelected: false }
        )).filter((brandOption) => brandOption.value !== null);

        state.selectedCategoryId = categoryId;
        state.selectedSubcategoryId = subcategoryId;
        state.isBuyAgainSelected = false;
        state.isFilterByBrandVisible = (brandOptions.length > 1);

        state.filterByBrandOptions = Object.values(brandOptions)
        .reduce((collector, brandOption) => (
            { ...collector,
                    [brandOption.id]: {
                        id: brandOption.id,
                        isChecked: false,
                        value: brandOption.brand,
                    },
            }
        ), {});
    })
    .addCase(setFilterOptions.rejected, (state) => {
        state.isFilterByBrandVisible = false;
    })
    .addCase(setFilterOptionsBySubcategory.fulfilled, (state, action) => {
        /* Retrieves all brands from a particular Category and filters out all null brands */
        const { categoryId, subcategoryId, brands } = action.payload;
        const brandOptions = brands.map((brandOption, index) => (
            { id: index, ...brandOption }
        )).filter((brandOption) => brandOption.brand !== null);

        state.selectedCategoryId = categoryId;
        state.selectedSubcategoryId = subcategoryId;
        state.isBuyAgainSelected = false;
        state.isFilterByBrandVisible = (brandOptions.length > 1);

        state.filterByBrandOptions = Object.values(brandOptions)
        .reduce((collector, brandOption) => (
            { ...collector,
                    [brandOption.id]: {
                        id: brandOption.id,
                        isChecked: false,
                        value: brandOption.brand,
                    },
            }
        ), {});
    })
    .addCase(setFilterOptionsBySubcategory.rejected, (state) => {
        state.isFilterByBrandVisible = false;
    })
    .addCase(setFilterOptionsBuyAgain.fulfilled, (state, action) => {
        /* Retrieves all brands from the "Buy Again" category */
        const brands = action.payload;
        const brandOptions = brands.map((brandOption, index) => (
            { id: index, ...brandOption }
        )).filter((brandOption) => brandOption.brand !== null);

        state.isBuyAgainSelected = true;
        state.isFilterByBrandVisible = (brandOptions.length > 1);

        state.filterByBrandOptions = Object.values(brandOptions)
        .reduce((collector, brandOption) => (
            { ...collector,
                    [brandOption.id]: {
                        id: brandOption.id,
                        isChecked: false,
                        value: brandOption.brand,
                    },
            }
        ), {});
    })
    .addCase(setFilterOptionsBuyAgain.rejected, (state) => {
        state.isFilterByBrandVisible = false;
    })
    .addCase(updateFilterPriceCheck, (state, action) => {
        const i = action.payload;
        state.isFilteringBrand = true;

        if (state.filterByPriceOptions) {
            state.filterByPriceOptions = {
                ...state.filterByPriceOptions,
                [i]: {
                    ...state.filterByPriceOptions[i],
                    isChecked: !state.filterByPriceOptions[i].isChecked,
                },
            };
        }
    })
    .addCase(updateFilterBrandCheck, (state, action) => {
        const i = action.payload;
        state.isFilteringBrand = true;

        if (state.filterByBrandOptions) {
            state.filterByBrandOptions = {
                ...state.filterByBrandOptions,
                [i]: {
                    ...state.filterByBrandOptions[i],
                    isChecked: !state.filterByBrandOptions[i].isChecked,
                },
            };
        }
    })
    .addCase(clearFilterByBrandSelection, (state) => {
        if (state.filterByBrandOptions) {
            state.filterByBrandOptions = Object.values(state.filterByBrandOptions)
            .reduce((collector, brandOption) => (
                { ...collector,
                        [brandOption.id]: {
                            ...brandOption,
                            isChecked: false,
                        },
                }
            ), {});
        }
    })
    .addCase(clearFilterByPriceSelection, (state) => {
        if (state.filterByPriceOptions) {
            state.filterByPriceOptions = Object.values(state.filterByPriceOptions)
            .reduce((collector, priceOption) => (
                { ...collector,
                        [priceOption.id]: {
                            ...priceOption,
                            isChecked: false,
                        },
                }
            ), {});
        }
    })
    .addCase(setFilterByBrandVisibility, (state, action) => {
        const containsMoreThanOneBrand = Object.values(state.filterByBrandOptions ?? {}).length > 1;
        state.isFilterByBrandVisible = action.payload && containsMoreThanOneBrand;
    })
    .addCase(setFilterByPriceOptions, (state, action) => {
        const products = action.payload;

        state.filterByPriceOptions = Object.values(filterByPriceOptions)
            .reduce((collector, priceOption) => {
                const toIncludeOption = products.some(priceOption.filter);
                if (toIncludeOption) {
                    return { ...collector,
                        [priceOption.id]: priceOption,
                    };
                }
                return collector;
            }, {});

        const numPriceOptions = Object.values(state.filterByPriceOptions ?? {}).length;
        state.isFilterByPriceVisible = (numPriceOptions > 1);
    })
    .addCase(updateFilterByPriceOptions, (state, action) => {
        const products = action.payload;

        state.filterByPriceOptions = Object.values(filterByPriceOptions)
            .reduce((collector, priceOption) => {
                const toIncludeOption = products.some(priceOption.filter);
                if (toIncludeOption) {
                    return { ...collector,
                        [priceOption.id]: priceOption,
                    };
                }
                return collector;
            }, state.filterByPriceOptions);

        const numPriceOptions = Object.values(state.filterByPriceOptions ?? {}).length;
        state.isFilterByPriceVisible = (numPriceOptions > 1);
    });
});

export default filterReducer;
