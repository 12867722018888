import { createAction } from '@reduxjs/toolkit';
import { BaseBottomSheetProps } from '@sarisuki/bottomSheet/types';

export const showBottomSheet = createAction<{
  bottomSheetType: string;
  bottomSheetProps?: BaseBottomSheetProps; }>(
  'bottomSheet/showBottomSheet',
);

export const hideBottomSheet = createAction(
  'bottomSheet/hideBottomSheet',
);
