import { Flex, HStack, Spacer, Text, VStack } from '@chakra-ui/layout';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { BaseBottomSheetProps } from '@sarisuki/bottomSheet/types';
import React, { useEffect } from 'react';
import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { Product } from '@sarisuki/products/type/product';
import {
  setProducts,
  getProductsByBrands,
  selectUnfilteredProducts,
  revertToUnfilteredProducts,
  getBuyAgainProductsByBrands,
} from '@sarisuki/products/productsSlice';
import { BsChevronLeft } from 'react-icons/bs';
import { Icon } from '@chakra-ui/react';
import { selectStore, setSearchingState } from '@sarisuki/store/storeSlice';
import {
  selectFilter,
  setIsFilteringBrand,
  setIsFilteringPrice,
  updateFilterBrandCheck,
  updateFilterPriceCheck,
  clearFilterByBrandSelection,
  clearFilterByPriceSelection,
} from '@sarisuki/filter/filterSlice';
import FilterByAvailabilty from './FilterByAvailabilty';

interface IProps extends BaseBottomSheetProps {}

const FilterBottomSheet = ({ open, onDismiss }: IProps) => {
  const { products } = useAppSelector(selectUnfilteredProducts);
  const { storeUrl } = useAppSelector(selectStore);
  const {
    isBuyAgainSelected,
    filterByPriceOptions,
    filterByBrandOptions,
    isSwitchChecked,
    isFilterByPriceVisible,
    isFilterByBrandVisible,
    isFilteringPrice,
    isFilteringBrand,
    selectedCategoryId,
    selectedSubcategoryId,
  } = useAppSelector(selectFilter);
  const dispatch = useAppDispatch();

  /* Contains the filter by price logic whether to display or not a product */
  const applyPriceFilters = (item: any) => {
    const applicableFilterByPriceFunctions = filterByPriceOptions ? (
      Object.values(filterByPriceOptions).filter((f) => f.isChecked).map((f) => f.filter)
    ) : (
      []
    );

    const numPriceFilters = applicableFilterByPriceFunctions.length;

    if (numPriceFilters === 0) {
      return true;
    }

    const toIncludeBasedOnPrice = applicableFilterByPriceFunctions.reduce(
      (toInclude, currentFilter) =>
      toInclude || currentFilter(item),
    false);

    return toIncludeBasedOnPrice;
  };

  /* Processes the filtering by availability and price activity */
  const onFilterPriceActivate = async () => {
    /* Filter by Availability */
    const filteredByAvailability = isSwitchChecked ? (
      products.results.slice().filter((f) => f.available_stock_qty > 0)
    ) : (
      products.results.slice()
    );

    /* Set products if filter price options are still null */
    if (!filterByPriceOptions) {
      await dispatch(
        setProducts({
          count: filteredByAvailability.length,
          limit: 50,
          next: '',
          offset: 0,
          previous: '',
          results: filteredByAvailability,
        }),
      );

      dispatch(setIsFilteringPrice(false));
      return;
    }

    /* Filter by price */
    const indexedFilteredByPrices = filteredByAvailability.filter(applyPriceFilters);
    const filteredByPrices: Product[] = Object.values(indexedFilteredByPrices);

    /* Set products */
    await dispatch(
      setProducts({
        count: filteredByPrices.length,
        limit: 50,
        next: '',
        offset: 0,
        previous: '',
        results: filteredByPrices,
      }),
    );

    dispatch(setIsFilteringPrice(false));
  };

  /* Processes the filtering by brand activity */
  const onFilterBrandActivate = async () => {
    const brands = filterByBrandOptions !== null ? (
      Object.values(filterByBrandOptions).filter((f) => f.isChecked).map((f) => f.value)
    ) : (
      []
    );

    if (!isBuyAgainSelected) {
      await dispatch(
        getProductsByBrands({
          storeUrl,
          categoryId: selectedCategoryId,
          subcategoryId: selectedSubcategoryId,
          brands,
        }),
      );
    } else {
      await dispatch(
        getBuyAgainProductsByBrands({
          storeUrl,
          brands,
        }),
      );
    }

    dispatch(setIsFilteringBrand(false));
    dispatch(setIsFilteringPrice(true));
  };

  /* Activates whenever 'isFilteringPrice' is changed to TRUE */
  useEffect(() => {
    if (isFilteringPrice) {
      onFilterPriceActivate();
    }
  }, [isFilteringPrice]);

  /* Activates whenever 'isFilteringBrand' is changed to TRUE */
  useEffect(() => {
    if (isFilteringBrand) {
      if (isFilterByBrandVisible) {
        onFilterBrandActivate();
      } else {
        dispatch(revertToUnfilteredProducts());
        dispatch(setIsFilteringBrand(false));
        dispatch(setIsFilteringPrice(true));
      }
    }
  }, [isFilteringBrand]);

  /* Updates filterByPrice options when one of its filter is checked or unchecked */
  const onFilterPriceSelect = (i: number) => {
    dispatch(updateFilterPriceCheck(i));
  };

  /* Updates filterByBrand options when one of its filter is checked or unchecked */
  const onFilterBrandSelect = (i: number) => {
    dispatch(updateFilterBrandCheck(i));
    dispatch(setSearchingState(false));
  };

  const determineIsFilterChecked = async () => {
    let isFilterChecked = false;

    if (filterByBrandOptions) {
      const isFilterBrandChecked = await Object.values(filterByBrandOptions)
        .filter((f) => f.isChecked);
        isFilterChecked = isFilterBrandChecked.length !== 0;
    }

    if (filterByPriceOptions) {
      const isFilterPriceChecked = await Object.values(filterByPriceOptions)
        .filter((f) => f.isChecked);
        isFilterChecked = isFilterChecked || isFilterPriceChecked.length !== 0;
    }

    return isFilterChecked;
  };

  const onClearAllFilters = async () => {
    const isFilterChecked = await determineIsFilterChecked();
    await dispatch(clearFilterByPriceSelection());
    await dispatch(clearFilterByBrandSelection());
    if (isFilterChecked) {
      dispatch(revertToUnfilteredProducts());
    }

    onDismiss();
  };

  const renderFilter = () => (
    <Flex alignItems="left" mx="16px" my="16px" minH="400px">
      <VStack minW="full" alignItems="left" maxH="70vh">
        <Icon
          as={BsChevronLeft}
          onClick={onDismiss}
          boxSize={5}
        />
        { /* Render Filter by Availability */ }
        <HStack pb="0">
          <Text fontSize="16px" fontWeight="bold" ml="6px" color="gray.900">
            Filter by availability
          </Text>
          <Spacer />
          <Button
            onClick={onClearAllFilters}
            fontSize="16px"
            bg="white"
            color="sarisuki-green.500"
            _focus={{
              border: 'none',
              bg: 'white',
            }}
            _active={{
              border: 'none',
              bg: 'white',
            }}
            _hover={{
              border: 'none',
              bg: 'white',
            }}
          >
            Clear all
          </Button>
        </HStack>

        <FilterByAvailabilty />
        <VStack w="full" h="full" placeItems="flex-start" overflow="scroll">
          { /* Render Filter by Price Options and its Checkboxes  */
            isFilterByPriceVisible &&
            <>
              <Text fontSize="16px" fontWeight="bold" color="gray.900">
                Filter by price
              </Text>
              {
                filterByPriceOptions && Object.values(filterByPriceOptions).map((i) => (
                  <Checkbox
                    key={i.id}
                    isChecked={i.isChecked}
                    colorScheme="green"
                    padding="5px"
                    marginLeft="2px"
                    onChange={() => {
                      onFilterPriceSelect(i.id);
                    }}
                    size="lg"
                  >
                    <Text fontSize="16px" color="gray.500" fontWeight="normal">
                      {i.value}
                    </Text>
                  </Checkbox>
                  ),
                )
              }
            </>
          }
          { /* Render Filter by Brand Options and its Checkboxes */
            isFilterByBrandVisible &&
            <>
              <Text fontSize="16px" fontWeight="bold" ml="6px" pt={isFilterByPriceVisible ? '24px' : '0px'} color="gray.900">
                Filter by brand
              </Text>
              {
                filterByBrandOptions && Object.values(filterByBrandOptions).map((i) => (
                  <Checkbox
                    key={i.id}
                    isChecked={i.isChecked}
                    colorScheme="green"
                    padding="5px"
                    marginLeft="2px"
                    onChange={() => {
                      onFilterBrandSelect(i.id);
                    }}
                    size="lg"
                  >
                    <Text fontSize="16px" color="gray.500" fontWeight="normal">
                      {i.value}
                    </Text>
                  </Checkbox>
                  ),
                )
              }
            </>
          }
        </VStack>
        <Spacer />
        <Spacer />
      </VStack>
    </Flex>
  );

  return (
    <BottomSheet open={open} scrollLocking onDismiss={onDismiss}>
      {renderFilter()}
    </BottomSheet>
  );
};

export default FilterBottomSheet;
