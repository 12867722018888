import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Text, VStack } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';
import { Button, Container } from '@chakra-ui/react';
import { hideModal } from '@sarisuki/modal/modalSlice';
import { useAppDispatch } from '@app/hooks';
import { setUpdateInfoFromCheckout } from '@sarisuki/checkout/checkoutSlice';

interface IProps extends BaseModalProps {}
const UpdateAccountInfoModal: React.FC<IProps> = ({ onDismiss, open }: IProps) => {
  const dispatch = useAppDispatch();

  const onUpdateInfoClick = () => {
    dispatch(setUpdateInfoFromCheckout(true));
    dispatch(hideModal());
  };

  const onDontUpdate = () => {
    dispatch(setUpdateInfoFromCheckout(false));
    dispatch(hideModal());
  };

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent rounded="md">
        <Container py={6} px="65px">
          <VStack>
            <Text as="span" color="gray.900" fontWeight="700" fontFamily="Inter" fontSize="20px" px={10} align="center">
              Update your details?
            </Text>

            <Text
              as="span"
              color="gray.900"
              fontWeight="300"
              fontFamily="Inter"
              fontSize="14px"
              align="center"
              pt={2}
              pb={4}
              px={1}
            >
              We noticed you&apos;ve entered new information.
              Do you want to update and save your new details?
            </Text>

            <Text
              as="span"
              color="gray.900"
              fontWeight="300"
              fontFamily="Inter"
              fontSize="14px"
              align="center"
              pt={2}
              pb={4}
              px={1}
            >
              Don&apos;t worry, we&apos;ll use your new information for this order
              even if you don&apos;t save.
            </Text>

            <Button
              w={['full', '256px']}
              h="42px"
              px="24px"
              py="9px"
              borderRadius="4px"
              colorScheme="sarisuki-green"
              onClick={onUpdateInfoClick}
            >
              <Text as="span" fontSize="16px" lineHeight="24px" fontFamily="Inter">
                Yes, save my new info
              </Text>
            </Button>

            <Text
              as="button"
              color="gray.500"
              fontWeight="700"
              fontSize="16px"
              lineHeight="24px"
              fontFamily="Inter"
              align="center"
              pt={2}
              px={1}
              onClick={onDontUpdate}
            >
              No, don&apos;t save
            </Text>
          </VStack>
        </Container>
      </ModalContent>
    </Modal>
  );
};
export default UpdateAccountInfoModal;
