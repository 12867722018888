import { Button, HStack, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react';

import { BaseModalProps } from '@sarisuki/modal/types';
import { hideModal } from '@sarisuki/modal/modalSlice';
import { selectFilterSort, revertToPreviousFilterStates, setIsApplyFilterButtonDisabled, hideFilterSortPanel, showFilterSortPanel } from '@components/filterSort/filterSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks';

interface IProps extends BaseModalProps {}

const FilterSortExitModal = ({ open, onDismiss, ...modalProps }: IProps) => {
  const dispatch = useAppDispatch();
  const {
    selectedBrandOptions,
    selectedCategoryOptions,
    isSortParamActive,
    isPriceParamActive,
  } = useAppSelector(selectFilterSort);

   const onClose = () => {
      dispatch(revertToPreviousFilterStates());
      dispatch(setIsApplyFilterButtonDisabled(true));
      dispatch(hideModal());
      dispatch(hideFilterSortPanel());
  };

  const onCloseExitModal = () => {
    dispatch(hideModal());
    dispatch(showFilterSortPanel());
  };

  const numOfFilters = () => {
    let count = selectedBrandOptions.length + selectedCategoryOptions.length;

    if (isPriceParamActive) {
      count += 1;
    }

    if (isSortParamActive) {
      count += 1;
    }

    if (count === 1) {
      return `${count} filter`;
    }

    return `${count} filters`;
  };

  const buttonTextProps = {
    fontWeight: 600,
    fotnSize: '16px',
    lineHeight: '24px',
  };

  return (
    <Modal isCentered isOpen={open} onClose={onDismiss} {...modalProps}>
      <ModalOverlay />
      <ModalContent closeOnOverlayClick={false} p="20px">
        <ModalHeader>
          <Text fontWeight={700} fontSize="20px" lineHeight="24px">
            You have {numOfFilters()} that aren&apos;t applied
          </Text>
        </ModalHeader>
        <ModalBody>
          <VStack spacing="32px" pb="20px">
            <Text fontWeight={400} fontSize="16px" lineHeight="24px" color="gray.500">
              You&apos;re about to leave the filters page with unapplied filter(s).
              Do you want to apply the filter(s) before exiting?
            </Text>
            <HStack width="full">
              <Button width="full" bg="white" color="gray.400" onClick={onClose} {...buttonTextProps}>
                No, don&apos;t apply
              </Button>
              <Button width="full" bg="sarisuki-green.500" color="white" onClick={onCloseExitModal} {...buttonTextProps}>
                Go back
              </Button>
            </HStack>
          </VStack>
        </ModalBody>

      </ModalContent>
    </Modal>
  );
};

export default FilterSortExitModal;
