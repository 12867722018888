import axios from '@const/api';
import { LocalStorageKeys } from '@const/keys';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createCart } from '@sarisuki/cart/cartSlice';
import { Cart } from '@sarisuki/cart/types/cart';
import { Store } from '@sarisuki/store/types/store';
import { OrderHistoryItems } from '../types';

export const setOrderID = createAction<string>(
  'orderHistory/setOrderID',
);

export const getPreviousStoreInfo = createAsyncThunk<Store, string>(
  'orderHistory/getPreviousStoreInfo',
  async (storeName) => {
    const response = await axios.get(`/stores/${storeName}/`);
    return response.data;
  },
);

export const resetPreviousOrderInfo = createAction(
  'orderHistory/resetPreviousOrderInfo',
);

export const getPreviousCart = createAsyncThunk<Cart, number>(
  'orderHistory/getPreviousCart',
  async (storeId, { dispatch }) => {
    const storeCart = `${LocalStorageKeys.CART_ID}_${storeId}`;

    // Check if there is an existing cart id
    let cart_id = localStorage.getItem(storeCart);
    if (!cart_id) {
      // Create a new cart
      const cart = await dispatch(createCart(storeId)).unwrap();
      localStorage.setItem(storeCart, cart.id);
      cart_id = cart.id;
    }

    try {
      const response = await axios.get(`/carts/${cart_id}/`);
      const { is_checked_out } = response.data;
      if (is_checked_out) {
        localStorage.removeItem(storeCart);
        dispatch(getPreviousCart(storeId));
      }
      return response.data;
    } catch (error: any) {
      const { status } = error.response;
      if (status < 500) {
        localStorage.removeItem(storeCart);
        dispatch(getPreviousCart(storeId));
      }
      return error.response;
    }
  },
);

export const isFromOrderAgain = createAction<boolean>(
  'orderHistory/isFromOrderAgain',
);

export const setOrderAgainItemQty = createAction<OrderHistoryItems[] | null>(
  'orderHistory/setOrderAgainItemQty',
);
