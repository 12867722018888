import { extendTheme } from '@chakra-ui/react';
import sarisukiColors from './colors';

/**
 * This contains the theme for the Sarisuki Web Store
 */
export default extendTheme({
  colors: sarisukiColors,
  fonts: {
    heading: 'Inter',
    body: 'Inter',
    mono: 'Inter',
  },
});
