import { useRouter } from 'next/dist/client/router';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Button } from '@chakra-ui/button';
import { Heading, Text, VStack } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';

interface IProps extends BaseModalProps {
  storeName?: string;
  address?: string;
}

const StoreModal: React.FC<IProps> = ({ open, onDismiss, storeName, address }: IProps) => {
  const router = useRouter();

  const onVisitStorePressed = () => {
    onDismiss();
    router.push(`/${storeName}`);
  };

  return (
    <Modal isOpen={open} onClose={onDismiss} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="start">
            <Heading fontWeight="bold" textColor="gray.900" as="h4" size="md">{storeName}</Heading>
            <Text>{address}</Text>
            <Button onClick={onVisitStorePressed} colorScheme="sarisuki-green">
              <span className="font-bold normal-case">Visit store</span>
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
StoreModal.defaultProps = { address: '', storeName: '' };
export default StoreModal;
