import axios from '@const/api';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

interface Brand {
    brand: string;
}

interface FilterBrandOptionsPayload {
    categoryId: number;
    subcategoryId: number | null;
    brands: Brand[];
}

export const setIsSwitchChecked = createAction<boolean>(
    'filter/setIsSwitchChecked',
);

export const setIsSwitchCheckedAndFilter = createAction<boolean>(
    'filter/setIsSwitchCheckedAndFilter',
);

export const setFilterOptions = createAsyncThunk<FilterBrandOptionsPayload, any>(
    'filter/setFilterOptions',
    async (payload) => {
        const { storeUrl, categoryId } = payload;
        const response = await axios.get(`/stores/${storeUrl}/brands?categories=${categoryId}`).then((res) => res);
        return { categoryId, subcategoryId: null, brands: response.data };
    },
);

export const setFilterOptionsBySubcategory = createAsyncThunk<FilterBrandOptionsPayload, any>(
    'filter/setFilterOptionsBySubcategory',
    async (payload) => {
        const { storeUrl, categoryId, subcategoryId } = payload;
        const response = await axios.get(`/stores/${storeUrl}/brands?categories=${categoryId}&subcategories=${subcategoryId}`).then((res) => res);
        return { categoryId, subcategoryId, brands: response.data };
    },
);

export const setFilterOptionsBuyAgain = createAsyncThunk<Brand[], string>(
    'filter/setFilterOptionsBuyAgain',
    async (storeUrl) => {
        const response = await axios.get(`/stores/${storeUrl}/brands/order-again/`, {
            headers: {
              Authorization: `Token ${localStorage.getItem('user_token')}`,
            },
          });

        return response.data;
    },
);

export const updateFilterPriceCheck = createAction<number>(
    'filter/updateFilterPriceCheck',
);

export const updateFilterBrandCheck = createAction<number>(
    'filter/updateFilterBrandCheck',
);

export const setIsFilteringBrand = createAction<boolean>(
    'filter/setIsFilteringBrand',
);

export const setIsFilteringPrice = createAction<boolean>(
    'filter/setIsFilteringPrice',
);

export const setFilterByBrandVisibility = createAction<boolean>(
    'filter/setFilterByBrandVisibility',
);

export const setFilterByPriceOptions = createAction<any>(
    'filter/setFilterByPriceOptions',
);

export const updateFilterByPriceOptions = createAction<any>(
    'filter/updateFilterByPriceOptions',
);

export const clearFilterByBrandSelection = createAction('filter/clearFilterByBrandSelection');

export const clearFilterByPriceSelection = createAction('filter/clearFilterByPriceSelection');
