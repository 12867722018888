import { useAppDispatch, useAppSelector } from '@app/hooks';
import { HStack, Switch, Text } from '@chakra-ui/react';
import { useMobileMediaQuery, useTabletMediaQuery } from '@hooks/responsive_hook';
import { setIsSwitchCheckedAndFilter, selectFilter } from '@sarisuki/filter/filterSlice';

const FilterByAvailabilty = () => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const dispatch = useAppDispatch();
  const { isSwitchChecked } = useAppSelector(selectFilter);

  const onSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      dispatch(setIsSwitchCheckedAndFilter(false));
    } else {
      dispatch(setIsSwitchCheckedAndFilter(true));
    }
  };

  return (
    <HStack spacing={3} pb="24px">
      <Switch
        isChecked={isSwitchChecked}
        size="lg"
        colorScheme="green"
        onChange={(e) => onSwitchChange(e)}
      />
      <Text fontWeight={400} fontSize={(isMobile || isTablet) ? '14px' : '12px'} color="gray.500" textAlign="center">Show available items only</Text>
    </HStack>
  );
};
export default FilterByAvailabilty;
