import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Text, VStack } from '@chakra-ui/layout';
import { BaseModalProps } from '@sarisuki/modal/types';
import { Button, Container } from '@chakra-ui/react';
import router from 'next/router';
import { hideModal } from '@sarisuki/modal/modalSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectLogin } from '@sarisuki/login/loginSlice';
import { setMobileNumber } from '@sarisuki/checkout/checkoutSlice';
import mobileNumberFormatter from '@utils/mobileNumberFormatter';

interface IProps extends BaseModalProps {}

const DifferentExistingMobileNumberModal: React.FC<IProps> = ({ onDismiss, open }: IProps) => {
  const { sukiInfo } = useAppSelector(selectLogin);
  const dispatch = useAppDispatch();

  const onSignupClick = () => {
    dispatch(hideModal());
    router.replace('/login');
  };

  const onHideModal = () => {
    const localizedFormattedNumber =
      mobileNumberFormatter.localizeMobileNumber(String(sukiInfo?.mobile_number));
    dispatch(setMobileNumber(localizedFormattedNumber));
    dispatch(hideModal());
  };

  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={open} onClose={onDismiss}>
      <ModalOverlay />
      <ModalContent rounded="md">
        <Container py={10}>
          <VStack px={5}>
            <Text as="span" color="gray.900" fontWeight="700" fontFamily="Inter" fontSize="20px" px={10} align="center">
              Your mobile number is associated with a different account.
            </Text>

            <Text
              as="span"
              color="gray.900"
              fontWeight="300"
              fontFamily="Inter"
              fontSize="14px"
              align="center"
              pt={2}
              pb={4}
              px={1}
            >
              You will need to log in to your other account.
            </Text>

            <Button
              w={['full', '256px']}
              h="42px"
              px="24px"
              py="9px"
              borderRadius="4px"
              colorScheme="sarisuki-green"
              onClick={onSignupClick}
            >
              <Text as="span" fontSize="16px" lineHeight="24px">Login</Text>
            </Button>

            <Text
              as="button"
              color="gray.500"
              fontWeight="700"
              fontSize="16px"
              lineHeight="24px"
              fontFamily="Inter"
              align="center"
              pt={2}
              px={1}
              onClick={onHideModal}
            >
              Cancel
            </Text>
          </VStack>
        </Container>
      </ModalContent>
    </Modal>
  );
};
export default DifferentExistingMobileNumberModal;
